import axiosInstance from '../utils/api';
import { USER_VERIFY_SESSION_SUCCESS, USER_VERIFY_SESSION_FAIL } from '../constants/userConstants';

export const checkAuthState = async (dispatch) => {
    try {
        const { data } = await axiosInstance.get('/users/verify-session');

        if (data.isAuthenticated) {
            dispatch({
                type: USER_VERIFY_SESSION_SUCCESS,
                payload: data.user,
            });
        } else {
            dispatch({ type: USER_VERIFY_SESSION_FAIL });
        }
    } catch (error) {
        console.error('Failed to verify session:', error);
        dispatch({ type: USER_VERIFY_SESSION_FAIL, payload: error.message });
    }
};
