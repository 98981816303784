import { useSnackbar } from "notistack";
import { useEffect, useState, useCallback  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MopedIcon from "@mui/icons-material/Moped";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  removeItem,
  addItemsToCart,
} from "../../actions/cartAction";
import {
  ADMIN_PRODUCT_API,
} from "../../utils/constants";
import Product from "./Product";
import Cart from "../Cart/Cart";
import DeliveryEdit from "../Cart/DeliveryEdit";
import image1 from "../../assets/images/ProductDetails1.png";
import image2 from "../../assets/images/ProductDetails2.jpg";
import image3 from "../../assets/images/ProductDetails3.jpg";
import { TbShare3 } from "react-icons/tb";

const ProductDetails = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();

  const Product_id = params.productId;

  // reviews toggle

  const [viewProduct, setViewProduct] = useState({});

  const { success, error: reviewError } = useSelector(
    (state) => state.newReview
  );
  const { cartItems } = useSelector((state) => state.cart);

  const [count, setCount] = useState(1);
  const [showCounter, setShowCounter] = useState(false);
  const [subCategory, setSubCategory] = useState();
  const [subsubCategory, setSubSubCategory] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [productCounts, setProductCounts] = useState({});

  useEffect(() => {
    const counts = cartItems.reduce((acc, item) => {
      acc[item.product_id] = item.quantity;
      return acc;
    }, {});
    setProductCounts(counts);
  }, [cartItems]);

  const handleAddToCart = (productId, quantity) => {
    dispatch(addItemsToCart(productId, quantity));
    setShowCounter(true);
  };

  const handleIncrease = (productId) => {
    setCount((prevCount) => prevCount + 1);
    dispatch(addItemsToCart(productId, 1));
    setShowCounter(true);
  };

  const handleDecrease = (productId) => {
    if (count > 1) {
      setCount((prevCount) => prevCount - 1);
      dispatch(addItemsToCart(productId, -1));
      setShowCounter(true);
    } else {
      dispatch(removeItem(productId));
      setShowCounter(false);
    }
  };

  const currentUrl = window.location.pathname;
  const product_id = currentUrl.substring(currentUrl.lastIndexOf("/") + 2);

  const addToCartHandler = () => {
    dispatch(addItemsToCart(viewProduct.Product_id, count)); // Make sure this uses viewProduct.Product_id
    enqueueSnackbar("Product Added To Cart", { variant: "success" });
    setShowCounter(true);
  };

  const itemInCart = cartItems.some((i) => i.product === product_id);

  const goToCart = () => {
    navigate("/cart");
  };

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        // Fetch product data
        const response = await fetch(`${ADMIN_PRODUCT_API}/${product_id}`);
        const productData = await response.json();

        setViewProduct(productData);
        setSubCategory(productData.Sub_Categories);
        setSubSubCategory(productData.Sub_Sub_Categories);

        // Get cart items from local storage and check if the product exists in the cart
        const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
        const item = cartItems.find(
          (item) => item.product_id === productData.Product_id
        );

        if (item) {
          setShowCounter(true);
          setCount(item.quantity);
        } else {
          setShowCounter(false);
        }

        // Fetch the price based on storeId if available
        const storedStoreId = localStorage.getItem("StoreId");
        const storeId = storedStoreId
          ? JSON.parse(storedStoreId).store_id
          : null;

        const priceUrl = storeId
          ? `${ADMIN_PRODUCT_API}/${product_id}/price?storeId=${storeId}`
          : `${ADMIN_PRODUCT_API}/${product_id}`;

        const priceResponse = await fetch(priceUrl);
        const priceData = await priceResponse.json();

        // Log the price data to debug
        console.log("Price data:", priceData);

        // Set product price and related information
        if (priceData && priceData.MRP) {
          setViewProduct((prevState) => ({
            ...prevState,
            MRP: priceData.MRP, // Ensure MRP is updated
            price: priceData.sell_price || priceData.MRP,
            offer: priceData.offer,
            you_save: priceData.offer,
            sell_price: priceData.sell_price,
            delivery_option: priceData.delivery_option || "Standard Delivery",
          }));
        } else {
          // If price data from store not found, fallback to product MRP
          setViewProduct((prevState) => ({
            ...prevState,
            price: productData.MRP,
            offer: null,
            sell_price: null,
            you_save: null,
            delivery_option: productData.delivery_option || "Standard Delivery",
          }));
        }
      } catch (error) {
        console.error("Error fetching product or price data:", error);
      }
    };

    fetchProductData();
  }, [product_id]);

  useEffect(() => {
    if (subCategory && subsubCategory) {
      fetch(`${ADMIN_PRODUCT_API}`)
        .then((response) => response.json())
        .then((productData) => {
          const filtered = productData.filter((item) => {
            return (
              subsubCategory.includes(item.Sub_Sub_Categories) &&
              item.Product_id !== viewProduct.Product_id // Exclude the current product
            );
          });

          const limitedProducts = filtered.slice(0, 5);
          setFilteredData(limitedProducts);
        })
        .catch((error) =>
          console.error("Error fetching categorized data:", error)
        );
    }
  }, [subCategory, subsubCategory, viewProduct.Product_id]);

  const closeCartSidebar = () => {
    setIsCartOpen(false);
    document.body.style.overflow = "auto"; // Enable scrolling
  };

  const generateShareableLink = () => {
    const baseUrl = window.location.origin;
    const shareableLink = `${baseUrl}/product/:${product_id}`;
    console.log("Link:", shareableLink);
    return shareableLink;
  };

  return (
    <>
      <div className="min-h-screen bg-white max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 pt-[6rem] hidden lg:block">
        <div className="flex items-stretch mt-4">
          <div className="w-[50%] h-full">
            <div className="pr-16 border h-[30rem] rounded-lg">
              <div className="flex justify-center w-full h-96">
                <div className="cursor-crosshair w-[450px] h-[450px] relative select-none">
                  <img
                    className="w-full h-full pointer-events-none"
                    src={viewProduct.Prodouct_img_0}
                    alt={viewProduct.name}
                  />
                </div>
              </div>
            </div>
            <div className="bg-gray-200 h-px"></div>
            <div className="pr-2">
              <div className="text-gray-700 text-2xl font-semibold leading-8 mt-8 mb-4">
                Product Details
              </div>
              <div style={{ height: "max-content" }}>
                <div className="my-4">
                  <p className="text-gray-800 text-md font-semibold leading-4 mt-0 mb-2 capitalize">
                    Unit
                  </p>
                  <span className="text-gray-600 text-md leading-4 overflow-wrap-break">
                    {viewProduct.Weight}
                  </span>
                </div>
                <div className="my-4">
                  <p className="text-gray-800 text-md font-semibold leading-4 mt-0 mb-2 capitalize">
                    Country Of Origin
                  </p>
                  <span className="text-gray-600 text-md leading-4 overflow-wrap-break">
                    India
                  </span>
                </div>
                <div className="my-4">
                  <p className="text-gray-800 text-md font-semibold leading-4 mt-0 mb-2 capitalize">
                    Customer Care Details
                  </p>
                  <span className="text-gray-600 text-md leading-4 overflow-wrap-break">
                    Email: info@vivimart.com
                  </span>
                </div>
                <div className="my-4">
                  <p className="text-gray-800 text-md font-semibold leading-4 mt-0 mb-2 capitalize">
                    Return Policy
                  </p>
                  <span className="text-gray-600 text-md leading-4 overflow-wrap-break">
                    The product is non-returnable. For a damaged, defective,
                    expired or incorrect item, you can request a replacement
                    within 24 hours of delivery. In case of an incorrect item,
                    you may raise a replacement or return request only if the
                    item is sealed/ unopened/ unused and in original condition.
                  </span>
                </div>
                <div className="my-4">
                  <p className="text-gray-800 text-md font-semibold leading-4 mt-0 mb-2 capitalize">
                    Disclaimer
                  </p>
                  <span className="text-gray-600 text-md leading-4 overflow-wrap-break">
                    Every effort is made to maintain accuracy of all
                    information. However, actual product packaging and materials
                    may contain more and/or different information. It is
                    recommended not to solely rely on the information presented.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="pl-12 w-[45%] sticky top-20 bottom-0 self-start">
            {viewProduct.Categories && (
              <div className="flex flex-col">
                <div className="flex justify-start items-center text-sm text-[rgba(13,19,0,var(--text-opacity))]">
                  <div className="flex justify-start items-center">
                    <a className="flex items-center" href="/">
                      <span className="text-xs text-darkOnyx-800">Home</span>
                    </a>
                    <span className="px-2 text-xs">/</span>
                  </div>
                  <div className="flex justify-start items-center">
                    <a
                      className="flex items-center"
                      href={`/products?category=${viewProduct.Categories}`}
                    >
                      <span className="text-xs text-darkOnyx-800 truncate">
                        {viewProduct.Categories}
                      </span>
                    </a>
                    <span className="px-2 text-xs">/</span>
                  </div>
                  <div className="flex justify-start items-center">
                    <span
                      className="text-sm text-darkOnyx-800 truncate w-[8rem]"
                      title={viewProduct.Product_name}
                    >
                      <span className="text-xs text-darkOnyx-800 truncate">
                        {viewProduct.Product_name}
                      </span>
                    </span>
                  </div>
                </div>

                <h2 className="text-black text-2xl leading-8 font-bold mt-4">
                  {viewProduct.Product_name}, {viewProduct.Weight}
                </h2>
                <div className="flex flex-col gap-4">
                  {/* <div className="inline-flex w-[5rem] items-center rounded-md px-1 font-bold text-gray-800 bg-gray-200">
                <img
                  className="h-4 w-4"
                  src="https://cdn.grofers.com/assets/eta-icons/15-mins.png"
                  alt="clock"
                />
                <span className="text-sm">13 MINS</span>
              </div> */}
                  <div className="h-px mt-2 w-full bg-gray-300"></div>
                  <div className="flex justify-between items-start ">
                    <div className="flex flex-col justify-left items-left">
                      <div className="flex items-center text-sm text-gray-500 line-through leading-md p-0">
                        MRP: ₹{Math.ceil(viewProduct.MRP)}
                      </div>
                      <div className="flex items-center text-lg mb-4">
                        <span className="font-semibold">
                          Price: ₹ ₹
                          {Math.ceil(
                            viewProduct.sell_price || viewProduct.sell_price
                          )}
                        </span>
                      </div>
                      <div className="flex items-center text-md text-appleGreen-400 font-semibold mb-1 leading-md p-0">
                        <span className="border p-2 rounded-lg border-blue-500 bg-blue-100 shadow-2xl hover:bg-blue-200">
                          {viewProduct.you_save
                            ? `${Math.ceil(viewProduct.you_save)}% OFF`
                            : "No Offer"}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      {showCounter ? (
                        <div className="flex items-center">
                          <button
                            className="cursor-pointer w-8 h-10 bg-transparent text-white font-semibold text-sm leading-5 tracking-wide uppercase rounded-l border-r"
                            onClick={() =>
                              handleDecrease(viewProduct.Product_id)
                            }
                            style={{ backgroundColor: "rgb(49, 134, 22)" }}
                          >
                            -
                          </button>
                          <div
                            style={{ backgroundColor: "rgb(49, 134, 22)" }}
                            className="h-10 w-10 text-white font-semibold text-sm leading-5 tracking-wide bg-green-50 flex justify-center items-center p-2"
                          >
                            {productCounts[viewProduct.Product_id]}
                          </div>
                          <button
                            className="cursor-pointer w-8 h-10 bg-transparent text-white font-semibold text-md leading-5 tracking-wide uppercase rounded-r border-l"
                            onClick={() =>
                              handleIncrease(viewProduct.Product_id)
                            }
                            style={{ backgroundColor: "rgb(49, 134, 22)" }}
                          >
                            +
                          </button>
                        </div>
                      ) : (
                        <button
                          className="cursor-pointer w-20 border border-green-500 h-10 text-green-500 font-semibold text-sm leading-5 tracking-wide uppercase bg-green-50 rounded flex justify-center items-center"
                          onClick={() =>
                            handleAddToCart(viewProduct.Product_id, 1)
                          }
                        >
                          ADD
                        </button>
                      )}

                      {/* Share Button */}
                      <button
                        onClick={() => {
                          const shareableLink = generateShareableLink();
                          navigator.clipboard
                            .writeText(shareableLink)
                            .then(() => {
                              enqueueSnackbar(
                                "Product link copied to clipboard!",
                                {
                                  variant: "success",
                                }
                              );
                            })
                            .catch((error) => {
                              console.error(
                                "Failed to copy product link:",
                                error
                              );
                              enqueueSnackbar(
                                "Failed to copy link, please try again.",
                                {
                                  variant: "error",
                                }
                              );
                            });
                        }}
                        className="text-primary-blue px-4 py-2 text-3xl"
                      >
                        <TbShare3 />
                      </button>
                    </div>
                  </div>
                  <div className="flex text-gray-500 gap-2 text-sm">
                    <MopedIcon className="text-red-500" />
                    <span>Fast Delivery in 30 Mins</span>
                  </div>
                  <div className="mb-2 font-bold text-base leading-relaxed ">
                    <span>Pack Sizes:</span>
                    <div className="w-[25rem] mt-2">
                      <div className="justify-between cursor-pointer rounded-md overflow-hidden mb-2.5 hover:shadow-1 border flex bg-white min-h-16 border-green-500">
                        <div className="flex flex-col items-start justify-start w-full p-2.5">
                          <div className="flex justify-start w-full h-full">
                            <div className="w-40 xl:w-52">
                              <span className="w-40 overflow-hidden whitespace-nowrap overflow-ellipsis font-semibold text-lg leading-4 text-gray-800">
                                {viewProduct.Weight}
                              </span>
                              <span
                                className="clamp-1 text-customGray text-sm pt-1.5 leading-5"
                                style={{ "--text-opacity": 1, width: "10rem" }}
                              />
                            </div>
                            <div className="flex w-40 xl:w-52 ml-6 xl:ml-5 flex-col">
                              <span className="flex gap-2">
                              <span>₹{Math.ceil(viewProduct.sell_price || viewProduct.MRP)}</span>
                              <span className="text-gray-700 text-sm">(₹{Math.ceil(viewProduct.sell_price)} / kg)</span>
                              </span>
                              <div className="pt-1.5 flex items-center">
                                <span
                                  className="line-through justify-start text-xs leading-5"
                                  style={{
                                    "--text-opacity": 1,
                                    color:
                                      "rgba(144, 144, 144, var(--text-opacity))",
                                  }}
                                >
                                  ₹{viewProduct.MRP}
                                </span>
                                <span
                                  className="justify-start bg-green-100 text-green-600 text-xs leading-5 px-1.5 py-0.5 ml-2"
                                  style={{
                                    backgroundColor: "rgba(241, 248, 230, 1)",
                                    color: "rgba(71, 111, 0, 1)",
                                    fontSize: "0.75rem",
                                    lineHeight: "1.1875",
                                  }}
                                >
                                  {Math.ceil(viewProduct.you_save)}% OFF
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!viewProduct.Categories && <div className="h-[20rem]"></div>}
            <div className="inline-block ">
              <div className="pt-0 pb-0 text-base mt-6 mb-4 text-gray-700 font-semibold font-okra-bold">
                Why shop from vivimart?
              </div>
              <div className="flex flex-col ">
                <div className="flex gap-8 mb-4">
                  <div className="h-14 w-14 cursor-default rounded-none">
                    <img
                      className="rounded-none object-fill cursor-default"
                      src={image1}
                      alt="Superfast Delivery"
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-gray-800 text-sm leading-4 mt-0 capitalize">
                      Superfast Delivery
                    </span>
                    <p className="text-gray-600 mt-2 text-sm leading-4 overflow-wrap-break">
                      Get your order delivered to your doorstep at the earliest
                      from dark stores near you.
                    </p>
                  </div>
                </div>
                <div className="flex gap-8 mb-4">
                  <div className="h-14 w-14 cursor-default rounded-none">
                    <img
                      className="rounded-none object-fill cursor-default"
                      src={image2}
                      alt="Best Prices & Offer"
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-gray-800 text-sm leading-4 mt-0 capitalize">
                      Best Prices & Offers
                    </span>
                    <p className="text-gray-600 mt-2 text-sm leading-4 overflow-wrap-break">
                      Best price destination with offers directly from the
                      manufacturers.
                    </p>
                  </div>
                </div>
                <div className="flex gap-8 mb-4">
                  <div className="h-14 w-14 cursor-default rounded-none">
                    <img
                      className="rounded-none object-fill cursor-default"
                      src={image3}
                      alt="	
                    Wide Assortment"
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-gray-800 text-sm leading-4 mt-0 capitalize">
                      Wide Assortment
                    </span>
                    <p className="text-gray-600 mt-2 text-sm leading-4 overflow-wrap-break">
                      Choose from 5000+ products across food, personal care,
                      household & other categories.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-6 w-full">
          <div className="text-xl font-semibold mb-6">Similar Products</div>
          <div>
            <Product similarData={filteredData} />
          </div>
        </div>
      </div>
      <div className="min-h-screen bg-white pt-[13rem] lg:hidden">
        <div className="bg-[var(--colors-white-050)] rounded-none ml-11 mr-11">
          <div className="w-full h-full ">
            <img
              className="transition-opacity ease-out duration-150 w-full h-full"
              src={viewProduct.Prodouct_img_0}
              alt={viewProduct.name}
            />
          </div>
        </div>
        <div className="px-4">
          <div className="pt-3 pr-3 pb-4">
            <h2
              className="text-black text-2xl leading-8 font-bold m-0"
              style={{ fontSize: "19px" }}
            >
              {viewProduct.Product_name}, {viewProduct.Weight}
            </h2>
            <div className="flex text-gray-500 gap-2 text-xs">
              <MopedIcon className="text-red-500" />
              <span className=" mt-1">Fast Delivery in 30 Mins</span>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col justify-left items-left">
              <div className="flex items-center text-xs text-gray-500 line-through leading-md p-0">
                MRP: ₹{Math.ceil(viewProduct.MRP)}
              </div>
              <div className="flex items-center text-sm">
                <span className="font-semibold">
                  Price: ₹{Math.ceil(viewProduct.sell_price)}
                </span>
              </div>
              <div className="flex text-gray-500 gap-2 text-xs">
                (Inclusive of all taxes)
              </div>
            </div>
            <div className="self-auto">
              {showCounter ? (
                <div className="flex items-center">
                  <button
                    className="cursor-pointer w-8 h-8 bg-transparent text-white font-semibold text-sm leading-5 tracking-wide uppercase rounded-l"
                    onClick={handleDecrease}
                    style={{ backgroundColor: "rgb(49, 134, 22)" }}
                  >
                    -
                  </button>
                  <div
                    style={{ backgroundColor: "rgb(49, 134, 22)" }}
                    className=" h-8 text-white font-semibold text-sm leading-5 tracking-wide bg-green-50 flex justify-center items-center px-2"
                  >
                    {count}
                  </div>
                  <button
                    className="cursor-pointer w-8 h-8 bg-transparent text-white font-semibold text-md leading-5 tracking-wide uppercase rounded-r"
                    onClick={handleIncrease}
                    style={{ backgroundColor: "rgb(49, 134, 22)" }}
                  >
                    +
                  </button>
                </div>
              ) : (
                <button
                  className="cursor-pointer w-full px-4 bg-[#318616] h-8 text-white font-semibold text-xs leading-5 tracking-wide uppercase rounded flex justify-center items-center"
                  onClick={() => handleAddToCart(viewProduct.Product_id, 1)}
                >
                  Add to cart
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="pt-6 px-4">
          <h1 className="text-black text-lg leading-8 font-bold font-sans">
            Similar Products
          </h1>
        </div>
        <div className=" flex flex-row mt-6 w-full">
          <Product similarData={filteredData} />
        </div>
        {cartItems.length > 0 && !isCartOpen && (
          <div className="fixed left-3 right-3 flex z-[10001] bg-[#0c831f] bottom-3 text-white rounded-lg p-2.5 pl-3 pr-4 mx-auto justify-between transition-transform duration-300 transform translate-y-0 opacity-100">
            <div className="flex justify-between gap-3">
              <ShoppingCartIcon />
              <div>
                {cartItems.length} item{cartItems.length !== 1 && "s"}
              </div>
            </div>
            <div
              onClick={() => setIsCartOpen(true)}
              className="flex justify-center items-center font-sans text-[17px] leading-[22px]"
            >
              View Cart
              <ArrowRightIcon />
            </div>
          </div>
        )}
        {isCartOpen && (
          <div className="fixed inset-0 z-20 flex">
            <div
              className="fixed inset-0 bg-black opacity-50 backdrop-blur-sm"
              onClick={closeCartSidebar}
            ></div>
            <div className="ml-auto">
              <Cart closeCartSidebar={closeCartSidebar} setIsEdit={setIsEdit} />
            </div>
          </div>
        )}

        {isEdit && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70 py-4">
            <DeliveryEdit setIsEdit={setIsEdit} />
          </div>
        )}
      </div>
    </>
  );
};

export default ProductDetails;
