import {
    CLEAR_ERRORS,
    GET_ADMIN_FAIL,
    GET_ADMIN_REQUEST,
    GET_ADMIN_SUCCESS
} from "../constants/adminConstants";

const initialState = {
    admin: [],
    loading: false,
    error: null,
};

export const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ADMIN_REQUEST:
            return { ...state, loading: true };
        case GET_ADMIN_SUCCESS:
            return { loading: false, admin: action.payload };
        case GET_ADMIN_FAIL:
            return { ...state, loading: false, error: action.payload };
        case CLEAR_ERRORS:
            return { ...state, error: null };
        default:
            return state;
    }
};