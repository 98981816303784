import { useSnackbar } from "notistack";
import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { clearErrors, getProducts } from "../../actions/productAction";
import Loader from "../Layouts/Loader";
import MinCategory from "../Layouts/MinCategory";
import Product from "./Product";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  ADMIN_SUB_CATEGORY_API,
  ADMIN_SUB_SUB_CATEGORY_API,
  ADMIN_PRODUCT_API,
} from "../../utils/constants";
import MetaData from "../Layouts/MetaData";
import { useLocation } from "react-router-dom";
import { getCategoryName } from "../../actions/categoryAction";

const Products = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const location = useLocation();
  const [price, setPrice] = useState([0, 200000]);
  const [category, setCategory] = useState("");
  const [ratings, setRatings] = useState(0);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [subSubCategoryData, setSubSubCategoryData] = useState([]);
  const [allSubSubCategoryData, setAllSubSubCategoryData] = useState([]);
  const [data, setData] = useState([]);
  const [product, setProduct] = useState([]);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [title, setTitle] = useState();
  // filter toggles
  const [categoryToggle, setCategoryToggle] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectItem, setSelectItem] = useState(null);
  const categoryId = new URLSearchParams(location.search).get("category_id");
  console.log("Selected Category ID:", categoryId);
  const [expandedSubCategoryIndex, setExpandedSubCategoryIndex] = useState(-1);
  const {
    products,
    loading,
    error,
    productsCount,
    resultPerPage,
    filteredProductsCount,
  } = useSelector((state) => state.products);
  const keyword = params.keyword;

  const { categoryNames } = useSelector((state) => state.categoryNames);

  console.log("category name based on selected id:", categoryNames);

  useEffect(() => {
    if (categoryId) {
      dispatch(getCategoryName(categoryId));
    }
  }, [dispatch, categoryId]);

  useEffect(() => {
    if (categoryNames && categoryNames.categoryName) {
      console.log("Fetched category name data:", categoryNames.categoryName);
    }
  }, [categoryNames]);

  useEffect(() => {
    console.log("Location search:", location.search);
    const queryParams = new URLSearchParams(location.search);
    const categoryId = queryParams.get("category_id");
    setCategory(categoryId);
    console.log("Selected category id:", categoryId);
  }, [location.search]);
  

  useEffect(() => {
    if (!categoryNames || !categoryNames.categoryName) return;

    const fetchProductData = async () => {
      try {
        // Use the actual category name for the API URL
        const response = await fetch(
          `${ADMIN_PRODUCT_API}/category/${categoryNames.categoryName}`
        );
        const productData = await response.json();
        setProduct(productData);

        // Filter products based on the fetched category name
        const filteredProductData = productData.filter((item) => {
          const lowerCaseCategories = item.Categories.toLowerCase();
          const lowerCaseCategoryName =
            categoryNames.categoryName.toLowerCase();
          return lowerCaseCategories.includes(lowerCaseCategoryName);
        });
        setData(filteredProductData);

        // Fetch and set sub-sub-categories
        const response1 = await fetch(ADMIN_SUB_SUB_CATEGORY_API);
        const subsubCategoryData = await response1.json();
        setAllSubSubCategoryData(subsubCategoryData);
      } catch (error) {
        console.error("Error fetching products or categories:", error);
      }
    };

    fetchProductData();
  }, [categoryNames]);

  useEffect(() => {
    const fetchSubCategoryData = async () => {
      try {
        // Fetch sub-categories by category ID
        if (categoryId) {
          const response = await fetch(
            `${ADMIN_SUB_CATEGORY_API}/category/${categoryId}`
          );
          const subCategoryData = await response.json();
          setSubCategoryData(subCategoryData);
        }
      } catch (error) {
        console.error("Error fetching sub-categories:", error);
      }
    };

    fetchSubCategoryData();
  }, [categoryId]);

  const handleClick = async (index, subCategoryId) => {
    setExpandedSubCategoryIndex(
      index === expandedSubCategoryIndex ? null : index
    );

    try {
      // Fetch sub-sub-categories by sub-category ID
      if (subCategoryId) {
        const response = await fetch(
          `${ADMIN_SUB_SUB_CATEGORY_API}/subCategory/${subCategoryId}`
        );
        const subSubCategoryData = await response.json();
        setSubSubCategoryData(subSubCategoryData);
      }
    } catch (error) {
      console.error("Error fetching sub-sub categories:", error);
    }

    setSelectedItem(index === selectedItem ? null : index);
  };

  const handleProductClick = async (index, name) => {
    const response = await fetch(`${ADMIN_PRODUCT_API}/subsubcategory/${name}`);
    const productData = await response.json();
    setProduct(productData);
    setSelectItem(index === selectedItem ? null : index);
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    dispatch(getProducts(keyword, category, price, ratings, currentPage));
  }, [
    dispatch,
    keyword,
    category,
    price,
    ratings,
    currentPage,
    error,
    enqueueSnackbar,
  ]);

  const [selectedOption, setSelectedOption] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [sortOption, setSortOption] = useState("relevance");

  const normalizeBrandName = (brandName) => brandName.trim().toLowerCase();

  const uniqueBrandNames = Array.from(
    new Set(product.map((item) => normalizeBrandName(item.Brand_name)))
  );

  const productOptions = uniqueBrandNames.map((brandName) => ({
    value: brandName,
    label: brandName.charAt(0).toUpperCase() + brandName.slice(1),
  }));

  const options = [
    ...productOptions.map((brand) => ({
      value: brand.value,
      label: brand.label,
    })),
    { value: "relevance", label: "Relevance" },
    { value: "priceAsc", label: "Price (Low to High)" },
    { value: "priceDesc", label: "Price (High to Low)" },
    { value: "discountHigh", label: "Discount (High to Low)" },
    { value: "nameAZ", label: "Name (A to Z)" },
  ];

  const handleOptionClick = (value) => {
    setSelectedOption(value);
    if (productOptions.find((option) => option.value === value)) {
      setSelectedBrand(value); // Set brand name if selected
    } else {
      setSelectedBrand(""); // Clear brand filter if not a brand name
    }
    setSortOption(value); // Update sort option state
    setIsOpen(false);
  };

  const sortedProducts = () => {
    let sorted = [...product];

    if (sortOption === "nameAZ") {
      sorted = sorted.sort((a, b) =>
        a.Product_name.localeCompare(b.Product_name)
      );
    } else if (sortOption === "priceAsc") {
      sorted = sorted.sort((a, b) => a.sell_price - b.sell_price);
    } else if (sortOption === "priceDesc") {
      sorted = sorted.sort((a, b) => b.sell_price - a.sell_price);
    } else if (selectedBrand) {
      sorted = sorted.filter(
        (item) => item.Brand_name.toLowerCase() === selectedBrand
      );
    }

    return sorted;
  };

  const parentRef = useRef(null);
  const childRef = useRef(null);

  useEffect(() => {
    const parent = parentRef.current;
    const child = childRef.current;

    const handleScroll = () => {
      if (child.scrollTop + child.clientHeight >= child.scrollHeight) {
        // Scroll the parent by 1 pixel
        parent.scrollTop += 1;
      }
    };
    if (child) {
      child.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (child) {
        child.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <>
      <MetaData title="All Products | Vivimart" />
      <MinCategory />
      <main className="w-full mt-14 sm:mt-0">
        <div className="border border-[0.5px] mb-0 border-gray-300 mt-[14.6rem] md:mt-[14.5rem] bg-white text-gray-900 p-3 font-semibold text-[13px] shadow-[0_0_10px_0_rgba(0,0,0,0.07)] lg:hidden">
          {title}
        </div>

        <div
          className="flex lg:gap-1  sm:mx-12 m-auto  overflow-hidden"
          ref={parentRef}
        >
          {/* <!-- sidebar column  --> */}
          <div className="sm:flex flex-col w-[23%] md:w-46 pr-1 mt-6 rounded-full">
            {/* <!-- nav tiles --> */}
            <div
              className="flex flex-col bg-white rounded-lg shadow-lg lg:h-[29
            rem]"
            >
              <div className="flex flex-col gap-2 mt-5 text-sm md:p-2 overflow-hidden">
                {/* category filter */}
                <div className="flex flex-col border-b px-4 pl-0 h-auto mt-[19rem] lg:mt-0 hidden lg:block">
                  <div
                    className="flex justify-between cursor-pointer py-2 pb-4 items-center"
                    onClick={() => {
                      setCategoryToggle(!categoryToggle);
                      // window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    <p className="font-medium text-xs uppercase pl-4">
                      Category
                    </p>
                    {/* {categoryToggle ? (
                      <ExpandLessIcon sx={{ fontSize: "20px" }} />
                    ) : (
                      <ExpandMoreIcon sx={{ fontSize: "20px" }} />
                    )} */}
                  </div>
                  {loading ? (
                    <Loader />
                  ) : (
                    <div className="flex flex-col pb-1 w-2xl max-h-[30rem] overflow-y-auto hidden-scrollbar">
                      {categoryToggle && (
                        <>
                          {product.length === 0 &&
                          subCategoryData.length === 0 ? (
                            <div className="p-3 text-sm text-gray-800">
                              No categories found.
                            </div>
                          ) : (
                            subCategoryData.map((subCategory, subIndex) => (
                              <div key={subIndex} className="relative">
                                <div
                                  className={`p-[10px] flex flex-col md:flex-row justify-between items-center cursor-pointer ${
                                    selectedItem === subIndex
                                      ? "bg-[#EBFFEF] border-l-3"
                                      : "bg-white"
                                  }`}
                                  style={
                                    selectedItem === subIndex
                                      ? {
                                          borderLeftColor: "green",
                                          borderLeftWidth: "0.3rem",
                                        }
                                      : {}
                                  }
                                  onClick={() => {
                                    handleClick(
                                      subIndex,
                                      subCategory.sub_category_id
                                    );
                                    // window.scrollTo({ top: 0, behavior: "smooth" });
                                  }}
                                >
                                  <div className="flex flex-col md:flex-row items-center pl-2">
                                  {subSubCategoryData.filter((subsubCategory) => subsubCategory.sub_category_id === subCategory.sub_category_id).length > 0 ? (
  expandedSubCategoryIndex === subIndex ? (
    <ExpandLessIcon />
  ) : (
    <ExpandMoreIcon />
  )
) : null}
                                    <div className="h-[3.7rem] w-[3.7rem] ml-3 rounded-full">
                                      <img
                                        draggable="false"
                                        className="h-full w-full object-contain"
                                        src={subCategory.sub_category_img}
                                        alt={subCategory.sub_category_name}
                                      />
                                    </div>
                                    <span
                                      title={subCategory.sub_category_name}
                                      className="cursor-pointer mt-2 ml-2 w-[6rem] lg:w-full text-custom-gray text-[15px] font-medium leading-[18px] group-hover:text-primary-blue truncate"
                                    >
                                      {subCategory.sub_category_name}
                                    </span>
                                  </div>
                                </div>
                                {expandedSubCategoryIndex === subIndex && (
                                  <div className="max-h-[13rem] overflow-y-auto hidden-scrollbar">
                                    {subSubCategoryData
                                      .filter(
                                        (subsubCategory) =>
                                          subsubCategory.sub_category_id ===
                                          subCategory.sub_category_id
                                      )
                                      .map((subsubCategory, subsubIndex) => (
                                        <div
                                          key={subsubIndex}
                                          onClick={() => {
                                            handleProductClick(
                                              subsubIndex,
                                              subsubCategory.sub_sub_category_name
                                            );
                                            window.scrollTo({
                                              top: 0,
                                              behavior: "smooth",
                                            });
                                          }}
                                          className={`p-[7px] flex flex-col md:flex-row justify-between items-center gap-3 border-r border-b border-gray-200 cursor-pointer hover:bg-[#EBFFEF] ${
                                            selectItem === subsubIndex
                                              ? "bg-[#EBFFEF] border-l-3"
                                              : "bg-white"
                                          }`}
                                        >
                                          <div className="flex flex-col md:flex-row items-center pl-4">
                                            <div className="h-[3.7rem] w-[3.7rem]">
                                              <img
                                                draggable="false"
                                                className="h-full w-full object-contain"
                                                src={
                                                  subsubCategory.sub_sub_category_img
                                                }
                                                alt={
                                                  subsubCategory.sub_sub_category_name
                                                }
                                              />
                                            </div>
                                            <span className="mt-2 md:mt-0 md:ml-3 text-custom-gray text-[15px] font-medium leading-[18px] group-hover:text-primary-blue truncate">
                                              {
                                                subsubCategory.sub_sub_category_name
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                )}
                              </div>
                            ))
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
                <div className="flex flex-col border-b h-auto md:mt-[0rem] lg:hidden">
                  <div className="max-h-[37rem] md:max-h-[45rem] overflow-y-auto hidden-scrollbar">
                    {allSubSubCategoryData.map(
                      (subsubCategory, subsubIndex) => (
                        <div
                          key={subsubIndex}
                          onClick={() => {
                            handleProductClick(
                              subsubIndex,
                              subsubCategory.sub_sub_category_name
                            );
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          }}
                          className="py-[7px] flex flex-col md:flex-row justify-between items-center gap-3 border-r border-b border-gray-200 cursor-pointer hover:bg-[#EBFFEF] bg-white"
                        >
                          <div className="flex flex-col md:flex-row items-center">
                            <div className="h-[3.7rem] w-[3.7rem]">
                              <img
                                draggable="false"
                                className="h-full w-full object-contain rounded-full"
                                src={subsubCategory.sub_sub_category_img}
                                alt={subsubCategory.sub_sub_category_name}
                              />
                            </div>
                            <div
                              className="ml-3 truncate w-[3rem]"
                              title={subsubCategory.sub_sub_category_name}
                            >
                              <span className="mt-2  text-custom-gray text-[15px] font-medium leading-[18px] group-hover:text-primary-blue ">
                                {subsubCategory.sub_sub_category_name}
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 overflow-auto mt-6">
            {loading ? (
              <Loader />
            ) : (
              <>
                <div className="flex items-center justify-between border rounded-lg border-[0.5px] border-gray-300 mb-2 bg-white text-gray-900 p-2 font-semibold text-[13px] shadow-[0px 0px 10px 0px rgba(0, 0, 0, 0.07)] hidden lg:flex">
                  <div className="flex items-center">
                    <span className="font-sans text-lg">
                      Buy{" "}
                      {categoryNames?.categoryName
                        ? categoryNames.categoryName
                        : ""}{" "}
                      Online
                    </span>
                  </div>
                  <div className="ml-auto pr-6 flex flex-cols gap-2 items-center">
                    <span className="font-sans text-md mt-2">Sort By</span>
                    <div className="">
                      <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="px-2 py-2 border rounded-lg bg-white w-[11rem] shadow-inner focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-between"
                      >
                        <span>
                          {options.find((option) => option.value === sortOption)
                            ?.label || "Select an option"}
                        </span>
                        <svg
                          className="ml-2 fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M7 10l5 5 5-5H7z" />
                        </svg>
                      </button>

                      {isOpen && (
                        <ul className="absolute w-[11rem] rounded-lg border bg-white shadow-md z-10">
                          {options.map((option) => (
                            <li
                              key={option.value}
                              onClick={() => handleOptionClick(option.value)}
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer border-b border-gray-200"
                            >
                              {option.label}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="flex flex-col gap-3 md:h-[47rem] lg:h-[40rem] h-[38.5rem] overflow-y-auto hidden-scrollbar"
                  ref={childRef}
                >
                  <div className="grid grid-cols-2 h-full md:h-full sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-2 px-1 mt-[0rem] lg:mt-0">
                    {sortedProducts().length > 0 ? (
                      sortedProducts().map((item) => (
                        <Product key={item.Product_id} id={item.Product_id} product={item} />
                      ))
                    ) : (
                      <div className="flex items-center justify-center">
                        <div>No products found</div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default Products;
