import React from "react";
import { useNavigate } from "react-router-dom";
import Orders from "./Orders";
import { useSelector } from "react-redux";

const MainData = () => {
  const navigate = useNavigate();

  const { orders } = useSelector((state) => state.allOrders)

  console.log("Main data:", orders);

  const getOrderCounts = (status) => {
    return orders.filter(order => order.status === status).length;
  }

  const popularProducts = [
    { name: "Product 1", sales: 20, ratings: 4.5, img: "https://ik.imagekit.io/efsdltq0e/product_images/product_1720781237454_Prodouct_img_0_hFg_Fe-qAV" },
    { name: "Product 2", sales: 15, ratings: 4.5, img: "https://ik.imagekit.io/efsdltq0e/product_images/product_1720781249748_Prodouct_img_0_rqXrGrKFy" },
    { name: "Product 2", sales: 15, ratings: 4.5, img: "https://ik.imagekit.io/efsdltq0e/product_images/product_1720781249748_Prodouct_img_0_rqXrGrKFy" },
  ]

  const topSellingProducts = [
    { name: "Product 3", sales: 30, img: "https://ik.imagekit.io/efsdltq0e/product_images/product_1720781237454_Prodouct_img_0_hFg_Fe-qAV" },
    { name: "Product 4", sales: 25, img: "https://ik.imagekit.io/efsdltq0e/product_images/product_1720781237454_Prodouct_img_0_hFg_Fe-qAV" },
  ]

  return (
    <>
      <div className="bg-white border border-gray-300 rounded-lg shadow-md p-4">
        <div className="p-2 mb-4">
          <h1 className="text-lg font-bold">Order Analytics</h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6">
          <div
            className="flex justify-between items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[4rem] cursor-pointer transition-all duration-200 ease-in-out"
            onClick={() => navigate("/store/orders")}
          >
            <div className="flex items-center">
              <img
                src="/images/Pending.png" // Path to your image
                alt="Confirmed"
                className="w-5 h-5" // Adjust the size as needed
              />
              <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                Pending
              </h4>
            </div>
            <h2 className="text-blue-500 text-xs sm:text-xl font-bold">
              {getOrderCounts("New")}
            </h2>
          </div>
          <div
            className="flex justify-between items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[4rem] cursor-pointer transition-all duration-200 ease-in-out"
            onClick={() => navigate("/store/orders")}
          >
            <div className="flex items-center">
              <img
                src="/images/Confirmed.png" // Path to your image
                alt="Confirmed"
                className="w-5 h-5" // Adjust the size as needed
              />
              <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                Confirmed
              </h4>
            </div>
            <h2 className="text-green-500 text-xs sm:text-xl font-bold">
              {getOrderCounts("Accepted")}
            </h2>
          </div>
          <div
            className="flex justify-between items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[4rem] cursor-pointer transition-all duration-200 ease-in-out"
            onClick={() => navigate("/store/orders")}
          >
            <div className="flex items-center">
              <img
                src="/images/Packaging.png" // Path to your image
                alt="Confirmed"
                className="w-5 h-5" // Adjust the size as needed
              />
              <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                Packaging
              </h4>
            </div>
            <h2 className="text-red-500 text-xs sm:text-xl font-bold">
              {getOrderCounts("Packaging")}
            </h2>
          </div>
          <div
            className="flex justify-between items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[4rem] cursor-pointer transition-all duration-200 ease-in-out"
            onClick={() => navigate("/store/orders")}
          >
            <div className="flex items-center">
              <img
                src="/images/out-of-delivery.png" // Path to your image
                alt="Confirmed"
                className="w-5 h-5" // Adjust the size as needed
              />
              <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                Out for Delivery
              </h4>
            </div>
            <h2 className="text-green-500 text-xs sm:text-xl font-bold">
              {getOrderCounts("Dispatched")}
            </h2>
          </div>
          <div
            className="flex justify-between items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[4rem] cursor-pointer transition-all duration-200 ease-in-out"
            onClick={() => navigate("/store/orders")}
          >
            <div className="flex items-center">
              <img
                src="/images/delivered.png" // Path to your image
                alt="Confirmed"
                className="w-5 h-5" // Adjust the size as needed
              />
              <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                Delivered
              </h4>
            </div>
            <h2 className="text-green-500 text-xs sm:text-xl font-bold">
              {getOrderCounts("Delivered")}
            </h2>
          </div>
          <div
            className="flex justify-between items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[4rem] cursor-pointer transition-all duration-200 ease-in-out"
            onClick={() => navigate("/store/orders")}
          >
            <div className="flex items-center">
              <img
                src="/images/canceled.png" // Path to your image
                alt="Confirmed"
                className="w-5 h-5" // Adjust the size as needed
              />
              <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                Canceled
              </h4>
            </div>
            <h2 className="text-red-500 text-xs sm:text-xl font-bold">
              {getOrderCounts("Canceled")}
            </h2>
          </div>
          <div
            className="flex justify-between items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[4rem] cursor-pointer transition-all duration-200 ease-in-out"
            onClick={() => navigate("/store/orders")}
          >
            <div className="flex items-center">
              <img
                src="/images/returned.png" // Path to your image
                alt="Confirmed"
                className="w-5 h-5" // Adjust the size as needed
              />
              <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                Returned
              </h4>
            </div>
            <h2 className="text-red-500 text-xs sm:text-xl font-bold">
              {getOrderCounts("Returned")}
            </h2>
          </div>
        </div>
      </div>

      <div className="bg-white border border-gray-300 rounded-lg shadow-md p-4 mt-8">
        <div className="p-2 mb-4">
          <h1 className="text-lg font-bold">Store Wallet</h1>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {/* First Column */}
          <div
            className="lg:col-span-1 flex flex-col justify-center items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-6 h-full cursor-pointer transition-all duration-200 ease-in-out" // Centered content vertically
            onClick={() => navigate("/store/orders")}
          >
            <div className="flex flex-col items-center mb-6">
              <img
                src="/images/withdraw.png"
                alt="Withdraw"
                className="w-16 h-16 mb-3" // Adjusted image size
              />
              <h4 className="text-black text-lg sm:text-xl font-bold mb-1">
                Rs. 1,00,000
              </h4>
              <h4 className="text-gray-500 text-sm sm:text-base font-medium">
                Withdrawable Amount
              </h4>
            </div>
            <button className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 text-lg">
              Withdraw
            </button>
          </div>

          {/* Second Column */}
          <div className="lg:col-span-2 grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="lg:col-span-2 grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div
                className="flex items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[6rem] cursor-pointer transition-all duration-200 ease-in-out" // Adjust height and transition
                onClick={() => navigate("/store/orders")}
              >
                <div className="flex flex-col mr-4 flex-grow">
                  {" "}
                  {/* Add flex-grow to take up remaining space */}
                  <h2 className="text-black text-xl font-bold mb-1">
                  Rs. 10,000
                  </h2>
                  <h4 className="text-gray-500 font-medium text-sm">Pending Withdrawel</h4>
                </div>
                <img
                  src="/images/pw.png"
                  alt="Packaging"
                  className="w-12 h-12 ml-auto" // Move image to the right end
                />
              </div>
              <div
                className="flex items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[6rem] cursor-pointer transition-all duration-200 ease-in-out" // Adjust height and transition
                onClick={() => navigate("/store/orders")}
              >
                <div className="flex flex-col mr-4 flex-grow">
                  {" "}
                  {/* Add flex-grow to take up remaining space */}
                  <h2 className="text-black text-xl font-bold mb-1">
                  Rs. 1,00,000
                  </h2>
                  <h4 className="text-gray-500 font-medium text-sm">Total Commission Given</h4>
                </div>
                <img
                  src="/images/tcg.png"
                  alt="Packaging"
                  className="w-12 h-12 ml-auto" // Move image to the right end
                />
              </div>
            </div>
            <div
              className="flex items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[6rem] cursor-pointer transition-all duration-200 ease-in-out" // Adjust height and transition
              onClick={() => navigate("/store/orders")}
            >
              <div className="flex flex-col mr-4 flex-grow">
                {" "}
                {/* Add flex-grow to take up remaining space */}
                <h2 className="text-black text-xl font-bold mb-1">
                Rs. 1,00,000
                </h2>
                <h4 className="text-gray-500 font-medium text-sm">Already Withdrawn</h4>
              </div>
              <img
                src="/images/aw.png"
                alt="Packaging"
                className="w-12 h-12 ml-auto" // Move image to the right end
              />
            </div>
            <div
              className="flex items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[6rem] cursor-pointer transition-all duration-200 ease-in-out" // Adjust height and transition
              onClick={() => navigate("/store/orders")}
            >
              <div className="flex flex-col mr-4 flex-grow">
                {" "}
                {/* Add flex-grow to take up remaining space */}
                <h2 className="text-black text-xl font-bold mb-1">
                Rs. 1,00,000
                </h2>
                <h4 className="text-gray-500 font-medium text-sm">Total Delivery Charge Earned</h4>
              </div>
              <img
                src="/images/tdce.png"
                alt="Packaging"
                className="w-12 h-12 ml-auto" // Move image to the right end
              />
            </div>
            <div
              className="flex items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[6rem] cursor-pointer transition-all duration-200 ease-in-out" // Adjust height and transition
              onClick={() => navigate("/store/orders")}
            >
              <div className="flex flex-col mr-4 flex-grow">
                {" "}
                {/* Add flex-grow to take up remaining space */}
                <h2 className="text-black text-xl font-bold mb-1">
                Rs. 1,00,000
                </h2>
                <h4 className="text-gray-500 font-medium text-sm">Total Tax Given</h4>
              </div>
              <img
                src="/images/ttg.png"
                alt="Packaging"
                className="w-12 h-12 ml-auto" // Move image to the right end
              />
            </div>
            <div
              className="flex items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[6rem] cursor-pointer transition-all duration-200 ease-in-out" // Adjust height and transition
              onClick={() => navigate("/store/orders")}
            >
              <div className="flex flex-col mr-4 flex-grow">
                {" "}
                {/* Add flex-grow to take up remaining space */}
                <h2 className="text-black text-xl font-bold mb-1">
                Rs. 1,00,000
                </h2>
                <h4 className="text-gray-500 font-medium text-sm">Collected Cash</h4>
              </div>
              <img
                src="/images/cc.png"
                alt="Packaging"
                className="w-12 h-12 ml-auto" // Move image to the right end
              />
            </div>
          </div>
        </div>
      </div>
      {/* New Section for Popular and Top Selling Products */}
      <div className="mt-8">
        
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-xl font-bold mb-4">Most Popular Products</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              {popularProducts.map((product, index) => (
                 <div key={index} className="bg-gray-50 flex flex-col items-center rounded-md border border-slate-100 p-2 w-50 hover:shadow-md md:p-1">
                 <img src={product.img} alt={product.name} className="w-14 h-14 mb-2 border border-blue-400 rounded-md sm:w-20 h-20" />
                 <h3 className="text-sm font-medium mb-1">{product.name}</h3>
                 <span className="text-xs">Ratings: {product.ratings}</span>
               </div>
              ))}
            </div>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-4">
            <h2 className="text-xl font-bold mb-4">Top Selling Products</h2>
            <div className="grid grid-cols-1 md:grid-cols-1 gap-2">
              {topSellingProducts.map((product, index) => (
                <div
                className="flex justify-between items-center bg-gray-50 text-white rounded-md hover:shadow-xl p-3 h-[4rem] cursor-pointer transition-all duration-200 ease-in-out"
                onClick={() => navigate("/store/orders")}
              >
                <div className="flex items-center">
                  <img
                    src={product.img} // Path to your image
                    alt="Confirmed"
                    className="w-12 h-12 border border-blue-400" // Adjust the size as needed
                  />
                  <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                    {product.name}
                  </h4>
                </div>
                <h2 className="text-blue-400 text-xs sm:text-xs border border-slate-100 p-1">
                  Sold: {product.sales}
                </h2>
              </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainData;
