import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import InventoryIcon from "@mui/icons-material/Inventory";
import GroupIcon from "@mui/icons-material/Group";
import ReviewsIcon from "@mui/icons-material/Reviews";
import AddBoxIcon from "@mui/icons-material/AddBox";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";
import { useDispatch, useSelector } from "react-redux";
import "./Sidebar.css";
import { useSnackbar } from "notistack";
import { logoutUser } from "../../../actions/userAction";
import logo from "../../../assets/images/logo1.png";

const navMenu = [
  {
    icon: <EqualizerIcon />,
    label: "Dashboard",
    ref: "/admin/dashboard",
  },
  {
    icon: <EqualizerIcon />,
    label: "Location",
    ref: "/admin/location",
  },
  {
    icon: <ShoppingBagIcon />,
    label: "Orders",
    ref: "/admin/orders",
  },
  {
    icon: <InventoryIcon />,
    label: "Products",
    ref: "/admin/products",
  },
  {
    icon: <AddBoxIcon />,
    label: "Add Product",
    ref: "/admin/new_product",
  },
  {
    icon: <AddBoxIcon />,
    label: "Categories",
    ref: "/admin/Category",
  },
  {
    icon: <AddBoxIcon />,
    label: "Sub Categories",
    ref: "/admin/SubCategory",
  },
  {
    icon: <AddBoxIcon />,
    label: "Sub Sub Categories",
    ref: "/admin/SubSubCategory",
  },
  {
    icon: <GroupIcon />,
    label: "Users",
    ref: "/admin/users",
  },
  {
    icon: <ReviewsIcon />,
    label: "Reviews",
    ref: "/admin/reviews",
  },
  {
    icon: <AccountBoxIcon />,
    label: "My Profile",
    ref: "/account",
  },
  {
    icon: <LogoutIcon />,
    label: "Logout",
  },
];

const Sidebar = ({ activeTab, setToggleSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.user);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleLogout = () => {
    dispatch(logoutUser());
    enqueueSnackbar("Logout Successfully", { variant: "success" });
    navigate("/login");
    setToggleSidebar(false); // Close sidebar after logout
  };

  const handleNavItemClick = () => {
    if (isMobile) {
      setToggleSidebar(false); // Close sidebar after navigation item click in mobile view
    }
  };

  return (
    <aside className="sidebar z-10 sm:z-0 block min-h-screen fixed left-0 pb-14 max-h-screen w-3/4 sm:w-1/5 bg-gray-800 text-white overflow-x-hidden border-r">
      <img
        draggable="false"
        className="h-20 w-44 relative top-4"
        src={logo}
        alt="Vivimart Logo"
      />
      {/* {user && (
        <div className="flex items-center gap-3 bg-gray-700 p-2 rounded-lg shadow-lg my-4 mx-3.5">
          <Avatar alt="Avatar" src={user.avatar.url} />
          <div className="flex flex-col gap-0">
            <span className="font-medium text-lg">{user.name}</span>
            <span className="text-gray-300 text-sm">{user.email}</span>
          </div>
          <button
            onClick={() => setToggleSidebar(false)}
            className="sm:hidden bg-gray-800 ml-auto rounded-full w-10 h-10 flex items-center justify-center"
          >
            <CloseIcon />
          </button>
        </div>
      )} */}

      <div className="flex flex-col w-full gap-0 my-8">
        {navMenu.map((item, index) => {
          const { icon, label, ref } = item;
          const isActive = location.pathname === ref;
          return (
            <React.Fragment key={index}>
              {label === "Logout" ? (
                <button
                  onClick={handleLogout}
                  className="hover:bg-gray-700 flex gap-3 items-center py-3 px-4 font-medium"
                >
                  <span>{icon}</span>
                  <span>{label}</span>
                </button>
              ) : (
                <Link
                  to={ref}
                  onClick={handleNavItemClick}
                  className={`${
                    isActive ? "bg-gray-700" : "hover:bg-gray-700"
                  } flex gap-3 items-center py-3 px-4 font-medium`}
                >
                  <span>{icon}</span>
                  <span>{label}</span>
                </Link>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </aside>
  );
};

export default Sidebar;

