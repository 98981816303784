import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addItemsToCart,
  removeItemsFromCart,
  emptyCart,
} from "../../actions/cartAction";
import CartItem from "./CartItem";       
import EmptyCart from "./EmptyCart";
import PriceSidebar from "./PriceSidebar";
import CloseIcon from "@mui/icons-material/Close";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AddIcon from "@mui/icons-material/Add";
import HomeIcon from "@mui/icons-material/Home";
import EditIcon from "@mui/icons-material/Edit";

import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import { ADMIN_PRODUCT_API } from "../../utils/constants";
import StandardDelivery from "./StandardDelivery";
import FastDelivery from "./FastDelivery";

const Cart = ({ closeCartSidebar, setIsEdit, setIsOpen }) => {
  const navigate = useNavigate();
  const { cartItems } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [addedProduct, setAddedProduct] = useState([]);
  const [delivery, setDelivery] = useState("FastDelivery");
  const [proceed, setProceed] = useState(false);
  const [fastDelivery, setFastDelivery] = useState([]);
  const [stdDelivery, setStdDelivery] = useState([]);
  const addressDetails =
    JSON.parse(localStorage.getItem("addressDetails")) || {};
  const [editingIndex, setEditingIndex] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const uniqueProductIds = Array.from(
          new Set(cartItems.map((item) => item.product_id))
        );

        const promises = uniqueProductIds.map(async (productId) => {
          const cartItem = cartItems.find(
            (item) => item.product_id === productId
          );

          if (cartItem) {
            const response = await fetch(`${ADMIN_PRODUCT_API}/${productId}`);
            const productData = await response.json();
            return { ...productData, quantity: cartItem.quantity };
          }

          return null; // Return null if the product does not exist
        });

        const productsWithQuantity = (await Promise.all(promises)).filter(
          Boolean
        ); // Remove null values
        setAddedProduct(productsWithQuantity);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchData();
  }, [cartItems]);

  const handleFastDelivery = () => {
    setDelivery("FastDelivery");
    const filteredProducts = addedProduct.filter(
      (product) =>
        product.delivery_option &&
        product.delivery_option.toLowerCase() === "fast delivery"
    );
    setFastDelivery(filteredProducts);
  };

  const handleStdDelivery = () => {
    setDelivery("StandardDelivery");
    const filteredProducts = addedProduct.filter(
      (product) =>
        product.delivery_option &&
        product.delivery_option.toLowerCase() === "standard delivery"
    );
    setStdDelivery(filteredProducts);
  };

  const addAddress = () => {
    setIsEdit(false);
    setIsOpen(true);
  };

  const handleEdit = (index) => {
    handleSelectAddress(index);
    setIsEdit(true);
    setEditingIndex(index);
  };
  

  const placeOrderHandler = () => {
    // navigate("/login?redirect=shipping");
    closeCartSidebar();
  };

  //select address for delivery
  const handleSelectAddress = (index) => {
    const address = addressDetails[index]; // Get the address object
    setSelectedAddress(address); // Update the state with the address object
    setProceed(false); // Close the address modal or sidebar
  };

  return (
    <div className=" w-[25rem] bg-white shadow-lg h-full hidden-scrollbar overflow-y-auto transition-transform lg:transform lg:translate-x-0">
      <div className="rounded-none border-0 bottom-0 shadow-md right-0 top-0 transition-all duration-400 ease-in-out w-full absolute bg-blue-100 outline-none hidden-scrollbar overflow-auto p-0">
        <div
          className="min-h-screen overflow-y-auto"
          style={{
            backgroundColor:
              delivery === "FastDelivery" ? "rgb(209, 240, 213)" : "#f1c992",
          }}
        >
          {!proceed && (
            <>
              <div className="top-0 w-full z-20 sticky bg-white">
                <div className="px-4 py-4 flex justify-between items-center">
                  <span className="leading-5 text-lg font-okra text-gray-800 font-bold">
                    My Cart
                  </span>
                  <div
                    className="text-xl text-gray-800 font-customfont cursor-pointer font-bold"
                    onClick={closeCartSidebar}
                  >
                    <CloseIcon />
                  </div>
                </div>
              </div>
              <div className="w-full p-3">
                {cartItems.length === 0 ? (
                  <EmptyCart closeCartSidebar={closeCartSidebar} />
                ) : (
                  <>
                    <div className="px-2 py-1 w-full flex items-center bg-white rounded-xl border border-gray-300">
                      <div
                        className="flex-1 py-2 px-3 text-center cursor-pointer rounded-md"
                        onClick={() => handleFastDelivery()}
                        style={{
                          backgroundColor:
                            delivery === "FastDelivery"
                              ? "rgb(209, 240, 213)"
                              : "white",
                        }}
                      >
                        <span>Fast Delivery</span>
                        <Tooltip title="Fast Delivery" arrow>
                          <span className="px-1">
                            <InfoIcon style={{ fontSize: "18px" }} />
                          </span>
                        </Tooltip>
                      </div>
                      <div
                        className="flex-1 py-2 px-3 text-center cursor-pointer rounded-md"
                        onClick={() => handleStdDelivery()}
                        style={{
                          backgroundColor:
                            delivery === "StandardDelivery"
                              ? "#f1c992"
                              : "white",
                        }}
                      >
                        <span>Standard Delivery</span>
                        <Tooltip title="Standard Delivery" arrow>
                          <span className="px-1">
                            <InfoIcon style={{ fontSize: "18px" }} />
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                    {delivery === "StandardDelivery" && (
                      <StandardDelivery
                        setProceed={setProceed}
                        closeCartSidebar={closeCartSidebar}
                        delivery={delivery}
                        stdDelivery={stdDelivery}
                        selectedAddress={selectedAddress}
                        setSelectedAddress={setSelectedAddress}
                      />
                    )}
                    {delivery === "FastDelivery" && (
                      <FastDelivery
                        setProceed={setProceed}
                        closeCartSidebar={closeCartSidebar}
                        delivery={delivery}
                        fastDelivery={fastDelivery}
                      />
                    )}
                  </>
                )}
              </div>
            </>
          )}
          {proceed && (
            <div>
              <div className="top-0 w-full z-20 sticky bg-white">
                <div className="px-4 py-3 flex items-center font-sans">
                  <div
                    className="text-xl text-gray-800 font-customfont cursor-pointer font-bold"
                    onClick={() => setProceed(false)}
                  >
                    <KeyboardBackspaceIcon />
                  </div>
                  <span className="leading-5 text-lg font-okra text-gray-800 font-bold pl-4 pt-1">
                    Select delivery address
                  </span>
                </div>
              </div>
              <div className="w-full p-3">
                <div className="p-3 box-border bg-white rounded-xl cursor-pointer">
                  <div
                    className="flex items-center gap-4"
                    style={{ color: "rgb(12, 131, 31)" }}
                    onClick={() => addAddress()}
                  >
                    <div>
                      <AddIcon />
                    </div>
                    <div>
                      <span>Add a new address</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full p-3">
                <div className="pl-4 font-sans text-sm">
                  <span>Your saved address</span>
                </div>
                <div className="p-3 box-border bg-white rounded-xl mt-3 flex">
                  <div className="ml-6 flex flex-col font-sans w-full max-w-2xl rounded-lg">
                    {addressDetails.length > 0 ? (
                      addressDetails.map((address, index) => (
                        <div
                          key={index}
                          className="box-border w-full max-w-md border border-gray-300 bg-white rounded-xl p-4 mt-2 flex items-start justify-between cursor-pointer"
                          onClick={() => handleSelectAddress(index)}
                        >
                          <div className="w-full flex flex-cols items-center justify-center">
                            <div className="flex-row w-64">
                            <div className="text-lg font-bold">
                              {address.name}
                            </div>
                            <div className="text-sm">
                              {address.house}, {address.floor}, {address.area},{" "}
                              {address.landmark}
                            </div>
                            <div className="text-sm">{address.phoneNumber}</div>
                            <div className="text-sm">
                              {address.selectResident}
                            </div>
                            </div>                            
                            <div>
                              <EditIcon
                                onClick={() => handleEdit(index)}
                                style={{ color: "rgb(12, 131, 31)" }}
                              />
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>No addresses available</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cart;
