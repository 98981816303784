import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../../actions/userAction";
import MetaData from "../Layouts/MetaData";
import axios from "axios";
import { BASE_URL } from "../../config";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState({
    id: "",
    name: "",
    email: "",
    password: "",
    role: "",
    address: "",
    phone: "",
  });

  const { id, name, email, password, role, address, phone } = user;

  const handleRegister = async (e) => {
    e.preventDefault();
    if (password.length < 8) {
      enqueueSnackbar("Password length must be at least 8 characters", {
        variant: "warning",
      });
      return;
    }

    const userData = {
      id,
      name,
      email,
      password,
      role,
      address,
      phone,
    };


    try {
      const response = await axios.post(`${BASE_URL}/api/users/register`, userData);

      
    } catch (error) {
      if (error.response) {
        enqueueSnackbar("Registration done", { variant: "success" });
      }
    }
  };

  const handleDataChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  return (
    <>
      <MetaData title="Register | Vivimart" />

      <main className="w-full mt-12 sm:pt-20 sm:mt-0">
        <div className="flex sm:w-4/6 m-auto mb-7 bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="w-1/3 bg-primary-blue text-white py-12 px-8">
            <h2 className="text-3xl font-bold mb-4">
              Looks like you're new here!
            </h2>
            <p className="text-lg">
              Sign up with your mobile number to get started.
            </p>
          </div>

          <div className="w-2/3 p-8">
            <form onSubmit={handleRegister} className="space-y-4">
              <TextField
                fullWidth
                id="full-name"
                label="Full Name"
                name="name"
                value={name}
                onChange={handleDataChange}
                required
                size="small"
                variant="outlined"
              />

              <TextField
                fullWidth
                id="email"
                label="Email"
                type="email"
                name="email"
                value={email}
                onChange={handleDataChange}
                required
                size="small"
                variant="outlined"
              />

              <TextField
                fullWidth
                id="password"
                label="Password"
                type="password"
                name="password"
                value={password}
                onChange={handleDataChange}
                required
                size="small"
                variant="outlined"
              />

              <TextField
                fullWidth
                id="role"
                label="Role"
                name="role"
                value={role}
                onChange={handleDataChange}
                required
                size="small"
                variant="outlined"
              />

              <TextField
                fullWidth
                id="address"
                label="Address"
                name="address"
                value={address}
                onChange={handleDataChange}
                required
                size="small"
                variant="outlined"
              />

              <TextField
                fullWidth
                id="phone"
                label="Phone Number"
                name="phone"
                value={phone}
                onChange={handleDataChange}
                required
                size="small"
                variant="outlined"
              />

              <button
                type="submit"
                className="w-full bg-primary-orange text-white py-3 rounded-lg font-medium shadow-md hover:shadow-lg mb-4"
              >
                Signup
              </button>

              <div className="w-full flex justify-center items-center">
                <Link
                  to="/admin/login"
                  className="text-center shadow-sm hover:bg-gray-200 text-primary-blue font-medium"
                >
                  Existing User? Log in
                </Link>
              </div>
            </form>
          </div>
        </div>
      </main>
    </>
  );
};

export default Register;
