export const GET_SUB_CATEGORY_REQUEST = "GET_SUB_CATEGORY_REQUEST";
export const GET_SUB_CATEGORY_SUCCESS = "GET_SUB_CATEGORY_SUCCESS";
export const GET_SUB_CATEGORY_FAILURE = "GET_SUB_CATEGORY_FAILURE";

export const GET_SUB_SUB_CATEGORY_REQUEST = "GET_SUB_SUB_CATEGORY_REQUEST";
export const GET_SUB_SUB_CATEGORY_SUCCESS = "GET_SUB_SUB_CATEGORY_SUCCESS";
export const GET_SUB_SUB_CATEGORY_FAILURE = "GET_SUB_SUB_CATEGORY_FAILURE";

export const GET_CATEGORY_NAME_REQUEST = "GET_CATEGORY_NAME_REQUEST";
export const GET_CATEGORY_NAME_SUCCESS = "GET_CATEGORY_NAME_SUCCESS";
export const GET_CATEGORY_NAME_FAILURE = "GET_CATEGORY_NAME_FAILURE";