import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';

const Searchbar = () => {
    const [keyword, setKeyword] = useState("");
    const [placeholderIndex, setPlaceholderIndex] = useState(0);
    const navigate = useNavigate();

    const placeholders = ["Fruits", "Vegetables", "Groceries"];

    useEffect(() => {
        const interval = setInterval(() => {
            setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
        }, 2000);

        return () => clearInterval(interval);
    }, [placeholders.length]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (keyword.trim()) {
            navigate(`/search/${keyword}`);
        } else {
            navigate('/search');
        }
    }

    const placeholderStyles = {
        position: "absolute",
        top: "30%",
        left: "0",
        transform: "translateY(-50%)",
        width: "100%",
        textAlign: "left",
        color: "gray",
        pointerEvents: "none",
        animation: "placeholderUp 2s infinite"
    };

    const formStyles = {
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        borderRadius: "1rem",
        backgroundColor: "#f3f4f6",
        height: "3rem",
        color: "#6b7280",
        padding: "0 0.75rem",
        boxShadow: "none",
        overflow: "hidden",
        position: "relative"
    };

    const inputContainerStyles = {
        position: "relative",
        flexGrow: 1,
        flexShrink: 1,
        height: "100%",
        overflow: "hidden"
    };

    const inputStyles = {
        position: "absolute",
        width: "100%",
        height: "100%",
        outline: "none",
        backgroundColor: "transparent",
        padding: "0",
        border: "none"
    };

    const keyframesStyles = `
        @keyframes placeholderUp {
            0% {
                transform: translateY(100%);
                opacity: 0;
            }
            50% {
                transform: translateY(0);
                opacity: 1;
            }
            100% {
                transform: translateY(-100%);
                opacity: 0;
            }
        }
    `;

    return (
        <form onSubmit={handleSubmit} style={formStyles}>
            <button type="submit" style={{ color: "#3b82f6" }}>
                <SearchIcon />
            </button>
            <div style={inputContainerStyles}>
                <input
                    style={inputStyles}
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    type="text"
                />
                {!keyword && (
                    <div style={placeholderStyles}>
                        <style>{keyframesStyles}</style>
                        <span>Search <q>{placeholders[placeholderIndex]}</q></span>
                    </div>
                )}
            </div>
        </form>
    );
};

export default Searchbar;
