import CloseIcon from "@mui/icons-material/Close";

const EmptyCart = ({ closeCartSidebar }) => {
  return (
    <div className="flex flex-col gap-2 m-6">
      <div className="px-2 box-border bg-transparent">
        <div className="overflow-hidden rounded-lg ml-0 bg-white">
          <div className="flex flex-col items-center bg-white p-4">
            <div className="w-52 h-44">
              <img
                draggable="false"
                className="w-full h-full object-contain"
                src="https://rukminim1.flixcart.com/www/800/800/promos/16/05/2019/d438a32e-765a-4d8b-b4a6-520b560971e8.png"
                alt="Empty Cart"
              />
            </div>
            <span className="text-lg">Your cart is empty!</span>
            <p className="text-xs">Add items to it now.</p>
            <button
              onClick={closeCartSidebar}
              className="bg-primary-blue text-sm text-white px-12 py-2 rounded-sm shadow mt-3"
            >
              Shop Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyCart;
