import React from 'react';
import MetaData from '../MetaData';

const Privacy = () => {
  return (
    <div className="w-full mt-20 px-4 sm:px-8 md:px-16 lg:px-32 xl:px-64">
      <MetaData title="Privacy Policy | Vivimart" />
      <main className="bg-white p-6 shadow-lg rounded-lg">
        <h1 className="text-3xl font-bold mb-12">Privacy Policy</h1>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-6">1. General</h2>
          <p className="mb-4 text-justify tracking-wide">
            1.1. GVS FOOD AND RETAIL PRIVATE LIMITED, with its headquarters located at Srivari Srimat, 3rd Floor, Door Old No.736/6, New No.1045, Avinashi Road, Coimbatore-641018, India (referred to as "GVS," "we," "us," or "our") is the entity responsible for managing and operating the mobile application/website and tablet applications available at <span className='text-blue-700 underline'><a href='www.vivimart.in' target='_blank'>www.vivimart.in</a></span>, collectively known as "ViviMart," "Website," or "App." ViviMart facilitates the sale and purchase of products and services listed and sold on the Website, and the terms "User," "Users," "you," or "your" refer to individuals or entities that use, access, browse the Website, and/or purchase the Products.
          </p>
          <p className="mb-4 text-justify">
            1.2. ViviMart is dedicated to safeguarding your privacy. This Privacy Policy is to be read in conjunction with the Terms of Use and other policies that we may notify you of. It outlines our privacy practices concerning the collection, use, disclosure, processing, transfer, storage, retention, and safeguarding of the information provided to us through the Website and/or as part of product purchases.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-6">2. Applicability of Policy</h2>
          <p className="mb-4 text-justify">
            2.1. By accessing, using, browsing, or purchasing on ViviMart, or by submitting information (and in the case of a minor, by having their parents or guardians submit information on their behalf), you agree to be bound by this Privacy Policy. You consent to the collection, storage, possession, handling, sharing, disclosure, or transfer of your information as per the terms of this Privacy Policy. You also agree to be governed by Indian laws, including those related to data protection and privacy.
          </p>
          <p className="mb-4 text-justify">
            2.2. While we primarily offer our Products within India, you may access or browse the Website from outside India. Please be aware that your information will be primarily stored and processed in India, subject to Indian laws, including data protection laws.
          </p>
          <p className="mb-4 text-justify">
            2.3. This document is considered an electronic record and is governed by the provisions of the Information Technology Act, 2000, and related rules. It does not require physical or digital signatures.
          </p>
          <p className="mb-4 text-justify">
            2.4. We recommend that you acquaint yourself with our Privacy Policy. If you do not agree with any provisions of the Terms or this Privacy Policy, we advise you not to use or access ViviMart.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">3. Type of Information Collected</h2>
          <p className="mb-4 text-justify">
            3.1. This Privacy Policy outlines the information we collect from Users, including Sensitive Personal Data or Information, the purpose of collection, and how we use, share, or transfer such information. You have the option to withdraw your consent for the collection and use of your information, including Personal Information and Sensitive Personal Data or Information. However, withdrawing consent may impact our ability to provide you with certain services. Your decision to withdraw consent will not affect the processing of information based on your previous consent.
          </p>
          <p className="mb-4 text-justify">
            3.2. We may collect various information, including your name, mailing address, phone number, email address, and contact preferences. This information, when combined with other data, can reasonably identify you and is referred to as "Personal Information."
          </p>
          <p className="mb-4 text-justify">
            3.3. We may also collect, receive, process, or store sensitive personal data or information provided by you, such as passwords, financial information (e.g., bank account or payment instrument details), and other data related to your financial transactions. All such data falls under the category of "Sensitive Personal Data or Information."
          </p>
          <p className="mb-4 text-justify">
            3.4. ViviMart is not intended for use by minors under the age of 18. If we become aware of collecting information from individuals below the age of 18 without parental consent, we will take steps to delete such information. In such cases, parents or guardians should contact us to have the information deleted. We are not liable for any damage or injury suffered by a minor who violates our Privacy Policy and Terms.
          </p>
        </section>

        {/* Repeat the structure for sections 4 to 13 */}

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">4. Use of Information Collected</h2>
          <p className="mb-4">
            4.1. We collect, use, share, and retain information in the following ways:
          </p>
          <p className="mb-4 text-justify">
            4.1.1. Collection: We collect information to provide you with the information, products, or services you request and to complete transactions or display products.
          </p>
          <p className="mb-4 text-justify">
            4.1.2. Usage: We use your information to create and provide access to your registered account, process product orders, assist merchants in fulfilling orders, develop and improve our products and services, inform you about our offerings and updates, resolve issues and disputes, and protect against errors, fraud, and illegal activities.
          </p>
          <p className="mb-4 text-justify">
            4.1.3. Sharing: We may share information with affiliates for a seamless shopping experience. You have the option to unsubscribe from data sharing. We may also share information with third-party service providers to facilitate the provision of our products and comply with legal requirements. In certain cases, we may disclose or transfer your information as part of reorganization or asset sales.
          </p>
          <p className="mb-4 text-justify">
            4.1.4. Retention: We retain information for the duration necessary to fulfill the purposes outlined in this Privacy Policy, or as required by law.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-6">5. Changes/Amendment/Modification to the Privacy Policy</h2>
          <p className="mb-4 text-justify">
            5.1. We will handle your information in accordance with applicable laws and regulations. This Privacy Policy is subject to changes and modifications based on business, legal, or regulatory requirements. Any significant changes will be communicated to you. Your continued use of the App after any modifications indicates your acceptance of such changes.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-6">6. Third-Party Applications, Websites & Services</h2>
          <p className="mb-4 text-justify">
            6.1. Your mobile service provider, mobile operating system provider, and third-party applications may collect, use, and share information about you. We do not control their practices. Links to third-party websites and advertisements displayed on the App are governed by their respective privacy policies, which are beyond our control.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-6">7. Non-Personal Information</h2>
          <p className="mb-4 text-justify">
            7.1. Non-personal information includes data that does not personally identify you. We collect, use, share, and retain non-personal information in accordance with this Privacy Policy.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-6">8. Location Services</h2>
          <p className="mb-4 text-justify">
            8.1. The App may access, collect, and analyze your location data for the performance of its services. You can enable or disable location settings on your Device.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-6">9. Cookies</h2>
          <p className="mb-4 text-justify">
            9.1. We receive information about the websites you visit, your IP address, and other details when you access the Website. "Cookies" are used to assist in providing our services. You can manage your cookie settings in your web browser.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-6">10. Other Information the App Collects</h2>
          <p className="mb-4 text-justify">
            10.1. The App may collect additional information as described and with your consent. Not providing certain information may affect your access to some services or the App.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-6">11. Opt-Out</h2>
          <p className="mb-4 text-justify">
            11.1. You have the option to opt-out of receiving non-essential promotional or marketing-related communications. You can remove your contact information from our mailing lists and newsletters by clicking on the "unsubscribe" link or following instructions in our emails.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-6">12. Access, Correction and Deletion</h2>
          <p className="mb-4 text-justify">
            12.1. You are responsible for ensuring that the information you provide is correct and up-to-date. You may request correction, updating, or deletion of your information. However, deletion or withdrawal of consent may impact your access to certain services or the App.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-6">13. Queries and Complaints</h2>
          <p className="mb-4 text-justify">
            13.1. If you have any concerns or feedback regarding the protection of your information or privacy-related matters, you can contact us at <a href='mailto:cs@vivimart.in'>cs@vivimart.in</a>. We are committed to safeguarding your information and value your support in this regard.
          </p>
        </section>
      </main>
    </div>
  );
};

export default Privacy;
