import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  ADMIN_SUB_CATEGORY_API,
  ADMIN_SUB_SUB_CATEGORY_API,
  URL,
  ADMIN_CATEGORY_API,
  ADMIN_PRODUCT_API,
} from "../../utils/constants";

const CsvUploadPopup = ({ isClose, onUploadSuccess, endPoint }) => {
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setFile(file);
    } else {
      setFileName("");
      setFile(null);
    }
  };

  const handleUpload = async () => {
    if (!file || !endPoint) {
      console.error("File or endpoint not defined.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    const endpoint =
      endPoint === "products" 
        ? "/products/upload"
        : endPoint === "category" 
        ? "/categories/upload"
        : endPoint === "subcategory"
        ? "/sub-categories/upload"
        : endPoint === "subsubcategory"
        ? "/sub-sub-categories/upload"
        : "";

    if (!endpoint) {
      console.error("Invalid endpoint.");
      return;
    }

    try {
      const response = await fetch(URL + endpoint, {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        let dataResponse;
        switch (endPoint) {
          case "products":
            dataResponse = await fetch(ADMIN_PRODUCT_API);
            break;
          case "category":
            dataResponse = await fetch(ADMIN_CATEGORY_API);
            break;
          case "subcategory":
            dataResponse = await fetch(ADMIN_SUB_CATEGORY_API);
            break;
          case "subsubcategory":
            dataResponse = await fetch(ADMIN_SUB_SUB_CATEGORY_API);
            break;
          default:
            console.error("Invalid endpoint.");
            return;
        }

        if (!dataResponse.ok) {
          throw new Error("Failed to fetch categories.");
        }
        const data = await dataResponse.json(); 
        const updatedCategories = data.map((category) => ({
          ...category,
        }));

        if (typeof onUploadSuccess === "function") {
          onUploadSuccess(updatedCategories);
        }
        if (typeof isClose === "function") {
          isClose(false);
        }
      } else {
        console.error("Failed to upload file:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const generateCSVData = () => {
    const headers = ["Categories_Id", "Categories_Name", "Categories_Img"];
    const rows = ["1", "Fruits", "Categories_Img"];
    let csvContent = headers.join(",") + "\n";
    csvContent += rows.map((row) => row.join(",")).join("\n");

    return csvContent;
  };

  const downloadSampleCSV = () => {
    const csvData = generateCSVData();
    const blob = new Blob(["\ufeff", csvData], {
      type: "application/vnd.ms-excel",
    });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "Products.xlsx";

    document.body.appendChild(a);

    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50">
      <div className="w-full max-w-lg absolute top-24 left-[33rem] bg-white h-72">
        <div className="flex items-center justify-between p-4 border-b border-gray-300">
          <h1 className="font-medium text-xl">Bulk XLS Upload</h1>
          <CloseIcon onClick={() => isClose(false)} />
        </div>
        <div>
          <div className="flex items-center p-4">
            <label
              htmlFor="file-upload"
              className="cursor-pointer bg-white rounded-md font-medium text-primary-blue hover:text-gray-600 py-2 px-4 border border-primary-blue"
            >
              Choose file
              <input
                id="file-upload"
                name="file-upload"
                type="file"
                onChange={handleFileChange}
              />
            </label>
            <p className="ml-2">{fileName || "No file chosen"}</p>
          </div>
          <button
            onClick={downloadSampleCSV}
            className="absolute left-[18rem] bg-primary-blue text-white rounded-md font-medium py-2 px-4 hover:bg-blue-700"
          >
            Download Sample CSV
          </button>
        </div>
        <div className=" border-b border-gray-300 mt-12"></div>
        <div className="absolute left-[22rem] top-[14rem]">
          <button
            onClick={handleUpload}
            className=" bg-primary-blue text-white rounded-md font-medium py-2 px-4 hover:bg-blue-700"
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  );
};

export default CsvUploadPopup;
