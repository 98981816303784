import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { NEW_PRODUCT_RESET } from "../../constants/productConstants";
import { createProduct, clearErrors } from "../../actions/productAction";
import CsvUploadPopup from "./CsvUploadPopup";
import MetaData from "../Layouts/MetaData";
import BackdropLoader from "../Layouts/BackdropLoader";
import { ADMIN_PRODUCT_API } from "../../utils/constants";

const NewProduct = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { loading, success, error } = useSelector((state) => state.newProduct);

  const [highlights, setHighlights] = useState([]);
  const [highlightInput, setHighlightInput] = useState("");
  const [specs, setSpecs] = useState([]);
  const [specsInput, setSpecsInput] = useState({
    title: "",
    description: "",
  });

  const [productId, setProductId] = useState(0);
  const [categories, setCategories] = useState("");
  const [sub_Categories, setSub_Categories] = useState("");
  const [subSub_Categories, setSubSub_Categories] = useState("");
  const [brand_name, setBrand_name] = useState("");
  const [product_name, setProduct_name] = useState("");
  const [weight, setWeight] = useState(0);
  const [size, setSize] = useState(0);
  const [mrp, setMrp] = useState(0);
  const [sell_price, setSell_price] = useState(0);
  const [offer, setOffer] = useState(0);
  const [you_save, setYou_save] = useState(0);
  const [kind, setKind] = useState("");
  const [exp_date, setExp_date] = useState("");
  const [about, setAbout] = useState("");
  const [benefits, setBenefits] = useState("");
  const [storage, setStorage] = useState("");
  const [prodouct_img0, setProdouct_img0] = useState([]);
  const [prodouct_img1, setProdouct_img1] = useState([]);
  const [prodouct_img2, setProdouct_img2] = useState([]);
  const [prodouct_img3, setProdouct_img3] = useState([]);
  const [prodouct_img4, setProdouct_img4] = useState([]);
  const [prodouct_img5, setProdouct_img5] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);

  const [logo, setLogo] = useState("");
  const [logoPreview, setLogoPreview] = useState("");
  const [productData, setProductData] = useState([]);
  const [originalproductData, setOriginalProductData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [sub_categoryData, setSubCategoryData] = useState([]);
  const [sub_sub_categoryData, setSubSubCategoryData] = useState([]);
  const [isCSVUploadOpen, setIsCSVUploadOpen] = useState(false);
  const [skuId, setSkuId] = useState(0);
  const [endPoint, setEndPoint] = useState("");

  useEffect(() => {
    fetch(ADMIN_PRODUCT_API)
      .then((response) => response.json())
      .then((productData) => {
        // Handle product data
        setProductData(productData.data);
        setOriginalProductData(productData.data);
        setProductId(productData.data.length + 1);
      })
      .catch((error) => console.error("Error fetching products:", error));
  }, []);

  const handleCategoryChange = (e) => {
    const selectedCategoryId = parseInt(e.target.value); // Convert to integer
    setCategories(selectedCategoryId);

    const filteredSubCategories = originalproductData.filter(
      (subcategory) => subcategory.Product_id === selectedCategoryId
    );
    setSubCategoryData(filteredSubCategories);
  };

  const handleSubCategoryChange = (e) => {
    const selectedSubCategoryId = parseInt(e.target.value); // Convert to integer
    setSub_Categories(selectedSubCategoryId);

    const filteredSubSubCategories = originalproductData.filter(
      (subsubcategory) => subsubcategory.Product_id === selectedSubCategoryId
    );
    setSubSubCategoryData(filteredSubSubCategories);
  };

  const handleSpecsChange = (e) => {
    setSpecsInput({ ...specsInput, [e.target.name]: e.target.value });
  };

  const addSpecs = () => {
    if (!specsInput.title.trim() || !specsInput.title.trim()) return;
    setSpecs([...specs, specsInput]);
    setSpecsInput({ title: "", description: "" });
  };

  const addHighlight = () => {
    if (!highlightInput.trim()) return;
    setHighlights([...highlights, highlightInput]);
    setHighlightInput("");
  };

  const deleteHighlight = (index) => {
    setHighlights(highlights.filter((h, i) => i !== index));
  };

  const deleteSpec = (index) => {
    setSpecs(specs.filter((s, i) => i !== index));
  };

  const handleLogoChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setLogoPreview(reader.result);
        setLogo(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleProductImageChange = (e) => {
    const files = Array.from(e.target.files);

    // setImages([]);
    setProdouct_img0([]);
    setProdouct_img1([]);
    setProdouct_img2([]);
    setProdouct_img3([]);
    setProdouct_img4([]);
    setProdouct_img5([]);
    setImagesPreview([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          // setImagesPreview((oldImages) => [...oldImages, reader.result]);
          // setImages((oldImages) => [...oldImages, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const newProductSubmitHandler = (e) => {
    e.preventDefault();

    // required field checks
    // if (highlights.length <= 0) {
    //   enqueueSnackbar("Add Highlights", { variant: "warning" });
    //   return;
    // }
    // if (!logo) {
    //   enqueueSnackbar("Add Brand Logo", { variant: "warning" });
    //   return;
    // }
    // if (specs.length <= 1) {
    //   enqueueSnackbar("Add Minimum 2 Specifications", { variant: "warning" });
    //   return;
    // }
    // if (images.length <= 0) {
    //   enqueueSnackbar("Add Product Images", { variant: "warning" });
    //   return;
    // }

    const formData = new FormData();

    formData.set("productId", productId);
    formData.set("categories", categories);
    formData.set("sub_Categories", sub_Categories);
    formData.set("subSub_Categories", subSub_Categories);
    formData.set("brand_name", brand_name);
    formData.set("product_name", product_name);
    formData.set("weight", weight);
    formData.set("size", size);
    formData.set("mrp", mrp);
    formData.set("sell_price", sell_price);
    formData.set("offer", offer);
    formData.set("kind", kind);
    formData.set("you_save", you_save);
    formData.set("exp_date", exp_date);
    formData.set("about", about);
    formData.set("benefits", benefits);
    formData.set("storage", storage);
    for (let pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }
    // images.forEach((image) => {
    //   formData.append("images", image);
    // });

    highlights.forEach((h) => {
      formData.append("highlights", h);
    });

    specs.forEach((s) => {
      formData.append("specifications", JSON.stringify(s));
    });

    dispatch(createProduct(formData));
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    if (success) {
      enqueueSnackbar("Product Created", { variant: "success" });
      dispatch({ type: NEW_PRODUCT_RESET });
      navigate("/admin/products");
    }
  }, [dispatch, error, success, navigate, enqueueSnackbar]);

  const handleUploadSuccess = (updatedData) => {
    setProductData(updatedData);
    setOriginalProductData(updatedData);
  };

  const handleCSVUpload = () => {
    setIsCSVUploadOpen(true);
    setEndPoint("products")
  }
  

  return (
    <>
      <MetaData title="Admin: New Product | ViviMart" />

      {loading && <BackdropLoader />}
      <form
        onSubmit={newProductSubmitHandler}
        encType="multipart/form-data"
        className="  bg-white rounded-lg shadow pb-4"
        id="mainform"
      >
        <div className="flex justify-end mb-4 border border-gray-200">
          <button
            className="flex justify-center my-2 mr-4
       items-center p-2 h-9 bg-primary-blue text-xs text-white"
       onClick={() => handleCSVUpload()}
          >
            Import CSV
          </button>
        </div>
        <div className="flex flex-col sm:flex-row">
          <div className="flex flex-col gap-3 ml-16 w-60 ">
            <TextField
              label="Product_id"
              variant="outlined"
              size="small"
              value={productId}
              onChange={(e) => setProductId(e.target.value)}
              disabled
            />

            <TextField
              label="Category"
              select
              value={categories}
              onChange={handleCategoryChange}
              variant="outlined"
              size="small"
            >
              <MenuItem value="">Select a Category</MenuItem>
              {productData && productData.map((category) => (
                <MenuItem key={category.Product_id} value={category.Product_id}>
                  {category.Categories}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Weight"
              variant="outlined"
              size="small"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
            />
            <TextField
              label="sell_price"
              variant="outlined"
              size="small"
              value={sell_price}
              onChange={(e) => setSell_price(e.target.value)}
            />
            <TextField
              label="kind"
              variant="outlined"
              size="small"
              value={kind}
              onChange={(e) => setKind(e.target.value)}
            />
            <TextField
              label="Benefits"
              variant="outlined"
              size="small"
              value={benefits}
              onChange={(e) => setBenefits(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-3 ml-16 w-60">
            <TextField
              label="Product_name"
              variant="outlined"
              size="small"
              value={product_name}
              onChange={(e) => setProduct_name(e.target.value)}
            />
            <TextField
              label="Sub_Categories"
              select
              value={sub_Categories}
              onChange={handleSubCategoryChange}
              variant="outlined"
              size="small"
            >
              <MenuItem value="">Select a sub_Category</MenuItem>
              {sub_categoryData.map((subcategory) => (
                <MenuItem
                  key={subcategory.Product_id}
                  value={subcategory.Product_id}
                >
                  {subcategory.Sub_Categories}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Size"
              variant="outlined"
              size="small"
              value={size}
              onChange={(e) => setSize(e.target.value)}
            />

            <TextField
              label="offer"
              variant="outlined"
              size="small"
              value={offer}
              onChange={(e) => setOffer(e.target.value)}
            />
            <TextField
              label="exp_date"
              variant="outlined"
              size="small"
              value={exp_date}
              onChange={(e) => setExp_date(e.target.value)}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Storage and Uses"
              variant="outlined"
              size="small"
              value={storage}
              onChange={(e) => setStorage(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-3 ml-16 mr-16 w-60">
            <TextField
              label="Brand_name"
              variant="outlined"
              size="small"
              value={brand_name}
              onChange={(e) => setBrand_name(e.target.value)}
            />

            <TextField
              label="subSub_Categories"
              select
              value={subSub_Categories}
              onChange={(e) => setSubSub_Categories(e.target.value)}
              variant="outlined"
              size="small"
            >
              <MenuItem value="">Select a sub_Category</MenuItem>
              {sub_sub_categoryData.map((subsubcategory) => (
                <MenuItem
                  key={subsubcategory.Product_id}
                  value={subsubcategory.Product_id}
                >
                  {subsubcategory.Sub_Sub_Categories}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="MRP"
              variant="outlined"
              size="small"
              value={mrp}
              onChange={(e) => setMrp(e.target.value)}
            />
            <TextField
              label="you_save"
              variant="outlined"
              size="small"
              value={you_save}
              onChange={(e) => setYou_save(e.target.value)}
            />

            <TextField
              label="About Product"
              variant="outlined"
              size="small"
              value={about}
              onChange={(e) => setAbout(e.target.value)}
            />

            <TextField
              label="SKU Id"
              variant="outlined"
              size="small"
              value={skuId}
              onChange={(e) => setSkuId(e.target.value)}
            />
          </div>
        </div>
        <div>
          <div className="flex flex-row gap-3 pt-4 px-16">
            <h2 className="relative" style={{ top: "0.5rem" }}>
              Main Image:
            </h2>
            <div
              className="relative "
              style={{
                marginLeft: window.innerWidth <= 600 ? "2.2rem" : "3rem",
              }}
            >
              <input
                className="absolute inset-0 z-10 w-full px-3 py-2 text-base font-normal font-medium leading-normal bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 cursor-pointer opacity-0"
                type="file"
                name="images"
                accept="image/*"
                multiple
                onChange={handleProductImageChange}
              />
              <span className="block w-full px-3 pr-36 py-2 text-base font-normal leading-normal bg-gray-200 border border-gray-300 rounded cursor-pointer">
                Choose file
              </span>
              <span className="absolute inset-y-0 right-0 pl-4 pr-3 py-2 text-base font-normal bg-white leading-normal border border-gray-300 rounded cursor-default">
                {prodouct_img0.length > 0 ? "File chosen" : "No file chosen"}
              </span>
              <img src={prodouct_img0} />
            </div>
          </div>
          <div className="flex flex-row gap-3 pt-4 px-16">
            <h2 className="relative" style={{ top: "0.5rem" }}>
              Upload Image1:
            </h2>
            <div className="relative " style={{ marginLeft: "1.6rem" }}>
              <input
                className="absolute inset-0 z-10 w-full px-3 py-2 text-base font-normal font-medium leading-normal bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 cursor-pointer opacity-0"
                type="file"
                name="images"
                accept="image/*"
                multiple
                onChange={handleProductImageChange}
              />
              <span className="block w-full px-3 pr-36 py-2 text-base font-normal leading-normal bg-gray-200 border border-gray-300 rounded cursor-pointer">
                Choose file
              </span>
              <span className="absolute inset-y-0 right-0 pl-4 pr-3 py-2 text-base font-normal bg-white leading-normal border border-gray-300 rounded cursor-default">
                {prodouct_img1.length > 0 ? "File chosen" : "No file chosen"}
              </span>
              <img src={prodouct_img1} />
            </div>
          </div>
          <div className="flex flex-row gap-3 pt-4 px-16">
            <h2 className="relative" style={{ top: "0.5rem" }}>
              Upload Image2:
            </h2>
            <div className="relative " style={{ marginLeft: "1.6rem" }}>
              <input
                className="absolute inset-0 z-10 w-full px-3 py-2 text-base font-normal font-medium leading-normal bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 cursor-pointer opacity-0"
                type="file"
                name="images"
                accept="image/*"
                multiple
                onChange={handleProductImageChange}
              />
              <span className="block w-full px-3 pr-36 py-2 text-base font-normal leading-normal bg-gray-200 border border-gray-300 rounded cursor-pointer">
                Choose file
              </span>
              <span className="absolute inset-y-0 right-0 pl-4 pr-3 py-2 text-base font-normal bg-white leading-normal border border-gray-300 rounded cursor-default">
                {prodouct_img3.length > 0 ? "File chosen" : "No file chosen"}
              </span>
              <img src={prodouct_img3} />
            </div>
          </div>
          <div className="flex flex-row gap-3 pt-4 px-16">
            <h2 className="relative" style={{ top: "0.5rem" }}>
              Upload Image3:
            </h2>
            <div className="relative " style={{ marginLeft: "1.6rem" }}>
              <input
                className="absolute inset-0 z-10 w-full px-3 py-2 text-base font-normal font-medium leading-normal bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 cursor-pointer opacity-0"
                type="file"
                name="images"
                accept="image/*"
                multiple
                onChange={handleProductImageChange}
              />
              <span className="block w-full px-3 pr-36 py-2 text-base font-normal leading-normal bg-gray-200 border border-gray-300 rounded cursor-pointer">
                Choose file
              </span>
              <span className="absolute inset-y-0 right-0 pl-4 pr-3 py-2 text-base font-normal bg-white leading-normal border border-gray-300 rounded cursor-default">
                {prodouct_img4.length > 0 ? "File chosen" : "No file chosen"}
              </span>
              <img src={prodouct_img4} />
            </div>
          </div>
          <div className="flex flex-row gap-3 pt-4 px-16">
            <h2 className="relative" style={{ top: "0.5rem" }}>
              Upload Image4:
            </h2>
            <div className="relative " style={{ marginLeft: "1.6rem" }}>
              <input
                className="absolute inset-0 z-10 w-full px-3 py-2 text-base font-normal font-medium leading-normal bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 cursor-pointer opacity-0"
                type="file"
                name="images"
                accept="image/*"
                multiple
                onChange={handleProductImageChange}
              />
              <span className="block w-full px-3 pr-36 py-2 text-base font-normal leading-normal bg-gray-200 border border-gray-300 rounded cursor-pointer">
                Choose file
              </span>
              <span className="absolute inset-y-0 right-0 pl-4 pr-3 py-2 text-base font-normal bg-white leading-normal border border-gray-300 rounded cursor-default">
                {prodouct_img5.length > 0 ? "File chosen" : "No file chosen"}
              </span>
              <img src={prodouct_img5} />
            </div>
          </div>
        </div>
        <div className="pt-4 pl-10 flex items-center justify-center ">
          <input
            form="mainform"
            type="submit"
            className="w-24	bg-primary-orange uppercase p-1 text-white font-medium rounded shadow hover:shadow-lg cursor-pointer"
            value="Submit"
          />
        </div>
      </form>
      {isCSVUploadOpen && <CsvUploadPopup isClose={setIsCSVUploadOpen} 
      endPoint={endPoint}
      onUploadSuccess={handleUploadSuccess} />}
    </>
  );
};

export default NewProduct;
