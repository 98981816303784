import { CLEAR_ERRORS, GET_LOCATION_FAILURE, GET_LOCATION_REQUEST, GET_LOCATION_SUCCESS, GET_STORE_ID_FAILURE, GET_STORE_ID_REQUEST, GET_STORE_ID_SUCCESS } from "../constants/locationConstants"
import axiosInstance from "../utils/api"

export const getLocationByPostalCode = () => async (dispatch) => {
    try {
        const postalCode = localStorage.getItem('SelectedPostalCode');
        if (!postalCode) throw new Error("No postal code found in localStorage");

        dispatch({ type: GET_LOCATION_REQUEST });

        const { data } = await axiosInstance.get(`locations/by-postal-code/${postalCode}`);

        dispatch({ type: GET_LOCATION_SUCCESS, payload: data });
         // Store the retrieved location data in localStorage
         localStorage.setItem('LocationData', JSON.stringify(data));

        console.log("location data:", data)

    } catch (error) {
        dispatch({
            type: GET_LOCATION_FAILURE,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

export const getStoreIdByPostalCode = () => async (dispatch) => {
    try {
        const postalCode = localStorage.getItem('SelectedPostalCode');
        if (!postalCode) throw new Error("No postal code found in localStorage");

        dispatch({ type: GET_STORE_ID_REQUEST });

        const { data } = await axiosInstance.get(`locations/store-id/by-postal-code/${postalCode}`);

        if (data) {
            dispatch({ type: GET_STORE_ID_SUCCESS, payload: data });
            // Store the retrieved store ID in localStorage
            localStorage.setItem('StoreId', JSON.stringify(data));
        } else {
            // Reset store ID if no store is found for the postal code
            localStorage.removeItem('StoreId');
            console.log("No store found for the given postal code");
        }
    } catch (error) {
        // Reset store ID on failure to fetch store by postal code
        localStorage.removeItem('StoreId');
        
        dispatch({
            type: GET_STORE_ID_FAILURE,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

export const clearError = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
  };