import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { newOrder } from "../../actions/orderAction";
import logo from "../../assets/images/logo1.png";
import Logomb from "../../assets/images/Logomb.png";
import AccordionPanel from "./AccordionPanel";
import axiosInstance from "../../utils/api";
import { emptyCart } from "../../actions/cartAction";
import { getLocationByPostalCode } from "../../actions/locationAction";

const Checkout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const addressDetails =
    JSON.parse(localStorage.getItem("addressDetails")) || {};
  const stdDelivery = location.state?.stdDelivery || [];
  const [paymentMethod, setPaymentMethod] = useState("");

  const { storeLocation } = useSelector((state) => state.storeLocation);

  useEffect(() => {
    dispatch(getLocationByPostalCode())
  }, [dispatch])

  const locationId = storeLocation ? storeLocation.id : 14;

  console.log("Selected store location id:", locationId)

  const handlePayment = async () => {
    if (!paymentMethod) {
      alert("Please select a payment method.");
      return;
    }

    const cartItems = stdDelivery.map((product) => ({
      product_id: product.Product_id,
      quantity: product.quantity,
      sell_price: product.sell_price,
      weight: product.Weight,
    }));

    const payload = {
      location: locationId, // Replace with your actual location ID logic
      cartItems,
      address: `${addressDetails.name}, ${addressDetails.house}, ${addressDetails.floor}, ${addressDetails.area}`,
      phone_number: localStorage.getItem("userPhoneNumber"),
      delivery_notes: "Leave at the front door.",
      payment_method: paymentMethod,
    };

    console.log("Order Payload:", payload);

    try {
      if (paymentMethod === "cod") {
        // Dispatch action to create the order
        await dispatch(newOrder(payload));

        // Clear the cart from local storage
        localStorage.removeItem("cartItems");
        localStorage.removeItem("totalQuantity");

        // Clear the cart from Redux state
        dispatch(emptyCart());

        alert("Order placed successfully with Cash on Delivery!");
      } else {
        // Create the order and get Razorpay data
        const orderData = await dispatch(newOrder(payload));

        // Open Razorpay for payment
        openRazorpay(orderData.razorpay_order_id, payload);

        // Optional: Clear the cart immediately if payment is successful
        // (you might want to clear it after successful payment confirmation)
      }
    } catch (error) {
      console.error("Error creating order:", error);
      alert("Payment failed. Please try again.");
    }
  };

  const openRazorpay = (orderId, payload) => {
    const options = {
      key: "rzp_test_Cd1cVSHpocrBwT", // Replace with your Razorpay key ID
      amount:
        payload.cartItems.reduce(
          (acc, item) => acc + item.sell_price * item.quantity,
          0
        ) * 100, // Convert to paise
      currency: "INR",
      name: "Vivimart",
      description: "Payment for order",
      order_id: orderId,
      handler: async (response) => {
        try {
          await axiosInstance.post("/orders/verify-payment", {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          });

          alert("Payment successful! Order placed.");
        } catch (error) {
          console.error("Payment verification failed:", error);
          alert("Payment verification failed. Please try again.");
        }
      },
      prefill: {
        name: addressDetails.name,
        email: "customer@example.com", // Replace with customer email
        contact: localStorage.getItem("userPhoneNumber"),
      },
      notes: {
        address: payload.address,
      },
      theme: {
        color: "#F37254",
      },
    };

    if (window.Razorpay) {
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } else {
      console.error("Razorpay script not loaded");
    }
  };

  return (
    <div className="w-screen h-screen bg-white">
      <div className="w-full m-auto flex justify-between items-center relative">
        <div className="bg-primary-blue flex items-center flex-1 w-full">
          <div className="flex col-span-1 pb-1 pt-2">
            <Link className="h-16 mr-1 sm:mr-4 hidden lg:block pr-14" to="/">
              <img
                draggable="false"
                className="h-full w-full object-contain"
                src={logo}
                alt="Vivimart Logo"
              />
            </Link>
          </div>
          <div className="lg:hidden flex col-span-1 pb-1 pt-2">
            <div className="flex col-span-1 relative bottom-[0.2rem]">
              <Link className="h-16 mr-1 sm:mr-4" to="/">
                <img
                  draggable="false"
                  className="h-full w-full object-contain"
                  src={Logomb}
                  alt="Vivimart Logo"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Container with vertical layout */}
      <div className="flex flex-col items-center bg-white px-4 py-4">
        {/* Delivery Address Section */}
        <div className="w-full lg:w-3/5 mb-4 p-4 border border-gray-300">
          <span className="font-semibold font-sans text-gray-600">
            Delivery Address
          </span>
          <div className="mt-1.5 text-sm text-[#999999] overflow-wrap-break">
            {Object.keys(addressDetails).length !== 0 && (
              <span>
                {addressDetails.name}, {addressDetails.house},{" "}
                {addressDetails.floor}, {addressDetails.area},{" "}
                {addressDetails.landmark}
              </span>
            )}
          </div>
        </div>

        {/* Cart Items Section */}
        <div className="w-full lg:w-3/5 mb-4 border border-gray-300 h-auto max-h-80">
          <div className="bg-[#fbfbfb] sticky top-0 z-10">
            <div className="text-[#666666] text-[14px] p-4 border-b border-gray-300">
              <span className="font-medium">My Cart</span>
              <span className="float-right">Items</span>
            </div>
          </div>

          <div className="h-[200px] overflow-y-auto">
            {stdDelivery.map((product, index) => (
              <div
                key={index}
                className="bg-white border-t border-[#eee] flex p-4 items-center"
              >
                <div className="w-[84px] h-[57px]">
                  <img
                    className="w-full h-full object-cover"
                    src={product.Prodouct_img_0}
                    alt={product.Product_name}
                  />
                </div>
                <div className="ml-4 flex-1">
                  <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                    {product.Product_name}
                  </div>
                  <div className="text-[#999999]">{product.Weight} kg</div>
                  <span className="block">
                    ₹{" "}
                    {product.quantity > 0
                      ? product.sell_price * product.quantity
                      : product.sell_price}
                  </span>
                </div>
                <div>{product.quantity} item</div>
              </div>
            ))}
          </div>

          <div className="bg-white p-4 flex justify-between border-t border-[#eee] sticky bottom-0 z-10">
            <span className="text-[14px]">Total</span>
            <span className="font-semibold text-[16px]">
              ₹{" "}
              {stdDelivery.reduce(
                (acc, item) => acc + item.sell_price * item.quantity,
                0
              ).toFixed(2)}

            </span>
          </div>
        </div>

        {/* Payment Method Section */}
        <div className="w-full lg:w-3/5 mb-4">
          <div className="font-sans text-[#1c1c1c] text-[24px] font-semibold leading-[32px] mb-4">
            Select Payment Method
          </div>
          <AccordionPanel setSelectedPaymentMethod={setPaymentMethod} />
        </div>

        {/* Proceed to Pay Button */}
        <div className="w-full lg:w-3/5 py-4 flex justify-center">
          <button
            onClick={handlePayment}
            className="bg-primary-blue text-white text-lg py-4 px-4 w-64 rounded"
          >
            {paymentMethod === "cod" ? "Place Order" : "Pay Now"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
