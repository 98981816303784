import MetaData from "../MetaData";

const Terms = () => {
  return (
    <div className="w-full mt-20 px-4 sm:px-8 md:px-16 lg:px-32 xl:px-64">
      <MetaData title="About us | Vivimart" />
      <main className="bg-white p-6 shadow-lg rounded-lg">
        <h1 className="text-3xl font-bold mb-12">
          TERMS AND CONDITIONS FOR USING VIVIMART
        </h1>

        <section className="mb-8">
          <p className="mb-4 text-justify">
            Welcome to Vivimart! Vivimart is an online platform available at and
            its corresponding mobile application, collectively referred to as
            "ViviMart." This platform is managed and operated by GVS FOOD AND
            RETAIL PRIVATE LIMITED, hereafter referred to as "GVS," "we," "us,"
            or "our." These terms and conditions govern the use of ViviMart and
            the services it offers and are subject to periodic updates.
            Additionally, specific terms and conditions applicable to certain
            services, referred to as "Service Terms," may also apply. In case of
            any inconsistency between these Terms of Use and the Service Terms,
            the Service Terms will prevail for the respective service.
          </p>
          <p className="mb-4 text-justify">
            Please note that these Terms of Use are considered an electronic
            record according to applicable law, and they do not require physical
            or digital signatures. Before using or accessing ViviMart or
            availing any service, please carefully read and understand these
            Terms of Use. By using ViviMart or its services, you indicate your
            agreement to abide by these terms.
          </p>

          <h2 className="text-2xl font-bold mb-6">
            1. Use of ViviMart
          </h2>
          <p className="mb-4 text-justify">
            ViviMart is an online platform that allows you to purchase products
            listed on ViviMart at the indicated prices from serviceable
            locations. It is important to understand that, unless explicitly
            stated otherwise, GVS acts as a facilitator and not as the seller of
            the products on ViviMart. Consequently, any purchase you make on the
            website constitutes a bipartite contract solely between you and the
            sellers on ViviMart.
          </p>

          <h2 className="text-2xl font-bold mb-6">
            2. Eligibility
          </h2>
          <p className="mb-4 text-justify">
            To use and access ViviMart, you must be legally capable of entering
            into binding contracts according to the Indian Contract Act, 1872.
            This means you must be at least 18 years old, of sound mind, and not
            disqualified from entering into contracts by any law. Minors,
            individuals under the age of 18, may use ViviMart only with the
            involvement of a parent or guardian.
          </p>

          <h2 className="text-2xl font-bold mb-6">
            3. Registration and Operation of Your Account
          </h2>
          <p className="mb-4 text-justify">
            3.1. To use ViviMart and its services, you need to register on the
            platform. Registration is a one-time process and is free of cost.
            You can register in one of two ways:
          </p>
          <p className="mb-4 text-justify">
            3.1.1. By creating a ViviMart account: You can register by providing
            relevant information such as your name, gender, and other necessary
            details. Your login ID and password will be generated based on the
            information you provide.
          </p>
          <p className="mb-4 text-justify">
            3.1.2. By using a third-party account: You may use login credentials
            from third-party accounts, as identified by GVS (e.g., Google or
            Facebook). If you choose this option, specific terms and conditions
            applicable to such third-party accounts will also apply.
          </p>
          <p className="mb-4 text-justify">
            Your registration details and ViviMart login information are
            collectively referred to as "Account Information."
          </p>
          <p className="mb-4 text-justify">
            3.2. You are solely responsible for operating the account created by
            you. It is your duty to maintain the confidentiality of your Account
            Information and restrict access to your account on your computer,
            mobile, or similar devices to prevent unauthorized access. You must
            keep your Account Information current and accurate at all times. You
            can access and update most of your Account Information in the 'Your
            Account' section of the website.
          </p>
          <p className="mb-4 text-justify">
            3.3. You must: (a) Immediately notify GVS of any unauthorized use of
            your account or any   breach. (b) Ensure you log out of your
            account at the end of each session. Failure to comply with these
            Terms of Use may result in GVS refusing access to ViviMart,
            restricting new orders, canceling pending orders, deactivating or
            suspending your account without prior notice for reasons including
            breach of these Terms and Conditions, violation of applicable laws,
            involvement in inappropriate or unlawful conduct, placing fake
            orders, frequent cancellations, returns, or abuse of policies, or
            causing harm to the brand image of ViviMart on public forums (e.g.,
            social media, print, electronic media), or harassment of ViviMart
            and its employees or agents.
          </p>

          <h2 className="text-2xl font-bold mb-6">
            4. Communication and Unsubscription
          </h2>
          <p className="mb-4 text-justify">
            4.1. After registering on ViviMart, you may receive SMS or email
            notifications from GVS regarding your registration and transactions
            on ViviMart.
          </p>
          <p className="mb-4 text-justify">
            4.2. You may also receive notifications about marketing and
            promotional activities available on ViviMart. By registering on
            ViviMart and/or verifying your contact number with us, you
            explicitly consent to receive marketing and promotional
            communications (via call, SMS, email, or other digital and
            electronic means) from us and/or our authorized representatives,
            even if your contact number is registered under the DND/NCPR list
            under the Telecom Commercial Communications Customer Preference
            Regulations, 2018.
          </p>
          <p className="mb-4 text-justify">
            4.3. GVS may also send notifications and reminders related to the
            Services on ViviMart, although it does not guarantee the delivery of
            such notifications/reminders.
          </p>
          <p className="mb-4 text-justify">
            4.4. You have the option to unsubscribe/opt-out from receiving
            marketing/promotional communications, newsletters, and other
            notifications from GVS at any time by following the instructions
            provided in such communications.
          </p>

          <h2 className="text-2xl font-bold mb-6">
            5. License and Access to Platform
          </h2>
          <p className="mb-4 text-justify">
            5.1. Your use of ViviMart, the Services, and access to ViviMart
            Content (defined below) is subject to a limited, revocable, and
            non-exclusive license granted to you when you register on ViviMart.
            You may use ViviMart solely for the purpose of identifying products,
            making purchases, and processing returns and refunds, following the
            Return and Refund Policy, for personal use only and not for business
            purposes.
          </p>
          <p className="mb-4 text-justify">
            5.2. This license does not include the right to: (a) Resale of
            products or commercial use of ViviMart or ViviMart Content. (b)
            Collect and use product listings, descriptions, or prices. (c) Use
            ViviMart, the Services, and/or ViviMart Content for any purpose
            other than as specified in these Terms of Use. (d) Download or copy
            Account Information. (e) Use data mining, robots, or similar data
            gathering and extraction tools to extract any part of ViviMart. (f)
            Create and/or publish your own database that features parts of
            ViviMart.
          </p>
          <p className="mb-4 text-justify">
            5.3. You grant GVS a royalty-free, perpetual, irrevocable,
            non-exclusive right and license to adopt, publish, reproduce,
            disseminate, transmit, distribute, copy, use, create derivative
            works from, display worldwide, or act on any material posted by you
            on ViviMart without additional approval or consideration in any
            form, media, or technology, for the full term of any rights that may
            exist in such content. You agree to take further actions necessary
            to perfect the rights granted to GVS, including executing deeds and
            documents upon request. Please note that GVS reserves the right to
            refuse your access to ViviMart, terminate/deactivate your account,
            remove or edit content on ViviMart at its discretion.
          </p>

          <h2 className="text-2xl font-bold mb-6">
            6. Personal Information Privacy
          </h2>
          <p className="mb-4 text-justify">
            6.1. During your registration and use of ViviMart or the Services,
            GVS may collect, store, or you may provide personal identifiable and
            sensitive information, including your name, phone number, email
            address, address, postal code, occupation, login details, and more.
          </p>
          <p className="mb-4 text-justify">
            6.2. GVS respects the sensitivity of your information and has
            formulated a Privacy Policy, which is considered part of these Terms
            of Use, outlining how your information is collected, stored,
            processed, used, and disclosed. If you object to the terms of our
            Privacy Policy, please refrain from using or accessing ViviMart or
            availing the Services. For more information, please read the entire
            Privacy Policy.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-xl font-bold mb-6">
            7. Amendment of Terms of Use
          </h2>
          <p className="mb-4 text-justify">
           7.1. GVS reserves the right to revise these Terms of Use from time to
            time to:
          </p>
          <ul className="list-disc ml-8 mb-4 text-justify">
            <li>
              Reflect updates, changes, or additions to ViviMart and the
              Services.
            </li>
            <li>
              Reflect changes in industry practices, legal requirements, or
              other reasons.
            </li>
          </ul>
          <p className="mb-4 text-justify">
            7.2. Changes will be effective immediately upon posting on ViviMart, and
            your continued use of ViviMart after such changes indicates your
            acceptance of these changes. You are expected to review these Terms
            of Use regularly.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-xl font-bold mb-6">
            8. Account Deactivation
          </h2>
          <p className="mb-4 text-justify">
            8.1. We reserve the right to refuse service, close accounts, remove, or
            edit content or cancel orders at our discretion. The circumstances
            leading to account deactivation include but are not limited to the
            following:
          </p>
          <ul className="list-disc ml-8 mb-4 text-justify">
            <li>Breach or suspected breach of these Terms of Use.</li>
            <li>Requests by law enforcement or other government agencies.</li>
            <li>
              Violation of a third party's rights, including infringement of
              intellectual property rights.
            </li>
            <li>Repeated fraudulent and/or deceitful activities.</li>
            <li>
              Misrepresentation of personal information or the sale of
              prohibited items.
            </li>
            <li>
              If your registration information or other account-related details
              are not accurate, current, and complete.
            </li>
            <li>Repeated failure to deliver payment for services.</li>
          </ul>
          <p className="mb-4 text-justify">
            8.2. If your account is deactivated, you may be denied access to
            ViviMart, your account, content, or data. However, you may not
            necessarily be entitled to a refund of any monies in your ViviMart
            account if your account is terminated or suspended.
          </p>
          <p className="mb-4 text-justify">
            8.3. In case of account deactivation, you may contact our Customer
            Support for inquiries and resolution of any disputes.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-bold mb-6">
            9. Compliance with Local Laws
          </h2>
          <p className="mb-4 text-justify">
            9.1. ViviMart is hosted in India, and it is directed to users residing in
            India. We make no representation that ViviMart is compliant with
            laws of any other location. Users who access ViviMart from locations
            outside India do so at their own risk and are responsible for
            compliance with local laws.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-xl font-bold mb-6">DISPUTES</h2>
          <p className="mb-4 text-justify">
            Any dispute or claim related to these Terms of Use, ViviMart, or any
            transaction conducted on ViviMart will be subject to the exclusive
            jurisdiction of the courts in Mumbai, Maharashtra, India, and will
            be governed by and construed in accordance with the laws of India.
            Disputes between you and GVS will be resolved in accordance with
            Indian laws as applicable.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-bold mb-6">DISCLAIMER</h2>
          <p className="mb-4 text-justify">
            GVS has no liability for any breach or delay in service due to a
            Force Majeure Event. A Force Majeure Event includes any act, event,
            or occurrence beyond GVS's reasonable control and may include
            industrial action, civil commotion, war, terrorist act, fire,
            explosion, storm, flood, earthquake, or other natural disaster, road
            traffic accidents, and any other natural or man-made event that
            prevents GVS from fulfilling its obligations.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-bold mb-6">
            REPORTING OF VIOLATIONS
          </h2>
          <p className="mb-4 text-justify">
            Please report any violations of these Terms of Use to our Customer
            Support.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-bold mb-6">
            INDEMNITY AND RELEASE
          </h2>
          <p className="mb-4 text-justify justify">
            You shall indemnify and hold GVS and its affiliates, officers,
            employees, and representatives harmless from any claim or demand, or
            actions, including reasonable attorney's fees, made by any third
            party or penalty imposed due to or arising out of your breach of
            these Terms of Use or any document incorporated by reference, or
            your violation of any law, rules, regulations, or the rights of a
            third party.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-bold mb-6">CONTACT US</h2>
          <p className="mb-4 text-justify">
            For any inquiries or grievances regarding ViviMart, you can contact
            our Customer Support.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-bold mb-6">
            ENTIRE AGREEMENT
          </h2>
          <p className="mb-4 text-justify">
            These Terms of Use, as well as any other policies or documents
            incorporated by reference, constitute the entire agreement between
            you and GVS concerning the use of ViviMart.
          </p>
          <p>
            Thank you for choosing ViviMart and using our services. We hope you
            enjoy your shopping experience with us.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-xl font-bold mb-6">Welcome to ViviMart!</h2>
          <p className="mb-4 text-justify">
            The Terms and Conditions of Sale outlined herein ("Terms of Sale")
            establish an agreement between the entity listed as the seller
            ("we," "us," or "our") on the website and its corresponding mobile
            application, collectively referred to as "ViviMart," and you ("you"
            or "your"). These terms govern your purchase of products
            ("Products") from us through ViviMart. By placing an order for any
            Product with us or making a purchase, you explicitly agree to abide
            by these Terms of Sale.
          </p>

          <p className="mb-4 text-justify">
            These Terms of Sale are supplementary to various agreements, Service
            Terms, Privacy Policy, Terms of Use, and all other policies of
            ViviMart. It's important to note that these Terms of Sale constitute
            an electronic record under applicable law. This electronic record is
            generated by a computer system and does not require physical or
            digital signatures.
          </p>

          <h3 className="text-lg font-bold mb-6">1. Our Sales Agreement</h3>
          <p className="mb-4 text-justify">
            1.1. When we list and display a Product on ViviMart, it serves as an
            invitation for you to make an offer to purchase that Product.
            Similarly, when you place an order on ViviMart, you are making an
            offer to buy the Product(s) from us.
          </p>
          <p className="mb-4 text-justify">
            1.2. After you've placed an order for a Product, you will receive an
            email confirming the receipt of your order, including order details
            (the "Order Confirmation"). Please note that the Order Confirmation
            is an acknowledgment of your order and does not signify our
            acceptance of your offer.
          </p>
          <p className="mb-4 text-justify">
            1.3. We only accept your offer and finalize the sales contract for a
            Product when we ship or dispatch the Product to you, and you receive
            an email confirming the shipment (the "Shipment Confirmation"). We
            reserve the right to refuse or cancel any order at our sole
            discretion before sending the Shipment Confirmation.
          </p>
          <p className="mb-4 text-justify">
            1.4. Some Products in your order may have an unknown exact quantity
            at the time of order placement (e.g., fruits, vegetables). These
            Products will be invoiced based on their weight at the time of
            packaging. Upon dispatch, the Shipment Confirmation will reflect any
            marginal variance in quantity compared to your original order, along
            with the relevant adjustment in the invoice amount.
          </p>
          <p className="mb-4 text-justify">
            1.5. If your order is dispatched in multiple packages, you may
            receive separate Shipment Confirmations for each package. Each
            Shipment Confirmation and corresponding dispatch constitutes a
            separate sales contract between you and us for the Product(s)
            specified in that confirmation.
          </p>
          <p className="mb-4 text-justify">
            1.6. Your contract is with us, and you confirm that the Product(s)
            you ordered are for your personal use and not for resale or business
            purposes. We sell Products in quantities typical for an average
            household, both within a single order and when placing several
            orders for the same Product.
          </p>
          <p className="mb-4 text-justify">
            1.7. You have the option to cancel your Product order at no cost at
            any time before we send the Shipment Confirmation.
          </p>
          <p className="mb-4 text-justify">
            1.8. All orders on ViviMart are subject to Product availability, our
            acceptance of your offer as mentioned above, and your adherence to
            these Terms of Sale.
          </p>
          <p className="mb-4 text-justify">
            1.9. It's important to acknowledge that the title and risk of all
            ordered Products pass to you upon their delivery to you.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-lg font-bold mb-6">2. Return and Refund</h2>
          <p className="mb-4 text-justify">
            2.1. Please refer to ViviMart's Returns Policy and Refund Policy,
            which apply to Products sold by us.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-lg font-bold mb-6">3. Product Availability</h2>
          <p className="mb-4 text-justify">
            3.1. We provide availability information for Products on the
            relevant ViviMart webpage. Beyond what is stated on the webpage or
            elsewhere on ViviMart, we cannot provide specific availability
            details. Dispatch estimates are non-binding, and you will be
            notified by email if any ordered Products are unavailable or out of
            stock during order processing.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-xl font-bold mb-6">4. Product Pricing</h2>
          <p className="mb-4 text-justify">
            4.1. All prices are listed in Indian Rupees and are inclusive of all
            applicable taxes.
          </p>
          <p className="mb-4 text-justify">
            4.2. The prices in your ViviMart shopping cart will reflect the most
            recent price displayed on the Product's information webpage. Please
            note that this price may differ from the initial price when you
            added the Product to your cart. Placing a Product in your shopping
            cart does not guarantee the displayed price. Additionally, a
            Product's price may change between the time you add it to your cart
            and the time you place the order.
          </p>
          <p className="mb-4 text-justify">
            4.3. Unless specified in paragraph 1.4 above, the prices at the time
            of ordering will be the charges on the delivery date. While most
            Product prices remain constant, some, such as fresh food and
            vegetables, may fluctuate daily. If prices are different on the
            delivery date, no additional charges will be collected or refunded,
            as applicable, except as outlined in paragraph 1.4.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-xl font-bold mb-6">5. Licenses</h2>
          <p className="mb-4 text-justify">
            5.2. Some Products may require licenses or permissions under
            applicable laws. You are responsible for obtaining and complying
            with these licenses and permissions.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-xl font-bold mb-6">6. Taxes</h2>
          <p className="mb-4 text-justify">
            6.1. You are responsible for all fees, costs, and charges associated
            with your Product purchases and agree to bear any applicable taxes.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-xl font-bold mb-6">7. Eligibility</h2>
          <p className="mb-4 text-justify">
            7.1. Only individuals who can enter into legally binding contracts
            as per the Indian Contract Act, 1872 (i.e., those who are 18 years
            of age or older, of sound mind, and not disqualified by any law from
            entering into contracts) can use ViviMart and place orders. Minors
            under the age of 18 may purchase Products on ViviMart with the
            involvement of a parent or guardian.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-xl font-bold mb-6">8. Limitation of Liability</h2>
          <p className="mb-4 text-justify">
            8.1. Our aggregate liability, if any, is limited to the refund of
            the amount charged for purchases made under a specific order. We are
            not liable for business losses, including profits, revenue,
            contracts, anticipated savings, data, goodwill, or indirect or
            consequential losses that were not reasonably foreseeable when the
            sales contract was formed.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-xl font-bold mb-6">9. Amendments</h2>
          <p className="mb-4 text-justify">
            9.1. We reserve the right to make changes to our policies and these
            Terms of Sale at any time. You will be bound by the policies and
            Terms of Sale in effect when you place an order, unless changes are
            required by law or government authority (in which case they apply to
            prior orders). If any condition is deemed invalid, it will not
            affect the validity of the remaining conditions.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-xl font-bold mb-6">10. Force Majeure</h2>
          <p className="mb-4 text-justify">
            10.1. We are not responsible for any delay or failure to fulfill our
            obligations due to causes beyond our reasonable control.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-xl font-bold mb-6">11. Waiver</h2>
          <p className="mb-4 text-justify">
            11.1. No provision in these Terms of Sale will be considered waived,
            and no breach excused, unless in writing and signed by us. Our
            consent to or waiver of your breach, whether expressed or implied,
            does not constitute consent, waiver, or excuse for any other breach.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-xl font-bold mb-6">
            12. Governing Law and Dispute Resolution
          </h2>
          <p className="mb-4 text-justify">
            12.1. These Terms of Sale, all transactions, and our relationship
            with you are governed by Indian law, with exclusive jurisdiction
            given to the courts of Mumbai.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-xl font-bold mb-6">13. Miscellaneous</h2>
          <p className="mb-4 text-justify">
            {" "}
            13.1. In addition to these Terms of Sale, you must comply with the
            terms and conditions of third parties, such as bank offers, brand
            promotional offers, whose links may be included in the Service Terms
            or Terms of Use.
          </p>
          <p className="mb-4 text-justify">
            13.2. These Terms of Sale supersede all previous oral and written
            terms and conditions communicated by us for the purchase of
            Products.
          </p>
        </section>
      </main>
    </div>
  );
};
export default Terms;
