// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zoom-container {
    position: relative;
    overflow: hidden;
  }

  .zoom-image {
    transition: transform 0.3s ease; /* Adjust the duration as needed */
    transform-origin: center center; /* Ensures zoom effect is centered */
  }

  .zoom-container:hover .zoom-image {
    transform: scale(1.2); /* Adjust scale factor as needed */
    transition: transform 0.6s ease;
  }
  .truncate-3-lines {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ProductDetails/Product.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,+BAA+B,EAAE,kCAAkC;IACnE,+BAA+B,EAAE,oCAAoC;EACvE;;EAEA;IACE,qBAAqB,EAAE,kCAAkC;IACzD,+BAA+B;EACjC;EACA;IACE,oBAAoB;IACpB,qBAAqB;IACrB,4BAA4B;IAC5B,gBAAgB;IAChB,uBAAuB;EACzB","sourcesContent":[".zoom-container {\n    position: relative;\n    overflow: hidden;\n  }\n\n  .zoom-image {\n    transition: transform 0.3s ease; /* Adjust the duration as needed */\n    transform-origin: center center; /* Ensures zoom effect is centered */\n  }\n\n  .zoom-container:hover .zoom-image {\n    transform: scale(1.2); /* Adjust scale factor as needed */\n    transition: transform 0.6s ease;\n  }\n  .truncate-3-lines {\n    display: -webkit-box;\n    -webkit-line-clamp: 3;\n    -webkit-box-orient: vertical;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
