import { CLEAR_ERRORS, GET_LOCATION_FAILURE, GET_LOCATION_REQUEST, GET_LOCATION_SUCCESS, GET_STORE_ID_FAILURE, GET_STORE_ID_REQUEST, GET_STORE_ID_SUCCESS } from "../constants/locationConstants"

const initialState = {
    storeLocation: [],
    loading: false,
    error: null,
};

export const locationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LOCATION_REQUEST:
        case GET_STORE_ID_REQUEST:
            return { ...state, loading: true };
        case GET_LOCATION_SUCCESS:
        case GET_STORE_ID_SUCCESS:
            return { loading: false, storeLocation: action.payload };
        case GET_LOCATION_FAILURE:
        case GET_STORE_ID_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case CLEAR_ERRORS:
            return { ...state, error: null };
        default:
            return state;
    }
};