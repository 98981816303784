import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import HomeIcon from "@mui/icons-material/Home";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import BusinessIcon from "@mui/icons-material/Business";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import SearchIcon from "@mui/icons-material/Search";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import PinDropIcon from "@mui/icons-material/PinDrop";

const DeliveryEdit = ({ setIsOpen, setAddress, isEdit }) => {
  const [floor, setFloor] = useState("");
  const [area, setArea] = useState("");
  const [landmark, setLandmark] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectResident, setSelectResident] = useState("Home");
  const [house, setHouse] = useState("");
  const [addressList, setAddressList] = useState([]); // State to manage the list of addresses
  const [editingIndex, setEditingIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  useEffect(() => {
    const storedAddresses = localStorage.getItem("addressDetails");
    if (storedAddresses) {
      try {
        const addressesArray = JSON.parse(storedAddresses);
        if (Array.isArray(addressesArray)) {
          setAddressList(addressesArray);
        }
      } catch (error) {
        console.error("Error parsing stored addresses:", error);
      }
    }
  }, []);

  useEffect(() => {
    if (isEdit && editingIndex !== null) {
      const storedAddresses =
        JSON.parse(localStorage.getItem("addressDetails")) || [];
      if (storedAddresses[editingIndex]) {
        const address = storedAddresses[editingIndex];
        setHouse(address.house || "");
        setFloor(address.floor || "");
        setArea(address.area || "");
        setLandmark(address.landmark || "");
        setName(address.name || "");
        setPhoneNumber(address.phoneNumber || "");
        setSelectResident(address.selectResident || "Home");
      }
    } else {
      // Clear fields if adding a new address
      setHouse("");
      setFloor("");
      setArea("");
      setLandmark("");
      setName("");
      setPhoneNumber("");
      setSelectResident("Home");
    }
  }, [isEdit, editingIndex]);

  const addressDetails = {
    house,
    floor,
    area,
    landmark,
    name,
    phoneNumber,
    selectResident,
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSaveAddress = () => {
    // Basic validation
    if (!house || !area || !name) {
      alert("Please fill in the required fields.");
      return;
    }
  
    // Retrieve and parse the stored addresses or initialize as an empty array
    let storedAddresses;
    try {
      storedAddresses = JSON.parse(localStorage.getItem("addressDetails")) || [];
      
      // Ensure storedAddresses is an array
      if (!Array.isArray(storedAddresses)) {
        console.error("Stored addresses data is not an array. Resetting to empty array.");
        storedAddresses = [];
      }
    } catch (error) {
      console.error("Error parsing stored addresses:", error);
      storedAddresses = [];
    }
  
    // Update or add address
    if (isEdit && editingIndex >= 0) {
      storedAddresses[editingIndex] = addressDetails; // Update existing address
    } else {
      storedAddresses.push(addressDetails); // Add new address
    }
  
    // Save updated addresses back to local storage
    localStorage.setItem("addressDetails", JSON.stringify(storedAddresses));
    setIsOpen(false);
  };

  // Filter addresses based on search query
  const filteredAddresses = addressList.filter((address) =>
    address.house.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="lg:w-[55rem] h-screen w-screen lg:h-full bg-white rounded-xl flex flex-row">
      <div className="flex-1 relative ">
        <div className="absolute top-[7px] left-[49%] transform -translate-x-1/2 w-[calc(100%-24px)] h-[54px] z-[9999]">
          <div className="h-full rounded-[12px] bg-white border border-[rgb(207,207,207)] shadow-[2px_2px_12px_0_rgba(0,0,0,0.06)]">
            <div className="flex items-center h-full ">
              <div className="p-2">
                <SearchIcon className="text-green-800" />
              </div>
              <div className="text-sm font-sans w-[21rem]">
                <input
                  className="flex-grow flex-shrink w-full focus:outline-none"
                  type="text"
                  placeholder="Search Location"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <div className="cursor-pointer">
                <CloseIcon style={{ fontSize: "12px" }} />
              </div>
            </div>
          </div>
        </div>
        <div className="h-full rounded-lg relative">
          <div className="h-full w-full absolute top-0 left-0 bg-[#e5e3df]">
            <div style={{ width: "100%", height: "100%" }}>
              <iframe
                title="Map"
                width="100%"
                height="100%"
                frameBorder="0"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387109.8704379497!2d-74.25819326367957!3d40.70582552922397!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjjCsDU5JzU2LjgiTiA3NMKwMjknNTIuMCJX!5e0!3m2!1sen!2sus!4v1625955620241!5m2!1sen!2sus"
                allowFullScreen
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="absolute flex flex-col gap-3 p-3 pb-6 rounded-t-lg bg-white shadow-md z-[9999] bottom-0 left-0 right-0 border border-gray-100">
          <div className="absolute flex items-center p-[8px_10px] gap-2 rounded-lg border-[0.654px] border-[#318616] bg-white shadow-md text-[12px] font-medium text-[#0c831f] cursor-pointer z-[9999] bottom-[246px] h-[30px] box-border top-[-38px]">
            <span>
              <MyLocationIcon style={{ fontSize: "1rem" }} />
            </span>
            <span>Go to current location</span>
          </div>
          <div className="font-sans font-bold">Delivering your order to</div>
          <div className="flex items-center p-[10px_12px_10px_8px] gap-2 rounded-lg border-[0.5px] border-[#e8e8e8] bg-[#f6fcfc]">
            <span>
              <PinDropIcon />
            </span>
            <span className="text-sm font-sans">Location</span>
          </div>
        </div>
      </div>
      <div className="flex-1 relative flex items-center hidden lg:block">
        <div className="flex flex-col w-full h-full">
          <div className="p-[14px_12px] text-[rgb(28,28,28)] text-[17px] font-extrabold flex gap-[10px] items-center justify-between border-b border-[rgb(232,232,232)] sticky bg-white top-0 z-10">
            <span>Enter complete address</span>
            <span onClick={() => handleClose()} className="cursor-pointer">
              <CloseIcon />
            </span>
          </div>
          <div className="px-4 py-6 flex flex-col gap-3">
            <TextField
              value={house}
              onChange={(e) => setHouse(e.target.value)}
              label="House No. / Building"
              variant="outlined"
              fullWidth
              size="small"
            />
            <TextField
              value={floor}
              onChange={(e) => setFloor(e.target.value)}
              label="Floor / Wing"
              variant="outlined"
              fullWidth
              size="small"
            />
            <TextField
              value={area}
              onChange={(e) => setArea(e.target.value)}
              label="Area / Locality"
              variant="outlined"
              fullWidth
              size="small"
            />
            <TextField
              value={landmark}
              onChange={(e) => setLandmark(e.target.value)}
              label="Landmark"
              variant="outlined"
              fullWidth
              size="small"
            />
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              label="Recipient Name"
              variant="outlined"
              fullWidth
              size="small"
            />
            <TextField
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              label="Phone Number"
              variant="outlined"
              fullWidth
              size="small"
            />
            <div className="flex flex-col mt-3">
              <label className="text-sm font-semibold">Address Type</label>
              <div className="flex gap-2">
                <label>
                  <input
                    type="radio"
                    checked={selectResident === "Home"}
                    onChange={() => setSelectResident("Home")}
                  />
                  <span className="ml-2">Home</span>
                </label>
                <label>
                  <input
                    type="radio"
                    checked={selectResident === "Office"}
                    onChange={() => setSelectResident("Office")}
                  />
                  <span className="ml-2">Office</span>
                </label>
                <label>
                  <input
                    type="radio"
                    checked={selectResident === "Other"}
                    onChange={() => setSelectResident("Other")}
                  />
                  <span className="ml-2">Other</span>
                </label>
              </div>
            </div>
            <div className="flex justify-end mt-4 gap-3">
              <button
                className="px-4 py-2 bg-blue-600 text-white rounded-lg"
                onClick={handleSaveAddress}
              >
                Save Address
              </button>
              <button
                className="px-4 py-2 bg-gray-400 text-white rounded-lg"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryEdit;
