import React, { useState } from "react";

const AccordionPanel = ({ setSelectedPaymentMethod }) => {
  const [selectedMethod, setSelectedMethod] = useState("");

  const handleSelection = (value) => {
    setSelectedPaymentMethod(value);
    setSelectedMethod(value);
  };

  return (
    <div className="py-[1rem]">
      <div className="flex flex-col space-y-4">
        {/* Cash on Delivery Panel */}
        <div
          className={`p-4 border rounded-md cursor-pointer ${
            selectedMethod === "cod" ? "bg-green-500 border-green-500" : "bg-white border-gray-300"
          }`}
          onClick={() => handleSelection("cod")}
        >
          <span
            className={`text-lg ${
              selectedMethod === "cod" ? "text-white" : "text-black"
            }`}
          >
            Cash on Delivery
          </span>
        </div>

        {/* Online Payment Panel */}
        <div
          className={`p-4 border rounded-md cursor-pointer ${
            selectedMethod === "online" ? "bg-green-500 border-green-500" : "bg-white border-gray-300"
          }`}
          onClick={() => handleSelection("online")}
        >
          <span
            className={`text-lg ${
              selectedMethod === "online" ? "text-white" : "text-black"
            }`}
          >
            Online Payment
          </span>
        </div>
      </div>
    </div>
  );
};

export default AccordionPanel;
