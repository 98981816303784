import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const Locations = () => {
  const [searchData, setSearchData] = useState("");
  const [tableData, setTableData] = useState([]);
  const [storeId, setStoreId] = useState("");
  const [cityName, setCityName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [primaryLocation, setPrimaryLocation] = useState("");
  const [secondaryLocations, setSecondaryLocations] = useState([""]);
  const [editIndex, setEditIndex] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);

  const saveLocations = () => {
    const newData = {
      storeId,
      cityName,
      ownerName,
      primaryLocation,
      secondaryLocations,
    };

    if (editIndex !== null) {
      const updatedTableData = [...tableData];
      updatedTableData[editIndex] = newData;
      setTableData(updatedTableData);
      setEditIndex(null);
    } else {
      setTableData([...tableData, newData]);
    }

    // Clear the input fields after saving
    setStoreId("");
    setCityName("");
    setOwnerName("");
    setPrimaryLocation("");
    setSecondaryLocations([""]);
  };

  const addSecondaryLocationField = () => {
    setSecondaryLocations([...secondaryLocations, ""]);
  };

  const editLocation = (index) => {
    const data = tableData[index];
    setStoreId(data.storeId);
    setCityName(data.cityName);
    setOwnerName(data.ownerName);
    setPrimaryLocation(data.primaryLocation);
    setSecondaryLocations(data.secondaryLocations);
    setEditIndex(index);
  };

  const deleteLocation = (index) => {
    setDeleteIndex(index);
  };

  const handleDeleteConfirm = () => {
    if (deleteIndex !== null) {
      setTableData(tableData.filter((_, i) => i !== deleteIndex));
      setDeleteIndex(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteIndex(null);
  };

  const handleSecondaryLocationChange = (value, index) => {
    const updatedLocations = [...secondaryLocations];
    updatedLocations[index] = value;
    setSecondaryLocations(updatedLocations);
  };

  return (
    <div className="flex flex-wrap gap-8">
      <div className="shadow-xl sm:w-72 shadow-gray-250 border border-gray-300 p-2 rounded-md bg-white max-h-[30rem] overflow-y-auto w-[23rem] flex-none">
        <div className="border-b border-gray-300 p-2 font-sans">
          <h1>City Information</h1>
        </div>
        <div className="p-2 font-sans">
          <h1>Store Id</h1>
          <input
            id="storeId"
            placeholder="Store Id"
            className="block w-full px-3 py-2 text-base font-normal leading-normal text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out"
            type="text"
            value={storeId}
            onChange={(e) => setStoreId(e.target.value)}
          />
          <h1>City Name</h1>
          <input
            id="cityName"
            placeholder="City Name"
            className="block w-full px-3 py-2 text-base font-normal leading-normal text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out"
            type="text"
            value={cityName}
            onChange={(e) => setCityName(e.target.value)}
          />
          <h1>Owner Name</h1>
          <input
            id="ownerName"
            placeholder="Owner Name"
            className="block w-full px-3 py-2 text-base font-normal leading-normal text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out"
            type="text"
            value={ownerName}
            onChange={(e) => setOwnerName(e.target.value)}
          />
          <h1>Primary Location</h1>
          <input
            id="primaryLocation"
            placeholder="Primary Location"
            className="block w-full px-3 py-2 text-base font-normal leading-normal text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out"
            type="text"
            value={primaryLocation}
            onChange={(e) => setPrimaryLocation(e.target.value)}
          />
          <h1>Secondary Location</h1>
          {secondaryLocations.map((location, index) => (
            <div key={index} className="mt-2 flex items-center gap-2">
              <input
                placeholder={`Secondary Location ${index + 1}`}
                className="block w-full px-3 py-2 text-base font-normal leading-normal text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out"
                type="text"
                value={location}
                onChange={(e) =>
                  handleSecondaryLocationChange(e.target.value, index)
                }
              />
              {index === secondaryLocations.length - 1 && (
                <button
                  className="flex justify-center items-center py-2 px-4 h-9 bg-primary-blue text-base font-normal rounded-md text-white"
                  onClick={addSecondaryLocationField}
                >
                  <ControlPointIcon />
                </button>
              )}
            </div>
          ))}
        </div>
        <div className="pl-2">
          <button
            className="flex justify-center my-2 mr-4 items-center py-2 px-4 h-9 bg-primary-blue text-base font-normal rounded-md text-white"
            onClick={saveLocations}
          >
            Save
          </button>
        </div>
      </div>
      <div className="flex-1 min-w-0 border border-gray-300 shadow-xl rounded-md shadow-gray-250 h-[30rem] bg-white flex flex-col">
        <div className="border-b border-gray-300 p-2 font-sans flex justify-between">
          <h1>All Cities</h1>
          <div className="flex gap-2">
            <button className="flex justify-center items-center py-2 px-4 h-9 bg-primary-blue text-xs text-white">
              Export as CSV
            </button>
            <button className="flex justify-center items-center py-2 px-4 h-9 bg-primary-blue text-xs text-white">
              Upload CSV
            </button>
          </div>
        </div>
        <div className="p-4">
          <div className="flex items-center border border-gray-300 rounded mb-4">
            <input
              placeholder="Search data"
              className="block w-full px-3 py-2 text-base font-normal leading-normal text-gray-700 bg-white border-none rounded-l transition duration-150 ease-in-out"
              value={searchData}
              type="text"
              onChange={(e) => setSearchData(e.target.value)}
            />
            <button type="submit" className="text-primary-blue px-4 py-2">
              <SearchIcon />
            </button>
          </div>
          <div className="w-full max-h-[30rem] overflow-y-auto">
            <table className="w-full border-collapse font-sans">
              <thead>
                <tr className="border-b">
                  <th className="px-2 py-2 w-1/12 text-gray-600 text-left">
                    Store Id
                  </th>
                  <th className="px-4 py-2 text-gray-600 text-left">
                    City Name
                  </th>
                  <th className="px-8 py-2 text-gray-600 text-left">
                    Owner Name
                  </th>
                  <th className="px-4 py-2 text-gray-600 text-left">
                    Primary Location
                  </th>
                  <th className="px-4 py-2 text-gray-600 text-left">
                    Secondary Locations
                  </th>
                  <th className="px-4 py-2 text-gray-600 text-left w-1/5">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData.length === 0 ? (
                  <tr>
                    <td colSpan="6" className="px-4 py-2 text-center">
                      No data to display.
                    </td>
                  </tr>
                ) : (
                  tableData.map((data, index) => (
                    <tr key={index} className="hover:bg-gray-100 border-b">
                      <td className="px-2 py-2">{data.storeId}</td>
                      <td className="px-4 py-2">{data.cityName}</td>
                      <td className="px-8 py-2">{data.ownerName}</td>
                      <td className="px-4 py-2">{data.primaryLocation}</td>
                      <td className="px-4 py-2">
                        {data.secondaryLocations.join(", ")}
                      </td>
                      <td className="py-2 w-1/5 flex gap-2">
                        <button className="px-1 py-1 bg-green-500 text-white rounded min-w-[4rem]">
                          Active
                        </button>
                        <button
                          className="px-1 py-1 bg-blue-500 text-white rounded min-w-[4rem]"
                          onClick={() => editLocation(index)}
                        >
                          Edit
                        </button>
                        <button
                          className="px-1 py-1 bg-red-500 text-white rounded min-w-[4rem]"
                          onClick={() => deleteLocation(index)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {deleteIndex !== null && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div
            style={{ width: "30rem" }}
            className="bg-white p-8 rounded-md shadow-lg h-80 gap-8 flex flex-col justify-center items-center"
          >
            <div>
              <HelpOutlineIcon
                className="text-gray-500"
                style={{ fontSize: "4rem" }}
              />
            </div>
            <div className=" gap-4 ">
              <p
                className="font-medium mb-4 text-gray-700"
                style={{ fontSize: "2rem" }}
              >
                Are you sure?
              </p>
              <h1 className="text-gray-500 ml-6">To delete this item?</h1>
            </div>
            <div className="flex justify-end gap-2">
              <button
                onClick={handleDeleteConfirm}
                className="px-4 py-2 bg-red-500 text-white rounded-md"
              >
                Yes
              </button>
              <button
                onClick={handleDeleteCancel}
                className="mr-2 px-4 py-2 bg-gray-200 text-gray-800 rounded-md"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Locations;
