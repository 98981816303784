import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import { addItemsToCart, removeItem } from "../../actions/cartAction";
import { BsInfoCircle } from "react-icons/bs";
import { CgNotes } from "react-icons/cg";
import { MdDeliveryDining } from "react-icons/md";
import { GiShoppingBag } from "react-icons/gi";

const FastDelivery = ({
  setProceed,
  closeCartSidebar,
  delivery,
  fastDelivery,
  selectedAddress,
  setSelectedAddress,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addressDetails =
    JSON.parse(localStorage.getItem("addressDetails")) || {};
    const cartItems = useSelector((state) => state.cart.cartItems);

    const [productCounts, setProductCounts] = useState({});

    useEffect(() => {
      const counts = cartItems.reduce((acc, item) => {
        acc[item.product_id] = item.quantity;
        return acc;
      }, {});
      setProductCounts(counts);
    }, [cartItems]);


  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    // Calculate total price based on product counts and their prices
    const newTotalPrice = fastDelivery.reduce((total, product) => {
      const quantity = productCounts[product.Product_id] || 0;
      return total + product.sell_price * quantity;
    }, 0);

    setTotalPrice(newTotalPrice);
  }, [productCounts, fastDelivery]);

  const handleAddToCart = useCallback((productId, quantity) => {
    if (productId) {
      dispatch(addItemsToCart(productId, quantity));
      setProductCounts((prevCounts) => ({
        ...prevCounts,
        [productId]: (prevCounts[productId] || 0) + quantity,
      }));
    } else {
      console.error("Invalid productId:", productId);
    }
  }, [dispatch]);

  const handleIncrease = (productId) => {
    dispatch(addItemsToCart(productId, 1));
  };

  const handleDecrease = (productId) => {
    if (productId) {
      setProductCounts((prevCounts) => {
        const newCount = (prevCounts[productId] || 0) - 1;
        if (newCount <= 0) {
          dispatch(removeItem(productId));
          return { ...prevCounts, [productId]: 0 };
        }
        dispatch(addItemsToCart(productId, -1));
        return { ...prevCounts, [productId]: newCount };
      });
    }
  };

  const handleProceed = () => {
    if (!selectedAddress) {
      alert("Please select an address.");
      return;
    }
  
    // Save selected address to localStorage
    localStorage.setItem("addressDetails", JSON.stringify(selectedAddress));
  
    navigate("/checkout", { state: { fastDelivery, selectedAddress } });
    closeCartSidebar();
  };

  const handleAddMore = () => {
    navigate("/search");
    closeCartSidebar()
  }
  return (
    <div className="flex flex-col h-[25rem] overflow-y-scroll scrollbar-hide">
      <div className="flex-1">
        <div className="px-2 py-1 box-border bg-white rounded-xl mt-3">
          <div className="flex items-center gap-4">
            <div className="w-12 h-12 overflow-hidden rounded-full bg-gray-100">
              <img
                className="w-full h-full object-cover"
                src="https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=180/assets/eta-icons/15-mins-filled.png"
                alt="clock"
              />
            </div>
            <div className="flex flex-cols flex-2 space-x-24 items-center">
              <span className="text-black font-bold text-lg leading-6">
                Fast Delivery
              </span>
              <button
              className="border p-2 rounded-lg text-sm text-white"
              style={{backgroundColor: "rgb(12, 131, 31)" }}
              onClick={handleAddMore}
              >
              Add more
              </button>
            </div>
          </div>
        {console.log(fastDelivery)}
          {fastDelivery &&
            fastDelivery.length > 0 &&
            fastDelivery.map((product, index) => (
              <div key={index} className="flex flex-col">
                <div className="flex self-stretch">
                  <div className="flex items-start justify-center mt-3 mb-3 ml-3 w-12 h-12">
                    <img
                      src={product.Prodouct_img_0}
                      alt={product.Product_name}
                    />
                  </div>
                  <div className="m-[9px] mx-[20px] flex-1 relative">
                    <div className="font-normal text-xs leading-4 text-[rgb(28,28,28)] w-[121px] overflow-hidden">
                      <span>{product.Product_name}</span>
                    </div>
                    <div className="flex justify-between">
                      <div className="w-32 flex justify-start py-1 font-normal text-xs leading-4 text-gray-500">
                        {product.Weight} kg
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                    <div className="flex leading-4 text-xs">
                      ₹{" "}
                      {productCounts[product.Product_id] > 0
                        ? product.sell_price * productCounts[product.Product_id]
                        : product.sell_price}
                    </div>
                      <div className="absolute ml-2 -translate-y-1/2 top-1/2 right-0">
                        {productCounts[product.Product_id] ? (
                          <div className="flex items-center">
                            <button
                              className="cursor-pointer w-8 h-8 bg-transparent text-white font-semibold text-sm leading-5 tracking-wide uppercase rounded-l"
                              onClick={() => handleDecrease(product.Product_id)}
                              style={{
                                backgroundColor: "rgb(49, 134, 22)",
                              }}
                            >
                              -
                            </button>
                            <div
                              style={{
                                backgroundColor: "rgb(49, 134, 22)",
                              }}
                              className="h-8 text-white font-semibold text-sm leading-5 tracking-wide bg-green-50 flex justify-center items-center px-2"
                            >
                              {productCounts[product.Product_id]}
                            </div>
                            <button
                              className="cursor-pointer w-8 h-8 bg-transparent text-white font-semibold text-md leading-5 tracking-wide uppercase rounded-r"
                              onClick={() => handleIncrease(product.Product_id)}
                              style={{
                                backgroundColor: "rgb(49, 134, 22)",
                              }}
                            >
                              +
                            </button>
                          </div>
                        ) : (
                          <button
                            onClick={() =>
                              handleAddToCart(product.Product_id, 1)
                            }
                            className="cursor-pointer w-16 border border-green-500 h-8 text-green-500 font-semibold text-xs leading-5 tracking-wide uppercase bg-green-50 rounded flex justify-center items-center"
                          >
                            ADD
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="px-2 py-1 box-border bg-white rounded-xl mt-3">
          <div className="flex text-base font-bold text-[rgb(31,31,31)] p-3 text-sm lg:text-md font-sans">
            Bill Details
          </div>
          <div className="flex flex-col text-xs">
            <div className="py-0 px-3 pb-2 flex justify-between text-[rgb(54,54,54)]">
              <div className="flex items-center">
                <CgNotes className="mr-2" />
                <span>Items total</span>
              </div>
              <span>₹{totalPrice}</span>
            </div>
            <div className="py-0 px-3 pb-2 flex justify-between text-[rgb(54,54,54)]">
              <div className="flex items-center">
                <MdDeliveryDining className="mr-2" />
                <span>Delivery charge</span>
                <BsInfoCircle className="ml-1" />
              </div>
              <div>
                <span className="line-through mr-1">₹25</span>
                <span className="text-blue-600">FREE</span>
              </div>
            </div>
            <div className="py-0 px-3 pb-2 flex justify-between text-[rgb(54,54,54)]">
              <div className="flex items-center">
                <GiShoppingBag className="mr-2" />
                <span>Handling charge</span>
                <BsInfoCircle className="ml-1" />
              </div>
              <div>
                <span className="line-through mr-1">₹5</span>
                <span className="text-blue-600">FREE</span>
              </div>
            </div>
            <div className="flex justify-between text-sm font-bold text-[rgb(28,28,28)] pt-1 px-3 pb-3 font-sans">
              <span>Grand total</span>
              <span>₹{totalPrice}</span>
            </div>
          </div>
        </div>
        <div className="px-2 py-1 box-border bg-white rounded-xl mt-3">
          <div className="text-base leading-5 font-bold pt-3 pb-1 text-black">
            <span className="text-sm lg:text-md font-sans">
              Cancellation Policy
            </span>
          </div>
          <div className="not-italic font-medium text-xs leading-[15px] text-[rgb(130,130,130)] pb-3">
            <p>
              Orders cannot be cancelled once packed for delivery. In case of
              unexpected delays, a refund will be provided, if applicable.
            </p>
          </div>
        </div>
      </div>
      <div className="fixed inset-x-0 bottom-0 min-h-[94px] mt-5 z-[5001]">
        <div className="box-border bg-transparent">
          <div className="overflow-hidden rounded-[15px] bg-white">
            <div className="pt-[1px] bg-[rgb(242,242,242)] shadow-[0px_0px_10px_0px_rgba(0,0,0,0.07)]">
              <div className="bg-white pt-3 px-4 pb-4 box-border">
                {Object.keys(addressDetails).length !== 0 && (
                  <div className="flex items-center p-3 px-4 bg-white border-b border-gray-200">
                    <div className="w-10 rounded-[12px] flex justify-center items-center mr-4">
                      <LocationOnIcon />
                    </div>
                    <div className="flex-1">
                      <div className="flex items-center justify-between">
                        <div className="font-bold text-[13px] leading-[17px] text-black custom-text">
                          Delivering to...
                        </div>
                        <div
                          className="text-[13px] cursor-pointer"
                          style={{ color: "rgb(12, 131, 31)" }}
                          onClick={() => setProceed(true)}
                        >
                          Change
                        </div>
                      </div>
                      <div className="text-gray-600 font-normal text-[11px] leading-[15px] mt-0.5 custom-ellipsis">
                      {selectedAddress?.name}, {selectedAddress?.house},
                      {selectedAddress?.floor}, {selectedAddress?.area},{" "}
                      {selectedAddress?.landmark}
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="flex cursor-pointer py-[10px] px-[12px] items-center rounded-[8px] w-full box-border"
                  style={{
                    backgroundColor:
                      delivery === "FastDelivery"
                        ? "rgb(12, 131, 31)"
                        : "#f74e11",

                  }}
                  onClick={() => handleProceed()}

                >
                  <div className="flex flex-1 flex-col items-start">
                    <div className="text-white text-base font-medium leading-normal font-okra">
                      <span>₹{totalPrice}</span>
                    </div>
                    <div className="mt-2 font-okra font-normal text-sm leading-4 text-white opacity-80">
                      <span>Total</span>
                    </div>
                  </div>
                  <div
                    className="text-base font-okra font-normal text-white"
                  >
                    Proceed
                    <ChevronRightIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FastDelivery;
