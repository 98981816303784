import React, { useState, useRef, useEffect } from "react";
import veg from "../../assets/images/Veg.png";
import nonveg from "../../assets/images/NonVeg.png";
import { useDispatch, useSelector } from "react-redux";
import { addItemsToCart, removeItem } from "../../actions/cartAction";
import { Link } from "react-router-dom";
import deliveryBike from "../../assets/images/delivery-bike.png";
import deliveryTruck from "../../assets/images/delivery-truck.png";
import CloseIcon from "@mui/icons-material/Close";
import "./Product.css";
import { ADMIN_PRODUCT_API } from "../../utils/constants";

const Product = ({ similarData }) => {
  const dispatch = useDispatch();
  const [showCounter, setShowCounter] = useState({});
  const { cartItems } = useSelector((state) => state.cart);
  const [startIndex, setStartIndex] = useState(0);
  const [count, setCount] = useState({});
  const [productDetailsList, setProductDetailsList] = useState({});

  const [isBoxOpen, setIsBoxOpen] = useState(false);

  const touchStartXRef = useRef(0);
  const touchEndXRef = useRef(0);

  useEffect(() => {
    // Initialize the count state for each product from cartItems
    const newCount = {};
    cartItems.forEach(item => {
      newCount[item.product_id] = item.quantity;
    });
    setCount(newCount);
  }, [cartItems]);

  useEffect(() => {
    // Update the count state when cartItems changes
    const newCount = {};
    similarData.forEach(data => {
      newCount[data.Product_id] = cartItems.find(item => item.product_id === data.Product_id)?.quantity || 0;
    });
    setCount(prevCount => ({
      ...prevCount,
      ...newCount
    }));
  }, [cartItems, similarData]);

  const toggleBox = () => {
    setIsBoxOpen(!isBoxOpen);
  };

  const handlePrevClick = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (startIndex + 3 < similarData.length) {
      setStartIndex(startIndex + 1);
    }
  };

  const handleTouchStart = (e) => {
    touchStartXRef.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndXRef.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const touchStartX = touchStartXRef.current;
    const touchEndX = touchEndXRef.current;
    const touchDiff = touchStartX - touchEndX;

    if (touchDiff > 50) {
      // Swiped left
      handleNextClick();
    } else if (touchDiff < -50) {
      // Swiped right
      handlePrevClick();
    }
  };

  const handleAddToCart = (productId, quantity) => {
    console.log(`Adding ${quantity} of product ID ${productId} to cart.`);
    dispatch(addItemsToCart(productId, quantity));
    setShowCounter((prev) => ({ ...prev, [productId]: true }));
  };

  const handleIncrease = (productId) => {
    dispatch(addItemsToCart(productId, 1));
  };

  const handleDecrease = (productId) => {
    console.log(`Decreasing quantity of product ID ${productId}.`);
    dispatch(addItemsToCart(productId, -1));
  };

  const getQuantity = (productId) => {
    return count[productId] || 0;
  };

  const fetchProductPrice = async (Product_id) => {
    try {
      const storedStoreId = localStorage.getItem("StoreId");
      const storeId = storedStoreId ? JSON.parse(storedStoreId).store_id : null;

      const priceUrl = storeId
        ? `${ADMIN_PRODUCT_API}/${Product_id}/price?storeId=${storeId}`
        : `${ADMIN_PRODUCT_API}/${Product_id}`;

      const priceResponse = await fetch(priceUrl);
      if (!priceResponse.ok) {
        throw new Error(`HTTP error! status: ${priceResponse.status}`);
      }

      const priceData = await priceResponse.json();

      setProductDetailsList((prevState) => ({
        ...prevState,
        [Product_id]: {
          MRP: priceData.MRP || "",
          sell_price: priceData.sell_price || "",
          offer: priceData.offer || "",
          you_save: priceData.you_save || "",
          delivery_option: priceData.delivery_option || "Standard Delivery",
        },
      }));
    } catch (error) {
      console.error("Error fetching product or price data:", error);
    }
  };

  useEffect(() => {
    similarData.forEach((data) => {
      fetchProductPrice(data.Product_id);
    });
  }, [similarData]);

  return (
    <div
      className="px-2 flex overflow-x-auto gap-4 mb-4"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {similarData.map((data, index) => {
         const productDetails = productDetailsList[data.Product_id] || {
          MRP: data.MRP,
          sell_price: data.sell_price,
          offer: data.offer,
          delivery_option: "Standard Delivery",
        };

        <div
          key={data.Product_id}
          className="bg-white rounded-lg shadow-md p-2 relative border border-[0.5px solid rgb(232, 232, 232)]"
        >
          <div className="box-border  font-normal rounded-lg flex flex-col justify-between">
            <div className="relative bg-center bg-no-repeat flex justify-between">
              <span className="flex items-center justify-center h-6 w-16 p-2 bg-green-700 rounded-full">
                <span className="text-xs font-normal block uppercase text-white">
                  {productDetails.offer}%
                </span>
              </span>
            </div>

            <Link to={`/product/:${data.Product_id}`}>
              <div className="zoom-container px-5">
                <img
                  className="zoom-image object-cover rounded-lg bg-transparent"
                  src={data.Prodouct_img_0}
                  alt={data.Product_name}
                  onError={(e) => (e.target.src = "/images/Noimage.jpg")}
                />
              </div>
            </Link>

            <div className="flex items-center pl-1">
              <img
                className="h-4 w-auto mr-2"
                src={data.kind === "veg" ? veg : nonveg}
                alt={data.kind === "veg" ? "Veg" : "Non Veg"}
              />
            </div>
          </div>

          <div className="p-1 pt-1">
            <div className="mb-2">
              <h2 className="block text-xs truncate-3-lines text-gray-800">
                {data.Brand_name}
              </h2>
              <h2
                className="text-customGray text-opacity-100 mb-1 truncate-3-lines leading-5 font-normal pt-1 cursor-pointer"
                style={{ fontSize: "17px", fontWeight: "500" }}
                title={data.Product_name}
              >
                {data.Product_name}
              </h2>
            </div>
            <div className="flex justify-between items-center mt-6 gap-2 ">
              <div className="flex flex-col text-xs">
                <div>
                  <span className="font-semibold lg:text-base text-[11px]">
                    ₹{productDetails.sell_price}
                  </span>
                </div>
                <div>
                  <span className="text-gray-400 line-through lg:text-sm text-[11px]">
                    ₹{productDetails.MRP}
                  </span>
                </div>
              </div>
              <div className="text-xs">
                {showCounter[data.Product_id] ? (
                  <div className="flex items-center lg:w-[5rem] w-[3.5rem]">
                    <button
                      className="cursor-pointer w-8 h-8 bg-transparent text-white font-semibold text-sm leading-5 tracking-wide uppercase rounded-l"
                      onClick={() => handleDecrease(data.Product_id)}
                      style={{ backgroundColor: "rgb(49, 134, 22)" }}
                    >
                      -
                    </button>
                    <div
                      style={{ backgroundColor: "rgb(49, 134, 22)" }}
                      className="h-8 text-white font-semibold text-sm leading-5 tracking-wide bg-green-50 flex justify-center items-center px-2"
                    >
                      {getQuantity(data.Product_id)}
                    </div>
                    <button
                      className="cursor-pointer w-8 h-8 bg-transparent text-white font-semibold text-md leading-5 tracking-wide uppercase rounded-r "
                      onClick={() => handleIncrease(data.Product_id)}
                      style={{ backgroundColor: "rgb(49, 134, 22)" }}
                    >
                      +
                    </button>
                  </div>
                ) : (
                  <button
                    style={{
                      border: "1px solid rgb(49, 134, 22)",
                      color: "rgb(49, 134, 22)",
                      fontSize: "13px",
                    }}
                    className="cursor-pointer lg:w-16 w-[3.5rem] h-8 font-semibold text-xs leading-5 tracking-wide uppercase bg-green-50 rounded-lg flex justify-center items-center"
                    onClick={() => handleAddToCart(data.Product_id, 1)}
                  >
                    ADD
                  </button>
                )}
              </div>
            </div>
            <div
  className={`mt-3 md:mt-5 rounded-md w-full flex items-center justify-center p-1 ${
    productDetails.delivery_option?.toLowerCase() === "fast delivery"
      ? "bg-yellow-200"
      : "bg-red-200"
  }`}
>
  <div className="ml-2">
    {productDetails.delivery_option?.toLowerCase() === "fast delivery" ? (
      <img
        src={deliveryBike} // Use the appropriate icon for fast delivery
        alt="deliveryBike"
        className="w-6 h-6"
      />
    ) : (
      <img
        src={deliveryTruck}
        alt="deliveryTruck"
        className="w-6 h-6"
      />
    )}
  </div>
  <div className="text-[11px] md:text-sm ml-2">
    {productDetails.delivery_option}
  </div>
</div>

            {isBoxOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-50 hidden lg:block">
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="bg-white p-4 rounded-lg z-10">
                  <button
                    className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
                    onClick={() => setIsBoxOpen(false)}
                  >
                    <CloseIcon />
                  </button>
                  <h2 className="text-lg font-semibold">{data.Product_name}</h2>
                  <p className="text-gray-700 mt-2">{data.description}</p>
                </div>
              </div>
            )}
          </div>
        </div>;
      })}
    </div>
  );
};

export default Product;
