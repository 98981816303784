import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import HelpIcon from '@mui/icons-material/Help';
import paymentMethods from '../../../assets/images/payment-methods.svg';

const footerLinks = [
  {
    title: "about",
    links: [
      { name: "Contact Us", redirect: "/contact-us" },
      { name: "About Us", redirect: "/about" },
    ]
  },
  {
    title: "help",
    links: [
      { name: "Shipping", redirect: "/shipping-policy" },
      { name: "Cancellation & Returns", redirect: "/return-replacement" },
      { name: "FAQ", redirect: "" }
    ]
  },
  {
    title: "policy",
    links: [
      { name: "Return Policy", redirect: "/return-replacement" },
      { name: "Terms Of Use", redirect: "/terms" },
      { name: "Security", redirect: "" },
      { name: "Privacy", redirect: "/privacy" },
    ]
  },
  {
    title: "social",
    links: [
      { name: "Facebook", redirect: "https://www.facebook.com/profile.php?id=61551726244471" },
      { name: "X", redirect: "https://x.com/vivimart_in" },
      { name: "Instagram", redirect: "https://www.instagram.com/vivimart.in/?fbclid=IwAR0dgR9TA8qXFaE5tKkUe8GbmzaRkNs10GVgesEwSZTiqg96KFJgYe-CXgw" },
      { name: "YouTube", redirect: "https://www.youtube.com/@vivimart" }
    ]
  }
];

const Footer = () => {
  const location = useLocation();
  const [adminRoute, setAdminRoute] = useState(false);
  const [storeRoute, setstoreRoute] = useState(false);

  useEffect(() => {
    setAdminRoute(location.pathname.split("/", 2).includes("admin"));
    setstoreRoute(location.pathname.split("/", 2).includes("store"));
  }, [location]);

  return (
    <>
      {!adminRoute && !storeRoute && (
        <>
          <footer className="w-full py-1 mt-3 sm:py-4 px-4 sm:px-12 bg-primary-darkBlue text-white text-xs border-b border-gray-600 flex flex-col sm:flex-row overflow-hidden">
            <div className="w-full sm:w-7/12 flex flex-col sm:flex-row">
              {footerLinks.map((el, i) => (
                <div className="w-full sm:w-1/5 flex flex-col gap-2 my-3 sm:my-6 ml-5" key={i}>
                  <h2 className="text-primary-grey mb-2 uppercase">{el.title}</h2>
                  {el.links.map((item, i) => (
                    item.redirect.startsWith("/") ? (
                      <Link to={item.redirect} className="hover:underline" key={i}>{item.name}</Link>
                    ) : (
                      <a href={item.redirect} target="_blank" rel="noreferrer" className="hover:underline" key={i}>{item.name}</a>
                    )
                  ))}
                </div>
              ))}
            </div>

            <div className="border-gray-600 h-36 w-1 border-l mr-5 mt-6 hidden sm:block"></div>
            <div className="w-full sm:w-5/12 my-6 mx-5 sm:mx-0 flex flex-col sm:flex-row gap-2 sm:gap-0 justify-between">
              <div className="w-full sm:w-1/2">
                <h2 className="text-primary-grey">Mail Us:</h2>
                <p className="mt-2 leading-5">
                  Vivimart,<br />
                  Address: 3rd Floor, Door (O)No.736/6 (N)No.1045,<br />
                  Avinashi Road, Coimbatore,<br />
                  Outer Ring Road, Devarabeesanahalli Village,<br />
                  Phone: +918686865851,<br />
                  Email: <a href='mailto:cs@vivimart.in'>cs@vivimart.in</a>
                </p>
              </div>
            </div>
          </footer>

          <div className="px-16 py-6 w-full bg-primary-darkBlue hidden sm:flex justify-between items-center text-sm text-white">
            <a href="" rel="noreferrer" target="_blank" className="flex items-center gap-2">
              <span className="text-yellow-400"><CardGiftcardIcon sx={{ fontSize: "20px" }} /></span> Gift Cards
            </a>
            <a href="" target="_blank" rel="noreferrer" className="flex items-center gap-2">
              <span className="text-yellow-400"><HelpIcon sx={{ fontSize: "20px" }} /></span> Help Center
            </a>
            <span>Copyright © 2024 All rights reserved by ViviMart</span>
            <img draggable="false" src={paymentMethods} alt="Card Payment" />
          </div>
        </>
      )}
    </>
  );
};

export default Footer;
