import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ children, isAdmin, isStore }) => {
  const { isAuthenticated } = useSelector((state) => state.user);

  if (typeof isAuthenticated === 'undefined') {
    // Optionally, return a loading spinner or null until authentication state is determined
    return null;
  }

  if (isAdmin && isAuthenticated) {
    return children;
  }
  if (isStore && isAuthenticated) {
    return children; 
  }
  if (!isAdmin && !isStore && isAuthenticated) {
    return children;
  }
  return <Navigate to={isStore ? "/store/login" : "/login"} />;
};
export default ProtectedRoute