import {
    SUB_STORE_FAIL,
    SUB_STORE_REQUEST,
    SUB_STORE_SUCCESS,
    CLEAR_ERRORS
} from "../constants/subStoreConstants";
import axiosInstance from "../utils/api";

export const getSubStoreByStoreId = (storeId) => async (dispatch) => {
    try {
      dispatch({ type: SUB_STORE_REQUEST });
  
      const { data } = await axiosInstance.get(`/sub-stores/${storeId}`);
  
      dispatch({
        type: SUB_STORE_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: SUB_STORE_FAIL,
        payload: error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      });
    }
  };

export const clearError = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
