import React from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { addItemsToCart, removeItemsFromCart, removeItem } from "../../actions/cartAction";
import { Link } from "react-router-dom";

const CartItem = ({
  product_id,
  Product_name,
  Brand_name,
  sell_price,
  Prodouct_img_0,
  MRP,
  quantity,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const increaseQuantity = () => {
    dispatch(addItemsToCart(product_id, 1));
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      dispatch(addItemsToCart(product_id, -1));
    } else {
      dispatch(removeItemsFromCart(product_id));
      enqueueSnackbar("Product Removed From Cart", { variant: "success" });
    }
  };

  const removeCartItem = () => {
    dispatch(removeItemsFromCart(product_id));
    enqueueSnackbar("Product Removed From Cart", { variant: "success" });
  };

  if (!quantity) return null; // Skip rendering if quantity is undefined

  return (
    <div className="flex flex-col gap-3 py-5 pl-2 sm:pl-6 border-b overflow-hidden">
      <Link
        to={`/product/${product_id}`}
        className="flex flex-col sm:flex-row gap-5 items-stretch w-full group"
      >
        <div className="w-full sm:w-1/6 h-28 flex-shrink-0">
          <img
            draggable="false"
            className="h-full w-full object-contain"
            src={Prodouct_img_0}
            alt={Product_name}
          />
        </div>
        <div className="flex flex-col sm:gap-5 w-full pr-6">
          <div className="flex flex-col gap-0.5 sm:w-3/5">
            <p className="group-hover:text-primary-blue">{Product_name}</p>
            <span className="text-sm text-gray-500">Brand: {Brand_name}</span>
          </div>
          <div className="flex items-baseline gap-2 text-xl font-medium">
            <span>₹{(sell_price * quantity).toFixed(2)}</span>
            <span className="line-through text-sm text-gray-400">
              MRP: ₹{MRP} 
            </span>
          </div>
          <div className="flex items-center gap-5">
            <div className="flex items-center gap-3">
              <button
                onClick={decreaseQuantity}
                className="font-medium text-xl hover:bg-gray-100 h-8 w-8 p-2 rounded-md border"
              >
                -
              </button>
              <span className="font-medium text-xl">{quantity}</span>
              <button
                onClick={increaseQuantity}
                className="font-medium text-xl hover:bg-gray-100 h-8 w-8 p-2 rounded-md border"
              >
                +
              </button>
            </div>
            <button
              onClick={removeCartItem}
              className="text-red-500 font-medium hover:text-red-600 hover:bg-gray-100 p-1 rounded-md"
            >
              Remove
            </button>
          </div>
        </div>
      </Link>
      <div>
        
      </div>
    </div>
  );
};

export default CartItem;
