import WebFont from "webfontloader";
import Footer from "./components/Layouts/Footer/Footer";
import Header from "./components/Layouts/Header/Header";
import Login from "./components/User/Login";
import Register from "./components/User/Register";
import AdminLogin from "./components/Admin/Login";
import AdminRegister from "./components/Admin/Register";
import StoreLogin from "./components/Store/Login";
import StoreRegister from "./components/Store/Register";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { loginUser, loadUser, logoutUser } from './actions/userAction';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkAuthState } from "./utils/auth";
import UpdateProfile from "./components/User/UpdateProfile";
import UpdatePassword from "./components/User/UpdatePassword";
import ForgotPassword from "./components/User/ForgotPassword";
import ResetPassword from "./components/User/ResetPassword";
import Account from "./components/User/Account";
import ProtectedRoute from "./Routes/ProtectedRoute";
import Home from "./components/Home/Home";
import ProductDetails from "./components/ProductDetails/ProductDetails";
import Products from "./components/Products/Products";
import Cart from "./components/Cart/Cart";
import Shipping from "./components/Cart/Shipping";
import OrderConfirm from "./components/Cart/OrderConfirm"; 
import Payment from "./components/Cart/Payment";
import OrderStatus from "./components/Cart/OrderStatus";
import OrderSuccess from "./components/Cart/OrderSuccess";
import MyOrders from "./components/Order/MyOrders";
import OrderDetails from "./components/Order/OrderDetails";
import Dashboard from "./components/Admin/Dashboard";
import DashboardStore from "./components/Store/Dashboard";
import MainData from "./components/Admin/MainData";
import OrderTable from "./components/Admin/OrderTable";
import UpdateOrder from "./components/Admin/UpdateOrder";
import ProductTable from "./components/Admin/ProductTable";
import NewProduct from "./components/Admin/NewProduct";
import UpdateProduct from "./components/Admin/UpdateProduct";
import UserTable from "./components/Admin/UserTable";
import UpdateUser from "./components/Admin/UpdateUser";
import ReviewsTable from "./components/Admin/ReviewsTable";
import Wishlist from "./components/Wishlist/Wishlist";
import NotFound from "./components/NotFound";
import Privacy from "./components/Layouts/Footer/Privacy";
import ShippingPolicy from "./components/Layouts/Footer/Shipping";
import ReturnAndReplacement from "./components/Layouts/Footer/ReturnAndReplacement";
import About from "./components/Layouts/Footer/About";
import Contact from "./components/Layouts/Footer/Contact";
import Terms from "./components/Layouts/Footer/Terms";
import Category from "./components/Admin/Category";
import SubCategory from "./components/Admin/SubCategory";
import SubSubCategory from "./components/Admin/SubSubCategory";
import Locations from "./components/Admin/Locations";
import MainDataStore from "./components/Store/MainData";
import Orders from "./components/Store/Orders";
import StoreProductTable from "./components/Store/ProductTable";
import Profile from "./components/Store/Profile";
import StoreHeader from "./components/Layouts/Header/StoreHeader";
import StoreInvoice from "./components/Store/StoreInvoice";
import { auth } from "./firebase";
import Checkout from "./components/Cart/Checkout";
import SearchProducts from "./components/Layouts/SearchProducts";
import LoadingPage from "./components/LoadingPage";

function App() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isAdminRoute = pathname.startsWith("/admin");
  const isStoreRoute = pathname.startsWith("/store");
  const isCheckout = pathname.startsWith("/checkout");
  const [isLogin, setIsLogin] = useState(false);
  const [loading, setLoading] = useState(true);

  const { isAuthenticated } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Roboto:300,400,500,600,700"],
      },
    });
  }, []);

  useEffect(() => {
    const checkAuth = async () => {
      await dispatch(loadUser());
      setLoading(false);
    };
    checkAuth();
  }, [dispatch]);

  // always scroll to top on route/path change
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  useEffect(() => {
    dispatch(loadUser());
    checkAuthState(dispatch); // Check and restore auth state
  }, [dispatch]);

  if (loading) return <LoadingPage />;

  // firebase auth
  // useEffect(() => {
  //   const unsubscribe = auth.onAuthStateChanged((user) => {
  //     setUser(user);
  //   });
  //   return () => unsubscribe();
  // }, []);

  // const handleLogout = () => {
  //   auth.signOut().then(() => {
  //     setUser(null);
  //   })
  // }

  return (
    <>
      {!isAdminRoute && !isStoreRoute && !isCheckout && <Header />}
      {!isAdminRoute && isStoreRoute && isLogin && <StoreHeader />}

      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/login" element={<Login/>} /> */}
        <Route path="/register" element={<Register />} />

        <Route path="/product/:id" element={<ProductDetails />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/:keyword" element={<Products />} />
        <Route path="/search" element={<SearchProducts />} />
        <Route path="/search/:keyword" element={<SearchProducts />} />

        <Route path="/cart" element={<Cart />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/shipping-policy" element={<ShippingPolicy />} />
        <Route path="/return-replacement" element={<ReturnAndReplacement />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/checkout" element={<Checkout />} />

        {/* order process */}
        <Route
          path="/shipping"
          element={
            <ProtectedRoute>
              <Shipping />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/order/confirm"
          element={
            <ProtectedRoute>
              <OrderConfirm />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/process/payment"
          element={
            <ProtectedRoute>
              <Payment />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/orders/success"
          element={<OrderSuccess success={true} />}
        />
        <Route
          path="/orders/failed"
          element={<OrderSuccess success={false} />}
        />
        {/* order process */}

        <Route
          path="/order/:id"
          element={
            <ProtectedRoute>
              <OrderStatus />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/orders"
          element={
              <MyOrders />
          }
        ></Route>

        <Route
          path="/order_details/:id"
          element={
            
              <OrderDetails />
            }
        ></Route>

        <Route
          path="/account"
          element={
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/account/update"
          element={
            <ProtectedRoute>
              <UpdateProfile />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/password/update"
          element={
            <ProtectedRoute>
              <UpdatePassword />
            </ProtectedRoute>
          }
        ></Route>

        <Route path="/password/forgot" element={<ForgotPassword />} />

        <Route path="/password/reset/:token" element={<ResetPassword />} />

        <Route
          path="/wishlist"
          element={
            <ProtectedRoute>
              <Wishlist />
            </ProtectedRoute>
          }
        ></Route>

        {isLogin && (
          <Route
            path="/admin"
            element={
              <ProtectedRoute isAdmin={true}>
                <Dashboard activeTab={0}>
                  <MainData />
                </Dashboard>
              </ProtectedRoute>
            }
          ></Route>
        )}
        <Route
          path="/admin"
          element={
            <ProtectedRoute isAdmin={true}>
              <AdminLogin setIsLogin={setIsLogin} />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/dashboard"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard>
                <MainData />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/order/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={1}>
                <UpdateOrder />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/products"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={2}>
                <ProductTable />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/new_product"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={3}>
                <NewProduct />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/product/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={2}>
                <UpdateProduct />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/users"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={4}>
                <UserTable />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/user/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={4}>
                <UpdateUser />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/reviews"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={5}>
                <ReviewsTable />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/Category"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={6}>
                <Category />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/SubCategory"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={7}>
                <SubCategory />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route> 

        <Route
          path="/admin/SubSubCategory"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={8}>
                <SubSubCategory />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/Admin/location"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={1}>
                <Locations />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/store"
          element={
            <ProtectedRoute isStore>
              <DashboardStore activeTab={0}>
                <MainDataStore />
              </DashboardStore>
            </ProtectedRoute>
          }
        />
        <Route
          path="/store/login"
          element={<StoreLogin setIsLogin={setIsLogin} />}
        />
        <Route path="/store/register" element={<StoreRegister />} />
        <Route
          path="/store/products"
          element={
            <ProtectedRoute isStore={true}>
              <DashboardStore>
                <StoreProductTable />
              </DashboardStore>
            </ProtectedRoute>
          }
        />
        <Route
          path="/store/orders"
          element={
            <ProtectedRoute isStore={true}>
              <DashboardStore>
                <Orders />
              </DashboardStore>
            </ProtectedRoute>
          }
        />
        <Route
          path="/store/profile"
          element={
            <ProtectedRoute isStore={true}>
              <DashboardStore>
                <Profile />
              </DashboardStore>
            </ProtectedRoute>
          }
        />
        <Route
          path="/store"
          element={
            <ProtectedRoute isStore={true}>
              <DashboardStore>
                <MainDataStore />
              </DashboardStore>
            </ProtectedRoute>
          }
        />
        <Route
          path="/store/orders/printStoreInvoice"
          element={<StoreInvoice />}
        />

        <Route path="*" element={<NotFound />}></Route>
      </Routes>
      {!isCheckout && <Footer />}
    </>
  );
}

export default App;