import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDwFWZ_gV5MRMSgHq9xEmnrCCrEltjdl4s",
  authDomain: "vivimartuser.firebaseapp.com",
  projectId: "vivimartuser",
  storageBucket: "vivimartuser.appspot.com",
  messagingSenderId: "978230642962",
  appId: "1:978230642962:web:e8d97ff4228aa5ab740bcb",
  measurementId: "G-24PKCSSS02"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app); 