import React, { useState, useEffect } from "react";
import dummy from "../../assets/images/dummy.png";
import { ADMIN_SUB_SUB_CATEGORY_API } from "../../utils/constants";
import CsvUploadPopup from "./CsvUploadPopup";
import SearchIcon from "@mui/icons-material/Search";

const SubSubCategory = () => {
  const [subSubCategoryData, setSubSubCategoryData] = useState([]);
  const [isCSVUploadOpen, setIsCSVUploadOpen] = useState(false);
  const [filteredCategoryData, setFilteredCategoryData] = useState([]);
  const [searchSubSubCategory, setSearchSubSubCategory] = useState("");
  const [endPoint, setEndPoint] = useState("");

  useEffect(() => {
    fetch(ADMIN_SUB_SUB_CATEGORY_API)
      .then((response) => response.json())
      .then((subSubCategoryData) => {
        // Handle category data
        setSubSubCategoryData(subSubCategoryData);
        setFilteredCategoryData(subSubCategoryData);
      })
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);

  const generateCSVData = () => {
    const headers = [
      "Sub Sub Categories_Id",
      "Sub Sub Categories_Name",
      "Sub Sub Categories_Img",
    ];
    const rows = subSubCategoryData.map((subsubcategory) => [
      subsubcategory.sub_sub_category_id,
      subsubcategory.sub_sub_category_name,
      subsubcategory.sub_sub_category_img,
    ]);
    let csvContent = headers.join(",") + "\n";

    csvContent += rows.map((row) => row.join(",")).join("\n");

    return csvContent;
  };

  const downloadCSV = () => {
    const csvData = generateCSVData();

    const blob = new Blob(["\ufeff", csvData], {
      type: "application/vnd.ms-excel",
    });

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "Sub_Sub_Categories.xlsx";

    document.body.appendChild(a);

    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const SearchSubSubCategory = () => {
    const filteredData = subSubCategoryData.filter((subsubcategory) =>
      subsubcategory.sub_sub_category_name
        .toLowerCase()
        .includes(searchSubSubCategory.toLowerCase())
    );
    setFilteredCategoryData(filteredData);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      SearchSubSubCategory();
    }
  };

  const handleUploadSuccess = (updatedData) => {
    setSearchSubSubCategory(updatedData);
    setFilteredCategoryData(updatedData);
  };

  const handleCSVUpload = () => {
    setIsCSVUploadOpen(true);
    setEndPoint("subsubcategory")
  }

  return (
    <div className="flex flex-wrap gap-8 ">
      <div className="shadow-xl sm:w-72 shadow-gray-250 border border-gray-300 p-2 rounded-md bg-white h-[30rem] w-[23rem] flex-none">
        <div className="border-b border-gray-300 p-2 font-sans">
          <h1>Copyright Information</h1>
        </div>
        <div className="ml-32 sm:ml-20">
          <img src={dummy} alt="oops!" className="mt-8 h-28 mb-4" />
          <label
            htmlFor="file-upload"
            className="font-sans bg-gray-300 px-14 py-1 relative right-8"
          >
            Add Cover
            <input
              id="file-upload"
              name="file-upload"
              type="file"
              className="sr-only"
            />
          </label>
        </div>
        <div className="p-4 font-sans">
          <h1>Sub Sub Category Name</h1>
          <input
            placeholder="Sub Sub Category Name"
            className="block w-full px-3 py-2 text-base font-normal leading-normal text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out"
            type="text"
          />
          <h1>Sub Sub Category Status</h1>
          <input
            placeholder="Sub Sub Category Status"
            className="block w-full px-3 py-2 text-base font-normal leading-normal text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out"
            type="text"
          />
        </div>
        <div className="p-4">
          <button
            className="flex justify-center my-2 mr-4
         items-center py-2 px-4 h-9 bg-primary-blue text-base font-normal rounded-md text-white"
          >
            Save
          </button>
        </div>
      </div>
      <div className="flex-1 min-w-0 border border-gray-300 shadow-xl rounded-md shadow-gray-250 h-[30rem] bg-white overflow-y-auto">
        <div className="border-b border-gray-300 p-2 font-sans">
          <h1>All Categories</h1>
          <div className="flex justify-end gap-2 pr-2">
            <button
              className="flex justify-center
       items-center py-2 px-4 h-9 bg-primary-blue text-xs text-white"
              onClick={downloadCSV}
            >
              Export as CSV
            </button>
            <button
              className="flex justify-center
         items-center py-2 px-4 h-9 bg-primary-green text-xs text-white"
              onClick={() => handleCSVUpload()}
            >
              Import CSV
            </button>
          </div>
        </div>
        <div className="p-4">
          <div className="flex items-center border border-gray-300 rounded">
            <input
              placeholder="Search Sub Sub Category"
              className="block w-full px-3 py-2 text-base font-normal leading-normal text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out"
              value={searchSubSubCategory}
              type="text"
              onChange={(e) => setSearchSubSubCategory(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button
              type="submit"
              className="text-primary-blue px-4 py-2"
              onClick={SearchSubSubCategory}
            >
              <SearchIcon />
            </button>
          </div>
          <div className="w-full pt-8">
            <table className="w-full border-collapse font-sans">
              <thead>
                <tr className="border-b">
                  <th className="px-2 py-2 w-1/12 text-gray-600 text-left">
                    Id
                  </th>
                  <th className="px-4 py-2 text-gray-600 text-left">Cover</th>
                  <th className="px-4 py-2 text-gray-600 text-left">
                    Category Name
                  </th>
                  <th className="px-8 py-2 text-gray-600 text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredCategoryData.map((subSubCategory) => (
                  <tr
                    key={subSubCategory.sub_sub_category_id}
                    className="hover:bg-gray-100 border-b"
                  >
                    <td className="px-2 py-2">
                      {subSubCategory.sub_sub_category_id}
                    </td>
                    <td className="px-4 py-2">
                      {subSubCategory.sub_sub_category_img}
                    </td>
                    <td className="px-4 py-2 w-40">
                      <img
                        className="w-20"
                        src={subSubCategory.sub_sub_category_name}
                      />
                    </td>
                    <td className="py-2">
                      <button className="px-1 py-1 bg-green-500 text-white rounded">
                        Active
                      </button>
                      <button className="px-1 py-1 bg-blue-500 text-white rounded ml-2">
                        Edit
                      </button>
                      <button className="px-1 py-1 bg-red-500 text-white rounded ml-2">
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}

                {/* Add more rows as needed */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isCSVUploadOpen && <CsvUploadPopup isClose={setIsCSVUploadOpen}
      endPoint={endPoint}
      onUploadSuccess={handleUploadSuccess} 
       />}
    </div>
  );
};

export default SubSubCategory;
