import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetails, updateOrderStatus } from "../../actions/orderAction"; // Import updateOrderStatus action
import { useSnackbar } from "notistack";

const OrderDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { order, loading, error } = useSelector((state) => state.orderDetails);
  const { isUpdated } = useSelector((state) => state.order);

  useEffect(() => {
    if (id) {
      dispatch(getOrderDetails(id));
    }
  }, [dispatch, id, isUpdated]);

  const handleCancelOrder = () => {
    dispatch(updateOrderStatus(id, "Canceled")).then(() => {
      enqueueSnackbar("Order canceled successfully", { variant: "success" });
      navigate("/orders");
    });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!order) return <p>No order details available.</p>;

  return (
    <div className="order-details-page p-4 mt-24">
      <h2 className="text-lg font-bold mb-2">Order Details</h2>
      <p className="mb-2">{order.order_items.length} items</p>
      <div className="order-details bg-white p-8 rounded-lg shadow-inner">
        <ul>
          {order.order_items.map((item, index) => (
            <li key={index} className="flex items-center justify-between mb-4">
              <div className="w-full border p-2 flex items-center justify-between rounded-lg shadow-md hover:shadow-lg">
                <div className="flex items-center">
                  <img
                    src={item.product_image_0}
                    alt={item.Product_name}
                    className="w-12 h-12 object-cover rounded-md mr-4"
                  />
                  <div>
                    <p className="font-semibold">{item.Product_name}</p>
                    <p className="text-sm text-gray-600">
                      {item.quantity} unit{item.quantity > 1 ? "s" : ""}
                    </p>
                  </div>
                </div>
                <div className="text-right">
                  <p className="font-semibold">
                    ₹{parseFloat(item.sell_price).toFixed(2)}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div className="bill-summary border-t pt-4 mt-4">
          <h3 className="text-md font-semibold mb-4">Bill Summary</h3>
          <div className="flex justify-between mb-2">
            <p>Item Total</p>
            <p>₹{parseFloat(order.total_price).toFixed(2)}</p>
          </div>
          <div className="flex justify-between mb-2">
            <p>Handling Charge</p>
            <p>₹{parseFloat(order.handling_charge).toFixed(2)}</p>
          </div>
          <div className="flex justify-between mb-2">
            <p>Delivery Fee: Free with Pass</p>
            <p className="text-green-500">₹{order.primary_delivery_fee}</p>
          </div>
          <div className="flex justify-between mb-2">
            <p>Zepto Pass Membership</p>
            <p>₹{parseFloat(order.pass_membership_fee).toFixed(2)}</p>
          </div>
          <div className="flex justify-between font-bold text-lg mt-2 border-t pt-2">
            <p>Total Bill</p>
            <p>₹{parseFloat(order.total_price).toFixed(2)}</p>
          </div>
        </div>
        <button
          onClick={handleCancelOrder}
          className="bg-red-500 text-white mt-4 py-2 px-4 rounded-lg shadow-md hover:bg-red-600"
        >
          Cancel Order
        </button>
      </div>
    </div>
  );
};

export default OrderDetailsPage;
