import React from 'react';
import MetaData from '../MetaData';

const ShippingPolicy = () => {
  return (
    <div className="w-full mt-20 px-4 sm:px-8 md:px-16 lg:px-32 xl:px-64">
      <MetaData title="Delivery and Shipping Policy | Vivimart" />
      <main className="bg-white p-6 shadow-lg rounded-lg">
        <h1 className="text-3xl font-bold mb-12">DELIVERY AND SHIPPING POLICY</h1>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-6">1. GENERAL</h2>
          <p className="mb-4 text-justify tracking-wide">
          1.1. GVS FOOD AND RETAIL PRIVATE LIMITED, headquartered at Srivari Srimat, 3rd Floor, Door Old No.736/6 , New No.1045, Avinashi Road Coimbatore-641018, India, referred to as "GVS," "we," "us," or "our," operates the mobile application/website and tablet applications available at <a href='www.vivimart.in' className='text-blue-700 underline' target='_blank'>www.vivimart.in</a>, collectively known as "ViviMart," "Website," or "App." ViviMart facilitates the sale and purchase of products and services listed and sold as "Products" on the Website. The term "User," "Users," "you," or "your" refers to any individual using, accessing, or browsing the Website and/or purchasing the Products.
          </p>
          <p className="mb-4 text-justify">
          1.2. Please refer to the "Terms and Conditions" on ViviMart, which are an integral part of this "Shipping and Delivery Policy" ("Policy"). This Policy, along with the "Terms and Conditions" and other policies on the Website, outlines our procedures and policies regarding the delivery and shipping of Products purchased through ViviMart.
          </p>
          <p className="mb-4 text-justify">
          1.3. We offer shipping and delivery of our Products throughout India. Our goal is to provide an exceptional customer experience by partnering with leading logistics service providers to ensure timely and hassle-free delivery of the ordered Products.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-6">2. APPLICABILITY OF POLICY</h2>
          <p className="mb-4 text-justify">
          2.1. By using ViviMart and/or initiating a purchase request for Products on the Website, you agree to be bound by the terms outlined in this Policy. If you do not agree with these terms, we advise against transacting on ViviMart. Please note that we may update the terms of this Policy from time to time. Therefore, whenever you use ViviMart, please review the Policy to ensure you are aware of the terms and conditions in effect at that time.
          </p>
          
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">3. TERMS OF SHIPPING AND DELIVERY</h2>
          <p className="mb-4 text-justify">
          3.1. We partner with third-party logistics service providers for the shipping and delivery of Products ("Logistics Partners"). The details of the responsible Logistics Partner for processing the shipping and delivery of any purchased Product will be provided when the Product is handed over to the Logistics Partner by us. Products are typically dispatched within 2 to 4 days after receiving the order on ViviMart. The estimated delivery timeline will be displayed to the user upon order confirmation. We will also share order details via email and/or mobile number provided during registration. While we make all reasonable efforts to ensure timely delivery, we reserve the right to ship and deliver Products on our own without using Logistics Partners or third-party service providers.
          </p>
          <p className="mb-4 text-justify">
          3.2. To ensure timely delivery, we may request or collect specific information such as your name, shipping address, billing address, landmarks, and contact details. You are responsible for providing accurate and complete information for successful delivery. Failure to do so may result in a lack of liability on our part for any delivery failures due to incomplete or inaccurate information.
          </p>
          <p className="mb-4 text-justify">
          3.3. We will make efforts to deliver the purchased Product to your designated address within the estimated delivery timeline. If you are unavailable to accept the delivery, our Logistics Partners will attempt delivery up to three times. If the third attempt is unsuccessful, we reserve the right to cancel the order and may deduct the shipping and delivery charges from any subsequent refunds.
          </p>
          <p className="mb-4 text-justify">
          3.4. While we strive for timely delivery, please understand that delays may occur due to factors beyond our control, including logistics issues, adverse weather conditions, disruptions, acts of nature, and unforeseen circumstances.
          </p>
          <p className="mb-4 text-justify">
          3.5. In the event of delays, we will make reasonable attempts to inform you via email. We are not liable for any claims arising from our failure to notify you of delivery delays, and we are not obligated to compensate for such delays.
          </p>
          <p className="mb-4 text-justify">
          3.6. We engage delivery personnel and Logistics Partners with high standards of professionalism. However, any issues arising from delivery personnel's behaviour or conduct should be resolved independently, as we are not liable for their actions.
          </p>
          <p className="mb-4 text-justify">
          3.7. Once you place an order on VivMart, we process the order and provide a unique tracking identity number for you to monitor the delivery status through the Logistics Partner's website or mobile application. Please note that tracking status may not always be entirely accurate or timely due to technical issues beyond our control.
          </p>
          <p className="mb-4 text-justify">
          3.8. We may charge shipping fees based on various factors, and these fees are non-refundable in most cases. Exceptions may apply if a defected, damaged, deficient, or incorrect Product is delivered and accepted by us after verification.
          </p>
          <p className="mb-4 text-justify">
          3.9. Returns of purchased Products are facilitated through our reverse-Logistics Partners, and the process follows our Cancellation, Return, and Refund Policy.
          </p>
        </section>

        {/* Repeat the structure for sections 4 to 13 */}

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">4. CUSTOMER SUPPORT</h2>
          <p className="mb-4">
          For any queries or concerns related to the shipping and delivery of Products, please contact our customer support team using the following details:
          </p>
          <p className="mb-4 text-justify">
          Customer Support Email – <a href='mailto:cs@vivimart.in' className='text-blue-700 underline'>cs@vivimart.in</a>
          </p>
          <p className="mb-4 text-justify">
          Contact Number – <a href='tell:+91 86 86 86 5851'>+91 86 86 86 5851</a>
          </p>
          <p className="mb-4 text-justify">
          Timings – 9:00 AM to 9:00 PM
          </p>
          
        </section>

        
      </main>
    </div>
  );
};

export default ShippingPolicy;
