import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  EMPTY_CART,
  SAVE_SHIPPING_INFO,
  GET_CART_ITEMS_FAIL,
  GET_CART_ITEMS_SUCCESS,
} from "../constants/cartConstants";

const initialState = {
  cartItems: JSON.parse(localStorage.getItem("cartItems")) || [],
  shippingInfo: JSON.parse(localStorage.getItem("shippingInfo")) || {},
  totalQuantity: parseInt(localStorage.getItem("totalQuantity"), 10) || 0,
  totalPrice: 0, // Initialize as needed
  error: null,
};

export const cartReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_TO_CART:
      return {
        ...state,
        cartItems: payload,
        totalQuantity: payload.reduce((total, item) => total + item.quantity, 0),
      };
    case REMOVE_FROM_CART:
      const updatedItems = state.cartItems.filter((item) => item.product_id !== payload);
      return {
        ...state,
        cartItems: updatedItems,
        totalQuantity: updatedItems.reduce((total, item) => total + item.quantity, 0),
      };
    case EMPTY_CART:
      return {
        ...state,
        cartItems: [],
        totalQuantity: 0,
      };
    case SAVE_SHIPPING_INFO:
      return {
        ...state,
        shippingInfo: payload,
      };
    case GET_CART_ITEMS_SUCCESS:
      return {
        ...state,
        cartItems: payload.cartItems,
        totalQuantity: payload.totalQuantity,
        totalPrice: payload.totalPrice,
        error: null,
      };
    case GET_CART_ITEMS_FAIL:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};
