import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllOrders, updateOrderItemStatus } from "../../actions/orderAction";
import OrderDetails from "./OrderDetails";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../LoadingPage";

const Orders = () => {
    const [selectedOrder, setSelectedOrder] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { orders, loading, error } = useSelector((state) => state.allOrders);
    const { user } = useSelector((state) => state.user);
    const store_id = user ? user.id : null;

    useEffect(() => {
        if (store_id) {
            dispatch(getAllOrders(store_id));
        }
    }, [dispatch, store_id]);

    const handleOrderItemClick = (order) => {
        setSelectedOrder(order);
    };

    const handleStatusUpdate = (order_id, product_id, newStatus) => {
        const updatedItems = [{ product_id, status: newStatus }];
        dispatch(updateOrderItemStatus(order_id, updatedItems));
    };

    const getOrderStatus = (order) => {
        const statuses = order.order_items.map(item => item.status);
        // Return the status that best represents the order's current state
        if (statuses.includes("New")) return "New";
        if (statuses.includes("Accepted")) return "Accepted";
        if (statuses.includes("Forwarded")) return "Forwarded";
        if (statuses.every(status => status === "Delivered")) return "Delivered";
        return "Unknown";
    };

    return (
        <div className="border border-gray-300 w-full min-h-[33rem] max-h-full font-sans rounded-md bg-white">
            <div className="border-b border-gray-300 p-2 pl-4 flex justify-between">
                <h1 className="text-gray-700 font-sans">
                    {selectedOrder ? "Order Details" : "Orders"}
                </h1>
            </div>
            {!selectedOrder ? (
                <div>
                    {loading ? (
                        <LoadingPage />
                    ) : error ? (
                        <p>Error: {error}</p>
                    ) : (
                        orders.map((order) => (
                            <div key={order.id} className="px-10 mb-4 mt-4">
                                <div
                                    className="pl-4 pt-4 flex rounded-lg relative gap-8 cursor-pointer"
                                    style={{ boxShadow: "0 3px 6px #00000080" }}
                                    onClick={() => handleOrderItemClick(order)}
                                >
                                    <div className="relative h-20 w-28 mt-8">
                                        <img src={order.order_items[0].product_image_0} alt={order.order_items[0].Product_name} />
                                        {order.order_items.length > 1 && (
                                            <div className="absolute bottom-0 right-0 bg-gray-800 text-white text-xs px-2 py-1 rounded-full">
                                                +{order.order_items.length - 1}
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex gap-4 flex-col w-full">
                                        <h1 className="text-gray-600 text-sm font-medium">ORDER ID: {order.order_id}</h1>
                                        <p className="text-gray-600 text-sm">Address: {order.address}</p>
                                        <p className="text-gray-600 text-sm">Phone: {order.phone_number}</p>
                                        <p className="text-gray-600 text-sm">Delivery Notes: {order.delivery_notes}</p>
                                        <h1 className="mb-2">{order.order_date}</h1>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            ) : (
                <OrderDetails order={selectedOrder} />
            )}
        </div>
    );
};

export default Orders;
