import React from "react";
import PropTypes from "prop-types";
import "./Banner.css";

const CustomDot = ({ active }) => {
  return (
    <div className={`custom-dot ${active ? 'active' : ''}`}></div>
  );
};

CustomDot.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default CustomDot;
