import { useEffect, useState, useRef } from "react";
import Sidebar from "./Sidebar/Sidebar";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CloseIcon from "@mui/icons-material/Close";

const Dashboard = ({ activeTab, children }) => {
  const [onMobile, setOnMobile] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [isOpenNotfi, setIsOpenNotfi] = useState(false);
  const navigate = useNavigate();
  const dashboardRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setOnMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (onMobile) {
      setToggleSidebar(false);
    }
  }, [onMobile]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dashboardRef.current &&
        !dashboardRef.current.contains(event.target)
      ) {
        setToggleSidebar(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const setTogglebar = () => {
    setToggle(!toggle);
  };

  return (
    <div ref={dashboardRef} className="flex min-h-screen sm:min-w-full">
      {!onMobile && !toggle && <Sidebar activeTab={activeTab} />}
      {toggleSidebar && (
        <Sidebar activeTab={activeTab} setToggleSidebar={setToggleSidebar} />
      )}
      <div
        className={`w-full min-h-screen ${
          toggle ? "" : "lg:ml-[17rem] sm:ml-48 md:ml-[9.5rem] sm:w-4/5"
        }`}
      >
        {!onMobile && (
          <div className="w-full bg-white shadow-md">
            <div className="flex justify-between items-center p-3 border-b border-gray-300">
              <div className="flex items-center gap-4 text-gray-500">
                <button className="pl-4" onClick={setTogglebar}>
                  <MenuIcon />
                </button>
                <span className="mt-1 text-lg ">Dashboard</span>
              </div>
              <div className="pr-4 cursor-pointer">
                <img
                  src="path_to_image" // Replace with your image path or URL
                  className="w-10 h-10 rounded-full object-cover shadow-md "
                />
              </div>
            </div>
            <div className="flex p-3">
              <button
                className="pl-4 flex items-center text-blue-500 underline hover:text-blue-700 focus:outline-none"
                onClick={() => navigate("/admin/dashboard")}
              >
                <span className="">Home</span>
              </button>
            </div>
          </div>
        )}
        <div className="flex flex-col gap-6 p-4 pb-6 overflow-hidden">
          <div className="flex justify-between">
            <div>
              <button
                onClick={() => setToggleSidebar(true)}
                className="sm:hidden bg-gray-700 w-10 h-10 rounded-full shadow text-white flex items-center justify-center"
              >
                <MenuIcon />
              </button>
            </div>
            <div className="flex gap-2 sm:hidden">
              <div onClick={() => setIsOpenNotfi(true)}>
                <NotificationsIcon
                  className="mt-1"
                  style={{ color: "#f33636" }}
                />
              </div>
              <img
                src="path_to_image" // Replace with your image path or URL
                className="w-10 h-10 rounded-full object-cover shadow-md cursor-pointer"
              />
            </div>
          </div>
          {!isOpenNotfi ? (
            children
          ) : (
            <div className="bg-white p-3 flex justify-between h-[45rem]">
              Notification
              <div onClick={() => setIsOpenNotfi(false)} >
                <CloseIcon />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
