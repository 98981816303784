import { useEffect, useState } from 'react';
import Categories from '../Layouts/Categories';
import Banner from './Banner/Banner';
import DealSlider from './DealSlider/DealSlider';
import ProductSlider from './ProductSlider/ProductSlider';
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors, getSliderProducts } from '../../actions/productAction';
import { useSnackbar } from 'notistack';
import MetaData from '../Layouts/MetaData';
import Product from './Product';
import { ADMIN_CATEGORY_API } from '../../utils/constants';


const Home = () => {

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { error, loading } = useSelector((state) => state.products);

  const [titles, setTitles] = useState([]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    dispatch(getSliderProducts());
  }, [dispatch, error, enqueueSnackbar]);

  useEffect(() => {
    fetch(ADMIN_CATEGORY_API)
      .then((response) => response.json())
      .then((categoryData) => {
        const uniqueCategories = Array.from(
          new Map(
            categoryData.map((item) => [item.category_name, item])
          ).values()
        );
        setTitles(uniqueCategories.map(category => category.category_name));
      })
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);

  return (
    <>
      <MetaData title="Vivimart" />
      
      <Categories/>
      <main className="flex flex-col gap-3 px-2 mt-16 sm:mt-2">
        <Banner />
        {titles.map((title, index) => (
          <Product key={index} title={title} />
        ))}
        <DealSlider title={"Discounts for You"} />
        {!loading && <ProductSlider title={"Suggested for You"} tagline={"Based on Your Activity"} />}
        <DealSlider title={"Top Brands, Best Price"} />
        {!loading && <ProductSlider title={"You May Also Like..."} tagline={"Based on Your Interest"} />}
        <DealSlider title={"Top Offers On"} />
        {!loading && <ProductSlider title={"Don't Miss These!"} tagline={"Inspired by your order"} />}
      </main>
    </>
  );
};

export default Home;
