import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import veg from "../../assets/images/Veg.png";
import nonveg from "../../assets/images/NonVeg.png";
import { removeItem, addItemsToCart } from "../../actions/cartAction";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import deliveryBike from "../../assets/images/delivery-bike.png";
import deliveryTruck from "../../assets/images/delivery-truck.png";
import { ADMIN_PRODUCT_API } from "../../utils/constants";

const Product = ({ id, product }) => {
  const [showCounter, setShowCounter] = useState(false);
  const [productDetails, setProductDetails] = useState(product);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.cart);

  const {
    MRP,
    offer,
    kind,
    Weight,
    you_save,
    sell_price,
    Brand_name,
    Product_name,
    Prodouct_img_0,
    Product_id,
    delivery_option,
  } = product;

  const showOffer = sell_price > MRP;

  const fetchProductData = async () => {
    try {
      // Get cart items from local storage and check if the product exists in the cart
      const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      const item = cartItems.find((item) => item.product_id === Product_id);

      if (item) {
        setShowCounter(true);
        setCount(item.quantity);
      } else {
        setShowCounter(false);
      }

      // Fetch the price based on storeId if available
      const storedStoreId = localStorage.getItem("StoreId");
      const storeId = storedStoreId ? JSON.parse(storedStoreId).store_id : null;

      console.log("Fetched Store ID:", storeId); // Debugging line

      const priceUrl = storeId
        ? `${ADMIN_PRODUCT_API}/${Product_id}/price?storeId=${storeId}`
        : `${ADMIN_PRODUCT_API}/${Product_id}`;

      console.log("Price URL:", priceUrl); // Debugging line

      const priceResponse = await fetch(priceUrl);

      if (!priceResponse.ok) {
        throw new Error(`HTTP error! status: ${priceResponse.status}`);
      }

      const priceData = await priceResponse.json();

      console.log("Price Data:", priceData); // Debugging line

      // Update product details with fetched price data
      setProductDetails((prevState) => ({
        ...prevState,
        MRP: priceData.MRP || prevState.MRP,
        price: priceData.sell_price || priceData.MRP || prevState.price,
        offer: priceData.offer || prevState.offer,
        you_save: priceData.you_save || prevState.you_save,
        sell_price: priceData.sell_price || prevState.sell_price,
        delivery_option:
          priceData.delivery_option ||
          prevState.delivery_option ||
          "Standard Delivery",
      }));
    } catch (error) {
      console.error("Error fetching product or price data:", error);
    }
  };

  useEffect(() => {
    fetchProductData();
  }, [Product_id]); // Dependency array to re-fetch if Product_id changes

  useEffect(() => {
    const existingItem = cartItems.find(
      (item) => item.product_id === Product_id
    );
    if (existingItem) {
      setShowCounter(true);
      setCount(existingItem.quantity);
    } else {
      setShowCounter(false);
      setCount(0);
    }
  }, [cartItems, Product_id]);

  const handleAddToCart = (productId, quantity) => {
    dispatch(addItemsToCart(productId, quantity));
    setShowCounter(true);
  };

  const handleIncrease = (productId) => {
    dispatch(addItemsToCart(productId, 1));
  };

  const handleDecrease = (productId) => {
    if (count > 1) {
      dispatch(addItemsToCart(productId, -1));
    } else {
      dispatch(removeItem(productId));
      setShowCounter(false);
    }
  };

  const [isBoxOpen, setIsBoxOpen] = useState(false);

  const toggleBox = () => {
    setIsBoxOpen(!isBoxOpen);
  };

  return (
    <>
      <style>
        {`
    .zoom-container {
      position: relative;
      overflow: hidden;
    }

    .zoom-image {
      transition: transform 0.3s ease; /* Adjust the duration as needed */
      transform-origin: center center; /* Ensures zoom effect is centered */
    }

    .zoom-container:hover .zoom-image {
      transform: scale(1.2); /* Adjust scale factor as needed */
      transition: transform 0.6s ease;
    }
  `}
      </style>
      <div className="bg-white rounded-lg shadow-md p-2 relative border border-[0.5px solid rgb(232, 232, 232)]">
        <div className="box-border  font-normal rounded-lg flex flex-col justify-between">
          <div className="relative bg-center bg-no-repeat flex justify-between">
            <span className="flex items-center justify-center h-5 w-16 p-2 md:h-6 w-16 p-2 lg:bg-green-700 rounded-full bg-green-700">
              <span className="text-sm md:text-xs font-normal block uppercase text-white">
                {offer}%
              </span>
            </span>
          </div>

          <Link to={`/product/:${product.Product_id}`} state={{ count: count }}>
            <div className="zoom-container px-5">
              <img
                className="zoom-image object-cover rounded-lg bg-transparent"
                src={Prodouct_img_0}
                alt={Product_name}
                onError={(e) => (e.target.src = "/images/Noimage.jpg")}
              />
            </div>
          </Link>

          <div className="flex items-center pl-1">
            <img
              className="h-4 w-auto mr-2"
              src={kind === "veg" ? veg : nonveg}
              alt={kind === "veg" ? "Veg" : "Non Veg"}
            />
          </div>
        </div>

        <div className="p-1 pt-1">
          <div className="mb-2">
            <h2 className="block text-sm md:text-xs truncate-3-lines text-gray-800">
              {product.Brand_name}
            </h2>
            <h2
              className="text-customGray text-[17px] text-opacity-100 mb-1 truncate leading-5 font-normal pt-1 cursor-pointer"
              style={{ fontWeight: "500" }}
              title={product.Product_name}
            >
              {product.Product_name}
            </h2>
          </div>
          {/* <div className="pb-1 hidden lg:block">
          <div className="bg-center bg-no-repeat bg-cover">
            <button
              className="flex justify-between items-center border border-gray-100 rounded-md w-full overflow-hidden text-ellipsis whitespace-nowrap px-1 py-0.5 h-7 leading-4 bg-white text-gray-600"
              onClick={toggleBox}
            >
              <span className="text-xs px-2">{Weight}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 ml-1"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d={
                    isBoxOpen
                      ? "M10 7.414L3.707 13.707a1 1 0 001.414 1.414L10 10.242l4.879 4.879a1 1 0 001.414-1.414L10 7.414z"
                      : "M10 12.586L3.707 6.293a1 1 0 011.414-1.414L10 10.758l4.879-4.879a1 1 0 111.414 1.414L10 12.586z"
                  }
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div> */}
          <div className="flex justify-between items-center mt-3 md:mt-6 gap-2 ">
            <div className="flex flex-col text-xs">
              {!showOffer ? (
                <>
                  <div>
                    <span className="font-semibold lg:text-base text-[13px] md:text-[11px]">
                      ₹{productDetails.sell_price}
                    </span>
                  </div>
                  <div>
                    <span className="text-gray-400 line-through lg:text-sm text-[12px] md:text-[11px]">
                      ₹{productDetails.MRP}
                    </span>
                  </div>
                </>
              ) : (
                <span className="text-gray-600 lg:text-xs text-[13px] md:text-[11px]">
                  ₹{productDetails.MRP}
                </span>
              )}
            </div>
            <div className="text-xs">
              {showCounter ? (
                <div className="flex items-center lg:w-[5rem] w-[4rem] md:w-[3.5rem]">
                  <button
                    className="cursor-pointer w-8 h-8 bg-transparent text-white font-semibold text-sm leading-5 tracking-wide uppercase rounded-l"
                    onClick={() => handleDecrease(product.Product_id)}
                    style={{ backgroundColor: "rgb(49, 134, 22)" }}
                  >
                    -
                  </button>
                  <div
                    style={{ backgroundColor: "rgb(49, 134, 22)" }}
                    className="h-8 text-white font-semibold text-sm leading-5 tracking-wide bg-green-50 flex justify-center items-center px-2"
                  >
                    {count}
                  </div>
                  <button
                    className="cursor-pointer w-8 h-8 bg-transparent text-white font-semibold text-md leading-5 tracking-wide uppercase rounded-r "
                    onClick={() => handleIncrease(product.Product_id)}
                    style={{ backgroundColor: "rgb(49, 134, 22)" }}
                  >
                    +
                  </button>
                </div>
              ) : (
                <button
                  style={{
                    border: "1px solid rgb(49, 134, 22)",
                    color: "rgb(49, 134, 22)",
                    fontSize: "13px",
                  }}
                  className="cursor-pointer w-[3rem] lg:w-16 md:w-[3.5rem] h-7 md:h-8 font-semibold text-xs leading-5 tracking-wide uppercase bg-green-50 rounded-lg flex justify-center items-center"
                  onClick={() => handleAddToCart(product.Product_id, 1)}
                >
                  ADD
                </button>
              )}
            </div>
          </div>
          <div
  className={`mt-3 md:mt-5 rounded-md w-full flex items-center justify-center p-1 ${
    productDetails.delivery_option?.toLowerCase() === "fast delivery" ? "bg-yellow-200" : "bg-red-200"
  }`}
>
  <div className="ml-2">
    {delivery_option === "Standard Delivery" ? (
      <img
        src={deliveryTruck}
        alt="deliveryTruck"
        className="w-6 h-6"
      />
    ) : delivery_option === "fast delivery" ? (
      <img
        src={deliveryTruck} // Replace with the appropriate fast delivery icon
        alt="deliveryTruck"
        className="w-6 h-6"
      />
    ) : (
      <img
        src={deliveryBike}
        alt="deliveryBike"
        className="w-6 h-6"
      />
    )}
  </div>
  <div className="text-[11px] md:text-sm ml-2">
    {productDetails.delivery_option}
  </div>
</div>
          {isBoxOpen && (
            <div className="fixed inset-0 flex items-center justify-center z-50 hidden lg:block">
              <div className="absolute inset-0 bg-black opacity-50"></div>
              <div className="relative bg-white p-5 rounded-lg z-50 flex flex-col">
                <div className="flex justify-center items-center sticky top-0 p-4 pt-0 z-10 bg-[#f4f6fb]">
                  <div class="font-bold text-[#1F1F1F] text-lg mb-4 mr-2 w-full h-auto">
                    <div class="overflow-hidden truncate line-clamp-2">
                      {product.Product_name}
                    </div>
                  </div>
                  <div
                    onClick={toggleBox}
                    className="cursor-pointer text-base text-white opacity-60 bg-[#4A5268] rounded-full py-1 px-2.5 self-start font-custom"
                  >
                    <CloseIcon />
                  </div>
                </div>
                <div className="flex flex-col p-3 gap-2.5">
                  <div className="cursor-pointer flex flex-col gap-3">
                    <div className="flex p-1 pr-3 pl-1 bg-white rounded-lg justify-between items-center">
                      <div className="flex flex-col items-center gap-2.5 w-1/3 justify-start">
                        <div className="relative">
                          <span className="text-xs font-normal block uppercase">
                            {offer}%
                          </span>
                        </div>
                        <div className="h-[70px] w-[70px]">
                          <img
                            className="w-full h-full object-cover opacity-100 will-change-transform-opacity filter-unset transition-opacity transition-transform duration-250 ease-in"
                            src={Prodouct_img_0}
                            alt={Product_name}
                          />
                        </div>
                      </div>
                      <div class="font-normal text-xs leading-4 text-[#696969] overflow-hidden line-clamp-2">
                        {Weight}
                      </div>
                      <div className="w-[17rem] flex justify-center ">
                        <div className="flex text-xs leading-4">
                          <div className="text-[#1F1F1F] font-okra-bold leading-4">
                            ₹{sell_price}
                          </div>
                          <div className="text-[#666666] line-through ml-2 font-normal">
                            ₹{MRP}
                          </div>
                        </div>
                      </div>
                      <div className="w-1/3 flex justify-end ">
                        <button className="cursor-pointer text-xs w-[66px] h-[31px] border border-[#318616] text-[#318616] bg-[#F7FFF9] font-semibold font-okra rounded-md gap-0.5 flex items-center justify-center">
                          ADD
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Product;