import { GET_SUB_CATEGORY_REQUEST, GET_SUB_CATEGORY_SUCCESS, GET_SUB_SUB_CATEGORY_FAILURE, GET_SUB_SUB_CATEGORY_REQUEST, GET_SUB_SUB_CATEGORY_SUCCESS, GET_SUB_CATEGORY_FAILURE, GET_CATEGORY_NAME_REQUEST, GET_CATEGORY_NAME_SUCCESS, GET_CATEGORY_NAME_FAILURE } from "../constants/categoryConstants";


export const subCategoryReducer = (state = { subCategories: [] }, action) => {
    switch (action.type) {
        case GET_SUB_CATEGORY_REQUEST:
            return {
                loading: true,
                subCategories: []
            }
        case GET_SUB_CATEGORY_SUCCESS:
            return {
                loading: false,
                subCategories: action.payload
            }
        case GET_SUB_CATEGORY_FAILURE:
            return {
                loading: false,
                subCategories: action.payload
            }
    
        default:
            return state;
    }
}

export const subSubCategoryReducer = (state = { subSubCategories: [] }, action) => {
    switch (action.type) {
        case GET_SUB_SUB_CATEGORY_REQUEST:
            return {
                loading: true,
                subSubCategories: []
            }
        case GET_SUB_SUB_CATEGORY_SUCCESS:
            return {
                loading: false,
                subSubCategories: action.payload
            }
        case GET_SUB_SUB_CATEGORY_FAILURE:
            return {
                loading: false,
                subSubCategories: action.payload
            }
    
        default:
            return state;
    }
}

export const categoryNameReducer = (state = { categoryNames: {} }, action) => {
    switch (action.type) {
        case GET_CATEGORY_NAME_REQUEST:
            return {
                loading: true,
                categoryNames: {}
            }
        case GET_CATEGORY_NAME_SUCCESS:
            return {
                loading: false,
                categoryNames: action.payload
            }
        case GET_CATEGORY_NAME_FAILURE:
            return {
                loading: false,
                categoryNames: action.payload
            }
    
        default:
            return state;
    }
}