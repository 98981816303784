import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { BASE_URL } from "../../config";
import notFound from "../../assets/images/NotFound.png";

const DetectLoc = ({ setLocation, searchLoc, setIsOpen }) => {
  const [detectLocation, setDetectLocation] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);

  useEffect(() => {
    const fetchCurrentLocation = async () => {
      try {
        const response = await fetch(`${BASE_URL}api/geocode`);
        const data = await response.json();
        if (response.ok) {
          setCurrentLocation(data);
        } else {
          setCurrentLocation("Location not found");
        }
      } catch (error) {
        console.error("Error fetching location:", error);
        setDetectLocation(true);
      }
    };

    navigator.geolocation.getCurrentPosition(async (position) => {
      console.log("Current position:", position);
      await fetchCurrentLocation();
    });
  }, []);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div
        className="detectLoc-slide-up bg-white p-4 rounded-t-lg w-full h-[40rem] rounded-t-[1rem]"
        style={{ backgroundColor: "rgb(244, 246, 252)" }}
      >
        <div className="flex items-center justify-between">
          <div className="font-sans font-bold" style={{ fontSize: "17px" }}>
            Select Your Location
          </div>
          <div>
            <button onClick={() => setIsOpen(false)}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="absolute top-[3rem] pl-2 left-[49%] transform -translate-x-1/2 w-[calc(100%-24px)] h-[54px] z-[9999]">
          <div className="h-full rounded-[12px] bg-white border border-[rgb(207,207,207)] shadow-[2px_2px_12px_0_rgba(0,0,0,0.06)]">
            <div className="flex items-center h-full ">
              <div className="p-2">
                <SearchIcon className="text-green-800" />
              </div>
              <div className="text-sm font-sans w-[21rem]">
                <input
                  className="flex-grow flex-shrink w-full focus:outline-none"
                  type="text"
                  placeholder="Search delivery location"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={() => setDetectLocation(true)}
          class="absolute top-[7rem] px-2 flex items-center p-[8px_10px] gap-2 rounded-lg border-[0.654px] bg-white text-[12px] font-medium text-[#0c831f] cursor-pointer z-[9999] bottom-[246px] w-[calc(100%-24px)] h-[40px] box-border"
        >
          <span>
            <MyLocationIcon style={{ fontSize: "1rem" }} />
          </span>
          <span style={{ fontSize: "14px" }} className="font-sans">
            Use current location
          </span>
        </div>
        {detectLocation && currentLocation !== null && (
          <div className="mx-auto w-96 mt-[10rem]">
            <img src={notFound} alt="oops!" className="ml-24 h-44" />
            <div
              style={{ fontSize: "14px" }}
              className="font-okra text-gray-700"
            >
              <div className="text-center text-gray-800 text-lg leading-normal mb-1">
                <h1>Oops!</h1>
              </div>
              <div className="text-center">
                Vivimart is not available at&nbsp;
                <b>{currentLocation}</b>
                &nbsp;at the moment. Please select a different location.
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DetectLoc;
