import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Banner.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CustomDot from "./CustomDot";

const webBanners = [
  "https://ik.imagekit.io/efsdltq0e/Banners/Web%20Banner/1.png?updatedAt=1721222152107",
  "https://ik.imagekit.io/efsdltq0e/Banners/Web%20Banner/2.png?updatedAt=1721222163177",
  "https://ik.imagekit.io/efsdltq0e/Banners/Web%20Banner/3.png?updatedAt=1721222155716",
  "https://ik.imagekit.io/efsdltq0e/Banners/Web%20Banner/4.png?updatedAt=1721222159092",
  "https://ik.imagekit.io/efsdltq0e/Banners/Web%20Banner/5.png?updatedAt=1721222148014",
];

const mobileBanners = [
  "https://ik.imagekit.io/efsdltq0e/Banners/Mobile%20Banner/1.png?updatedAt=1721224339840",
  "https://ik.imagekit.io/efsdltq0e/Banners/Mobile%20Banner/2.png?updatedAt=1721224339674",
  "https://ik.imagekit.io/efsdltq0e/Banners/Mobile%20Banner/3.png?updatedAt=1721224339592",
  "https://ik.imagekit.io/efsdltq0e/Banners/Mobile%20Banner/4.png?updatedAt=1721224339643",
  "https://ik.imagekit.io/efsdltq0e/Banners/Mobile%20Banner/5.png?updatedAt=1721224339816",
  "https://ik.imagekit.io/efsdltq0e/Banners/Mobile%20Banner/6.png?updatedAt=1721224339834",
];

export const PreviousBtn = ({ className, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIosIcon />
    </div>
  );
};

export const NextBtn = ({ className, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIosIcon />
    </div>
  );
};

const Banner = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true, // Use default dots
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PreviousBtn className="prev-arrow" />,
    nextArrow: <NextBtn className="next-arrow" />,
  };

  return (
    <>
      <section className="h-44 w-full sm:h-[22rem] shadow relative overflow-hidden hidden lg:block">
        <Slider {...settings}>
          {webBanners.map((el, i) => (
            <div key={i}>
              <img
                draggable="false"
                className="banner-image"
                src={el}
                alt={`banner-${i}`}
                onError={(e) => {
                  e.target.onerror = null; // Prevents looping
                  e.target.src =
                    "https://via.placeholder.com/600x300?text=Image+Not+Found"; // Fallback image
                }}
              />
            </div>
          ))}
        </Slider>
      </section>
      <section className="md:h-[24rem] mt-[-3rem] sm:h-[20.9rem] w-full relative overflow-hidden lg:hidden">
        <Slider {...settings}>
          {mobileBanners.map((el, i) => (
            <div key={i}>
              <img
                draggable="false"
                className="banner-image"
                src={el}
                alt={`banner-${i}`}
                onError={(e) => {
                  e.target.onerror = null; // Prevents looping
                  e.target.src =
                    "https://via.placeholder.com/600x300?text=Image+Not+Found"; // Fallback image
                }}
              />
            </div>
          ))}
        </Slider>
      </section>
    </>
  );
};

export default Banner;
