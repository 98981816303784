import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { ADMIN_CATEGORY_API } from "../../utils/constants";

const MinCategory = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    fetch(ADMIN_CATEGORY_API)
      .then((response) => response.json())
      .then((categoryData) => {
        const uniqueCategories = Array.from(
          new Map(
            categoryData.map((item) => [item.category_name, item])
          ).values()
        );
        setCategoryData(uniqueCategories);
      })
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);
  return (
    <section className="bg-white w-full px-2 sm:px-12 mt-[6rem] overflow-hidden border-b hidden lg:block">
      <div className="flex items-center justify-between p-0.5">
        {categoryData.map((item, i) => (
          <Link
            to={`/products?category=${item.category_name}`}
            className="flex flex-col items-center p-2 group text-center"
            style={{ width: "fit-content" }}
            key={i}
          >
            <span className="text-sm text-gray-800 font-medium group-hover:text-primary-blue">
              {item.category_name}
            </span>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default MinCategory;
