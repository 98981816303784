import React from "react";
import { Link } from "react-router-dom";
import Logo_business from "../../../assets/images/Logo_business.png";

const StoreHeader = () => {
  return (
    <div className="pt-24 lg:hidden">
      <header className="bg-primary-blue fixed top-0 py-4 w-full z-10 ">
        <div className="w-full px-1 sm:px-4 m-auto flex justify-between items-center relative">
          <div className="flex pb-2">
            <div className="flex col-span-1 relative bottom-[0.2rem]">
              <Link className="h-16 mr-1 sm:mr-4" to="/">
                <img
                  draggable="false"
                  className="h-full w-full object-contain"
                  src={Logo_business}
                  alt="Vivimart Logo"
                />
              </Link>
            </div>
            <div className="text-white font-sans">
              <h1 className="font-bold">Get the app</h1>
              <p className="w-40 text-xs">
                for better experience and exclusive features
              </p>
            </div>
            <div className="font-sans fixed right-0 m-4">
              <button className="bg-gray-600 text-white font-bold py-2 px-4 rounded-lg">
                Use app
              </button>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default StoreHeader;
