import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { searchProducts } from "../../actions/searchAction";
import Product from "../Products/Product";

const SearchProducts = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const keyword = params.keyword ? params.keyword.toLowerCase() : "";

  const { loading, products, error } = useSelector((state) => state.search);

  useEffect(() => {
    if (keyword) {
      dispatch(searchProducts(keyword));
    }
  }, [dispatch, keyword]);

  const validProducts = Array.isArray(products) ? products : [];

  const subcategories = [...new Set(validProducts.map((product) => product.Sub_Sub_Categories))];
  const uniqueSubcategory = subcategories.length > 0 ? subcategories[0] : "";

  return (
    <div className="flex flex-col gap-3 hidden-scrollbar overflow-y-auto p-4 sm:p-6 bg-white md:p-8 lg:mt-0 mt-[14rem] lg:px-[10rem] lg:py-[7rem]">
      {loading ? (
        <div className="flex items-center justify-center h-screen">
          <span className="font-bold text-2xl">Loading...</span>
        </div>
      ) : error ? (
        <div className="flex items-center justify-center h-screen">
          <span className="font-bold text-2xl text-red-500">{error}</span>
        </div>
      ) : validProducts.length > 0 ? (
        <>
          {uniqueSubcategory && (
            <div className="font-sans w-[8rem] inline-block text-center text-gray-600 text-[13px] font-medium leading-[17px] cursor-pointer bg-white border border-gray-300 shadow-sm rounded-[12px] p-[12px] px-[16px]">
              {uniqueSubcategory}
            </div>
          )}
          <div>
            <span className="font-bold font-sans">
              Showing results for "{keyword}"
            </span>
          </div>
          <div className="flex flex-cols-2 h-2xl w-64 sm:flex-cols-3 md:flex-cols-4 lg:flex-cols-5 gap-3 sm:gap-4 md:gap-5">
            {validProducts.map((item) => (
              <Product key={item.Product_id} product={item} />
            ))}
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center h-screen">
          <span className="font-bold text-2xl">No results found for "{keyword}"</span>
        </div>
      )}
    </div>
  );
};

export default SearchProducts;
