import { CLEAR_ERRORS, GET_ADMIN_FAIL, GET_ADMIN_REQUEST, GET_ADMIN_SUCCESS } from "../constants/adminConstants"
import axiosInstance from "../utils/api"

export const getAdminDetails = () => async (dispatch) => {
    try {
        dispatch({ type: GET_ADMIN_REQUEST });
        const { data } = await axiosInstance.get("/admin-details");
        dispatch({ type: GET_ADMIN_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: GET_ADMIN_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        });
    }
};


export const clearError = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
  };