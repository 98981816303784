import {
    SUB_STORE_FAIL,
    SUB_STORE_REQUEST,
    SUB_STORE_SUCCESS,
    CLEAR_ERRORS
} from "../constants/subStoreConstants";
const initialState = {
    subStores: [],
    loading: false,
    error: null,
}

export const subStoreReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUB_STORE_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case SUB_STORE_SUCCESS:
            return {
                loading: false,
                subStores: action.payload,
            }
        case SUB_STORE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }               
    
        default:
            return state;
    }
}