import React from 'react';
import MetaData from '../MetaData';

const ReturnAndReplacement = () => {
  return (
    <div className="w-full mt-20 px-4 sm:px-8 md:px-16 lg:px-32 xl:px-64">
      <MetaData title="Cancellation, Return, and Refund Policy | ViviMart" />
      <main className="bg-white p-6 shadow-lg rounded-lg">
        <h1 className="text-3xl font-bold mb-12">ViviMart Cancellation, Return, and Refund Policy</h1>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-6">1. General</h2>
          <p className="mb-4 text-justify">
            1.1. GVS FOOD AND RETAIL PRIVATE LIMITED, headquartered at Srivari Srimat, 3rd Floor, Door Old No.736/6, New No.1045, Avinashi Road, Coimbatore-641018, India ("GVS," "we," "us," or "our"), operates the mobile application/website and tablet applications found at <span className='text-blue-700 underline'><a href='https://www.vivimart.com' target='_blank'>www.ViviMart.com</a></span> (collectively referred to as "ViviMart," "Website," or "App"). ViviMart facilitates the sale and purchase of products and services ("Products") on the Website. The terms "User," "Users," "you," or "your" refer to individuals who use, access, browse the Website, or purchase Products.
          </p>
          <p className="mb-4 text-justify">
            1.2. Our goal is to offer a seamless user experience, from ordering to cancellation, return, and refund. When transacting on ViviMart, you can expect a trouble-free process for returning or cancelling ordered Products, making us your preferred shopping destination.
          </p>
          <p className="mb-4 text-justify">
            1.3. The "Terms and Conditions" on ViviMart are incorporated by reference into this cancellation, return, and refund policy ("Policy"). This Policy, in conjunction with the Terms and Conditions, outlines GVS's procedures and policies for accepting (a) cancellations, (b) returns, and (c) refunds. Any cancellation or refund of Products by you is subject to the terms and conditions set forth in this Policy.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-6">2. Applicability of Policy</h2>
          <p className="mb-4 text-justify">
            2.1. By using ViviMart or initiating a request to purchase Products on ViviMart, you agree to abide by the terms in this Policy. If you do not agree with the terms in this Policy, we advise against transacting on ViviMart. Please note that we may change the terms of the Policy from time to time. Before using ViviMart, please review the Policy to ensure you understand the applicable terms and conditions.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-6">3. Cancellation Terms</h2>
          <p className="mb-4 text-justify">
            3.1. You can cancel your order at any time before the Product is dispatched. Orders cannot be canceled once dispatch has begun. If, after dispatch, you wish to cancel the order, please contact Customer Support and request an order cancellation. We will make every effort to prevent delivery, but if delivery is attempted, you may refuse it.
          </p>
          <p className="mb-4 text-justify">
            3.2. You can request order cancellation by cancelling the order in the 'My Orders' section using the order tracking number, calling our customer care number, or any other method notified by GVS.
          </p>
          <p className="mb-4 text-justify">
            3.3. Upon receiving your order cancellation request, we will cancel the order immediately, and the refund process for prepaid orders will be initiated within 24 hours of confirmation.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-6">4. Return and Refund Terms</h2>
          <p className="mb-4 text-justify">
          4.1. You can initiate the return of Products within the specified timelines and according to the terms outlined in Annexure A (refer to the table at the end of this Policy). We will accept the return request subject to the conditions in this Policy. We will not process returns for orders of the wrong Product model, color, or incorrect Product.
          </p>
          <p className="mb-4 text-justify">
          4.2. All our packages have tamper-proof packaging. Please do not accept packages with a tampered seal or damaged outer packaging. Accepting a tampered or damaged package disqualifies you from return claims for physically damaged/defective Products, incorrect Products, or missing accessories.
          </p>
          <p className="mb-4 text-justify">
          4.3. You can register a complaint or initiate a return through ViviMart. To do so, go to the 'Orders' section, select the 'Help' tab, and choose 'Still need help.' You may log a complaint or contact us via email, call, or chat to initiate a return. You may be required to provide images of the Product for quality assessment.
          </p>
          <p className="mb-4 text-justify">
          4.4. After verification, we will accept or reject the return request based on the defects, damages, or non-compliance with the Product. We may also schedule a visit to assess the damage or defect in the Product.
          </p>
          <p className="mb-4 text-justify">
          4.5. The refund for eligible Products will be processed within one business day after verification. The refund amount will be credited to your original payment method or your ViviMart Wallet for Wallet refund.
          </p>
          <p className="mb-4 text-justify">
          4.6. For "Cash on Delivery" orders, refunds will be credited to your bank account if the Product is purchased from a third-party seller, or to your Wallet if the sale was made by GVS.
          </p>
          <p className="mb-4 text-justify">
          4.7. Refunds will be processed within 15 working days, depending on your chosen payment method. If there are delays, please contact us for assistance.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-6">5. Customer Support Team</h2>
          <p className="mb-4 text-justify">
          For feedback, concerns, or inquiries, please contact us:
          </p>
          <p className="mb-4 text-justify">
          Write to us at: <a href='mailto:cs@vivimart.in'>cs@vivimart.in</a> 
          </p>
          <p className="mb-4 text-justify">
          Call us at: <a href='tell:+91 86 86 86 5851'>+91 86 86 86 5851</a> 
          </p>
        </section>
        <section className="mb-6">
          <h1 className="text-3xl font-semibold mb-6">ANNEXURE A – RETURN POLICY AND CONDITIONS</h1>
          <p className="mb-4 text-justify">
            1. Home & Kitchen (Prayer & Spiritual Needs, Mops, Brushes & Scrubs, Furnishing, Umbrella, Raincoat, Stationery, Toys, Games & Fitness, Kitchenware, Dining) <br></br>
Returnable within 7 days for damaged, defective, non-working, missing parts, or incorrect product based on the description.<br></br>
Products must be in their original condition, with the brand outer box, MRP tags, user manual, warranty cards, and original accessories in the manufacturer's packaging for a successful return pickup.

          </p>
          <p className="mb-4 text-justify">
          2.	Garden and Outdoor - Gardening (Indoor Plants, Seeds, Outdoor Plants, Fertilizer and Soil, Compost) <br></br>
Non-returnable for consumable nature of products.<br></br>
Contact ViviMart customer care within 7 days for damaged, defective, or incorrect products.

          </p>
          <p className="mb-4 text-justify">
          3.	Home and Kitchen - Prayer & Spiritual Needs (Kumkum/Turmeric/Sand, Agarbatti, Dhoop)
Non-returnable for consumable nature of products.<br></br>
Contact ViviMart customer care within 7 days for damaged, defective, or incorrect products.

          </p>
          <p className="mb-4 text-justify">
          3.	Home and Kitchen - Prayer & Spiritual Needs (Kumkum/Turmeric/Sand, Agarbatti, Dhoop)
Non-returnable for consumable nature of products.<br></br>
Contact ViviMart customer care within 7 days for damaged, defective, or incorrect products.

          </p>
          <p className="mb-4 text-justify">
          4.	Home Decor - Decorative Stickers (Wall Stickers), Home Fragrance (Incense Sticks, Scented Candles, Scented Oils)<br></br>
Non-returnable for consumable nature of products.<br></br>
Contact ViviMart customer care within 7 days for damaged, defective, or incorrect products.

          </p>
          <p className="mb-4 text-justify">
          5.	Fruit & Vegetables (Fresh Fruits, Fresh Vegetables, Herbs & Seasonings, Exotic Fruits & Vegetables)<br></br>
Non-returnable for consumable nature of products.<br></br>
Contact ViviMart customer care within 24 hours for quality, freshness, physical condition, or incorrect product concerns.

          </p>
          <p className="mb-4 text-justify">
          6.	Dairy & Bakery (Dairy, Toast & Khari, Breads and Buns, Bakery Snacks, Batter & Chutney, Cheese, Ghee, Paneer & Tofu)<br></br>
Non-returnable for consumable nature of products.<br></br>
Contact ViviMart customer care within 24 hours for quality, freshness, physical condition, or incorrect product concerns.

          </p>
          <p className="mb-4 text-justify">
          7.	Staples (Atta, Flours & Sooji, Dals & Pulses, Rice & Rice Products, Edible Oils, Masalas & Spices, Salt, Sugar & Jaggery, Soya Products, Wheat & Other Grains, Dry Fruits & Nuts)<br></br>
Non-returnable for consumable nature of products.<br></br>
Contact ViviMart customer care within 24 hours for quality, freshness, physical condition, or incorrect product concerns.

          </p>
          <p className="mb-4 text-justify">
          8.	Disposable (Plates & Glasses, Kitchen & Toilet Rolls, Paper Tissues & Napkins, Cling Film, Garbage Bags, Aluminium Foil)<br></br>
Non-returnable for consumable nature of products.<br></br>
Contact ViviMart customer care within 72 hours for damaged, defective, or incorrect products.

          </p>
          <p className="mb-4 text-justify">
          9.	Snacks (Foods, Biscuits & Cookies, Noodle, Pasta, Vermicelli, Breakfast Cereals, Snacks & Namkeen, Chocolates & Candies, Ready To Cook & Eat, Frozen Veggies & Snacks, Spreads, Sauces, Ketchup, Indian Sweets Pickles & Chutney, Extracts & Flavouring)<br></br>
Non-returnable for consumable nature of products.<br></br>
Contact ViviMart customer care within 24 hours for quality, freshness, physical condition, or incorrect product concerns.

          </p>
          <p className="mb-4 text-justify">
          10.	Beverages (Tea, Coffee, Fruit juices, Energy & Soft Drinks, Health Drink & Supplement, Soda & Flavored Water)<br></br>
Non-returnable for consumable nature of products.<br></br>
Contact ViviMart customer care within 24 hours for quality, freshness, physical condition, or incorrect product concerns.


          </p>
          <p className="mb-4 text-justify">
          11.	Personal Care (Hair Care, Oral Care, Skin Care, Bath & Hand Wash, Body Wash & Bathing Accessories, Feminine Hygiene, Men’s Grooming, Cosmetics and Beauty Products, Deodorants & Fragrances, Health & Wellness, Grooming Tools and Accessories)<br></br>
Non-returnable for hygiene/personal care/wellness and consumable nature of products.<br></br>
Contact ViviMart customer care within 24 hours for quality, freshness, physical condition, or incorrect product concerns.

          </p>
          <p className="mb-4 text-justify">
          12.	Personal Care - Health and Wellness (Body Supports)<br></br>
Returnable within 10 days for size issue, fit issue, damaged, defective, missing parts, or incorrect product based on the description.<br></br>
Products must be unused/unwashed, with intact price tags/barcodes, not altered, and without strong odors/stains.

          </p>
          <p className="mb-4 text-justify">
          13.	Home Care (Detergents, Dishwash, All Purpose Cleaners, Fresheners & Repellents)<br></br>
Non-returnable for hygiene/personal care/wellness and consumable nature of products.<br></br>
Contact ViviMart customer care within 24 hours for quality, freshness, physical condition, or incorrect product concerns.

          </p>
          <p className="mb-4 text-justify">
          14.	Sports, Toys, and Luggage - Outdoor games - Holi colors, Holi accessories <br></br>
Non-returnable for consumable nature of products.<br></br>
Contact ViviMart customer care within 72 hours for damaged, defective, missing parts, or incorrect products.

          </p>
          <p className="mb-4 text-justify">
          15.	Sports, Toys and Luggage - Sporting Goods and Fitness Equipment’s (Billiard, Cricket inner apparel, Cricket Guards, Cycle and Ride-on, Elliptical Trainers, Exercise Bikes, Pedal Exercisers, Rowing Machines, Stair Climbers & Step Machines, Treadmills, Massaging Equipment’s, Home Gym Systems, Racks & Cages, Benches, Massager, Snooker, Table Tennis table, Other Sports accessories)
          </p>
          <p className="mb-4 text-justify">
          Non-returnable for consumable nature of products.
          </p>
          <p className="mb-4 text-justify">
          Contact ViviMart customer care within 7 days for damaged, defective, missing parts, or incorrect products.
          </p>
          <p className="mb-4 text-justify">
          Products must be unused, with the original packaging and accessories for a successful return pickup.
          </p>
          <p className="mb-4 text-justify">
          For issues beyond 7 days, contact the manufacturer under the product warranty.
          </p>

        </section>
        </main>
    </div>
  )
}

export default ReturnAndReplacement
