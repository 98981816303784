import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { NextBtn, PreviousBtn } from "./Banner/Banner";
import { ADMIN_PRODUCT_API } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import Product from "../Products/Product"; // Import the Product component

export const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 6,
  initialSlide: 1,
  swipe: false,
  prevArrow: <PreviousBtn />,
  nextArrow: <NextBtn />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

const ProductList = ({ title }) => {
  const [product, setProduct] = useState([]);
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.cart);

  // Initialize product counts from localStorage or default to an empty object
  const initialProductCounts =
    JSON.parse(localStorage.getItem("productCounts")) || {};
  const [productCounts, setProductCounts] = useState(initialProductCounts);

  // Fetch products and initialize product states on mount
  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await fetch(`${ADMIN_PRODUCT_API}/category/${title}`);
        const productData = await response.json();
        console.log("Fetched product data:", productData); // Debugging line
        setProduct(productData);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProductData();
  }, [title]);

  const uniqueProducts = Object.values(
    product.reduce((acc, item) => {
      if (!acc[item.Sub_Sub_Categories]) {
        acc[item.Sub_Sub_Categories] = item;
      }
      return acc;
    }, {})
  );

  // Update localStorage whenever productCounts change
  useEffect(() => {
    localStorage.setItem("productCounts", JSON.stringify(productCounts));
  }, [productCounts]);

  return (
    <>
      <style>
        {`
          .slick-slide {
            padding: 0 10px; /* Adjust the value as needed */
          }
          .slick-track {
            display: flex;
            align-items: center;
          }
          
          .zoom-container {
            position: relative;
            overflow: hidden;
          }

          .zoom-image {
            transition: transform 0.3s ease; /* Adjust the duration as needed */
            transform-origin: center center; /* Ensures zoom effect is centered */
          }

          .zoom-container:hover .zoom-image {
            transform: scale(1.2); /* Adjust scale factor as needed */
            transition: transform 0.6s ease;
          }
        `}
      </style>
      {product.length !== 0 && (
        <section className="bg-white w-full shadow overflow-hidden mt-0 md:mt-0 sm:mt-44">
          <div className="flex px-6 py-3 justify-between items-center">
            <h1 className="lg:text-xl text-md font-medium">{title}</h1>
            <Link
              to={`/products?category=${title}`}
              className="bg-primary-blue text-xs font-medium text-white lg:px-5 lg:py-2.5 px-2 py-1.5 rounded-sm shadow-lg"
            >
              VIEW ALL
            </Link>
          </div>
          <Slider {...settings}>
            {uniqueProducts.map((item) => (
              <Product key={item.Product_id} _id={item.Product_id} product={item} />
            ))}
          </Slider>
        </section>
      )}
    </>
  );
};

export default ProductList;
