import { SEARCH_PRODUCTS_FAIL, SEARCH_PRODUCTS_REQUEST, SEARCH_PRODUCTS_SUCCESS, FETCH_SUGGESTIONS_SUCCESS, FETCH_SUGGESTIONS_FAIL } from "../constants/searchConstants";
import axiosInstance from "../utils/api";

// Action to search products
export const searchProducts = (keyword) => async (dispatch) => {
    try {
        dispatch({ type: SEARCH_PRODUCTS_REQUEST });

        const encodedKeyword = encodeURIComponent(keyword);
        const { data } = await axiosInstance.get(`/products/search?q=${encodedKeyword}`);

        const products = Array.isArray(data) ? data : [data];

        dispatch({
            type: SEARCH_PRODUCTS_SUCCESS,
            payload: products,
        });
    } catch (error) {
        dispatch({
            type: SEARCH_PRODUCTS_FAIL,
            payload: error.response?.data?.message || error.message,
        });
    }
};

// Action to fetch search suggestions
export const fetchSuggestions = (keyword) => async (dispatch) => {
    try {
        const encodedKeyword = encodeURIComponent(keyword);
        const { data } = await axiosInstance.get(`/products/search/suggestions?q=${encodedKeyword}`);

        dispatch({
            type: FETCH_SUGGESTIONS_SUCCESS,
            payload: data.suggestions,
        });
    } catch (error) {
        dispatch({
            type: FETCH_SUGGESTIONS_FAIL,
            payload: error.response?.data?.message || error.message,
        });
    }
};
