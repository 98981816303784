import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Searchbar from "./Searchbar";
import logo from "../../../assets/images/logo1.png";
import PrimaryDropDownMenu from "./PrimaryDropDownMenu";
import SecondaryDropDownMenu from "./SecondaryDropDownMenu";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Logomb from "../../../assets/images/Logomb.png";
import DetectLocation from "../../Layouts/DetectLocation";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Cart from "../../Cart/Cart";
import Login from "../../User/Login";
import DeliveryEdit from "../../Cart/DeliveryEdit";
import DetectLoc from "../DetectLoc";

const Header = () => { 
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { cartItems } = useSelector((state) => state.cart);
  const [togglePrimaryDropDown, setTogglePrimaryDropDown] = useState(false);
  const [toggleSecondaryDropDown, setToggleSecondaryDropDown] = useState(false);
  const [searchLocation, setSearchLocation] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenn, setIsOpenn] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const userPhoneNumber = localStorage.getItem("userPhoneNumber");
  const totalQuantity = localStorage.getItem("totalQuantity");
  const selectedAddress = localStorage.getItem("selectedAddress") || "";

  console.log("Selected Address:", selectedAddress);


  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolling(true)
    } else {
      setScrolling(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const changeLocation = () => {
    setIsOpen(true);
  };

  const openCartSidebar = () => {
    setIsCartOpen(true);
    document.body.style.overflow = "hidden"; // Prevent scrolling
  };

  const closeCartSidebar = () => {
    setIsCartOpen(false);
    document.body.style.overflow = "auto"; // Enable scrolling
  };

  const handleAccountDetails = () => {
    setTogglePrimaryDropDown(!togglePrimaryDropDown);
  };

  return (
    <>
      <header className="bg-primary-blue fixed top-0 py-2 w-full h-30 z-10">
        {/* navbar container */}
        <div className="w-full px-1 sm:px-4 m-auto flex justify-between items-center relative">
          {/* logo & search container */}
          <div className="flex items-center flex-1 w-full">
            <div className="flex col-span-1">
              <Link
                className="h-16 mr-4 sm:mr-4 hidden lg:block pr-14 md: h-20 pr-3"
                to="/"
              >
                <img
                  draggable="false"
                  className="h-full w-full object-contain"
                  src={logo}
                  alt="Vivimart Logo"
                />
              </Link>
            </div>

            <div
              style={{
                transition: "transform 0.3s ease-out", // Smooth transition
                transform: scrolling ? "translateY(-100%)" : "translateY(0)", // Hide or show
              }}
              className="header-mobile-promotion lg:hidden flex pb-2"
            >
              <div className="flex col-span-1 relative bottom-[0.2rem]">
                <Link className="h-16 mr-1 sm:mr-4" to="/">
                  <img
                    draggable="false"
                    className="h-full w-full object-contain"
                    src={Logomb}
                    alt="Vivimart Logo"
                  />
                </Link>
              </div>
              <div className="text-white font-sans">
                <h1 className="font-bold text-sm md:text-base">Get the app</h1>
                <p className="w-40 text-xs md:text-sm">
                  for better experience and exclusive features
                </p>
              </div>
              <div className="font-sans">
                <a href="https://play.google.com/store/apps/details?id=com.vivmart.customer&hl=en">
                  <button
                    className="bg-gray-600 text-white text-xs md:text-sm font-bold py-2 px-4 rounded-lg"
                  >
                    Use app
                  </button>
                </a>
              </div>
            </div>


            <div className="col-span-10 w-80 hidden lg:block md:w-74 sm:pr-4">
              <h1
                style={{ fontFamily: "'Okra', Helvetica", color: "#FFCE44" }}
                className="font-bold tracking-wider mb-0 text-[18px] text-white md:text-[17px] sm:text-[12px]"
              >
                Fast Delivery in 30 min...
              </h1>
              <div className="relative inline-block">
                <div
                  className="inline-flex items-center cursor-pointer"
                  style={{ backgroundColor: "transparent" }} // Set background to transparent
                  onClick={changeLocation}
                >
                  <span
                    className={`text-white font-sans mr-2 text-base truncate ${
                      selectedAddress ? "w-auto" : "w-28"
                    } max-w-[12rem] md:max-w-[10rem] text-[12px] sm:max-w-[8rem]`}
                  >
                    {selectedAddress || "Select Location"}
                  </span>
                  <span className="text-white">
                    <ArrowDropDownIcon />
                  </span>
                </div>
              </div>
            </div>
            <div className="hidden lg:block w-8/12 mr-14 md:hidden sm:block">
              <Searchbar />
            </div>
          </div>

          {/* right navs */}
          <div className="hidden lg:block">
            <div className="flex items-center justify-between ml-1 sm:ml-0 gap-0.5 sm:gap-7 relative">
              {!userPhoneNumber ? (
                <div className="flex items-center">
                  <button
                    className="px-3 sm:px-9 py-0.5 text-white font-medium rounded-sm cursor-pointer text-[19px]"
                    style={{
                      backgroundColor: "transparent",
                      fontSize: "1.25rem",
                    }} // 1.25rem = 20px
                    onClick={() => setIsLoginOpen(true)}
                  >
                    Login
                  </button>
                </div>
              ) : (
                <span
                  className="userDropDown flex items-center text-white font-medium gap-1 cursor-pointer"
                  onClick={() => handleAccountDetails()}
                >
                  {/* {user.name && user.name.split(" ", 1)} */}
                  <span className="text-[19px]">Account</span>
                  <span>
                    {togglePrimaryDropDown ? (
                      <ExpandLessIcon sx={{ fontSize: "19px" }} />
                    ) : (
                      <ExpandMoreIcon sx={{ fontSize: "19px" }} />
                    )}
                  </span>
                </span>
              )}

              {togglePrimaryDropDown && (
                <PrimaryDropDownMenu
                  setTogglePrimaryDropDown={setTogglePrimaryDropDown}
                  user={userPhoneNumber}
                />
              )}

              {/* <span
                className="moreDropDown hidden sm:flex items-center text-white font-medium gap-1 cursor-pointer"
                onClick={() =>
                  setToggleSecondaryDropDown(!toggleSecondaryDropDown)
                }
              >
                More
                <span>
                  {toggleSecondaryDropDown ? (
                    <ExpandLessIcon sx={{ fontSize: "16px" }} />
                  ) : (
                    <ExpandMoreIcon sx={{ fontSize: "16px" }} />
                  )}
                </span>
              </span> */}

              {toggleSecondaryDropDown && <SecondaryDropDownMenu />}

              <span
                className="flex items-center text-white font-medium gap-2 relative cursor-pointer text-[19px]"
                onClick={openCartSidebar}
              >
                <ShoppingCartIcon />
                {totalQuantity > 0 && (
                  <div className="w-5 h-5 p-2 bg-red-500 text-xs rounded-full absolute -top-2 left-3 flex justify-center items-center border-red-500">
                    {totalQuantity}
                  </div>
                )}
                Cart
              </span>
            </div>
          </div>
        </div>
        <div className="bg-white fixed py-4 w-full z-10 lg:hidden md:block sm:block">
          <div className="col-span-10 pl-8 md:pl-4 sm:pl-2">
            <h1
              style={{ fontFamily: "'Okra', Helvetica" }}
              className="font-extrabold mb-[6px] text-[18px] md:text-[16px] sm:text-[14px]"
            >
              Fast Delivery in 30 min...
            </h1>

            <div className="relative inline-block">
              <div className="bg-white border border-gray-300 rounded-md inline-flex items-center cursor-pointer">
                <span
                  className={`text-black font-sans mr-2 text-sm truncate ${
                    selectedAddress ? "w-auto" : "w-28"
                  } md:w-24 sm:w-10`}
                >
                  {selectedAddress || "Select Location"}
                </span>

                <span>
                  <ArrowDropDownIcon />
                </span>
              </div>
            </div>
          </div>
          <div className="w-full px-3 pt-1 md:pt-2 sm:pt-3">
            <Searchbar />
          </div>
        </div>
        {isOpen && (
          <div className="hidden lg:block">
            <DetectLocation
              setLocation={setSearchLocation}
              searchLoc={searchLocation}
              setIsOpen={setIsOpen}
            />
          </div>
        )}
        {isOpen && (
          <div className="lg:hidden detectLoc-slide-up">
            <DetectLoc
              setLocation={setSearchLocation}
              searchLoc={searchLocation}
              setIsOpen={setIsOpen}
            />
          </div>
        )}
      </header>

      {/* Cart Sidebar */}
      {isCartOpen && (
        <div className="fixed inset-0 z-20 flex">
          <div
            className="fixed inset-0 bg-black opacity-50 backdrop-blur-sm"
            onClick={closeCartSidebar}
          ></div>
          <div className="ml-auto">
            <Cart
              closeCartSidebar={closeCartSidebar}
              setIsEdit={setIsEdit}
              setIsOpen={setIsOpenn}
            />
          </div>
        </div>
      )}

      {isOpenn && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70 py-4">
          <DeliveryEdit isEdit={isEdit} setIsOpen={setIsOpenn} />
        </div>
      )}

      {isLoginOpen && (
        <div className="fixed inset-0 z-50 flex lg:items-center justify-center lg:bg-black bg-white lg:bg-opacity-70 bg-opacity-100">
          <Login setIsLoginOpen={setIsLoginOpen} />
        </div>
      )}
    </>
  );
};

export default Header;
