import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { ADMIN_CATEGORY_API } from "../../utils/constants";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export const PreviousBtn = ({ onClick }) => {
  return (
    <div className="absolute left-2 top-1/2 transform -translate-y-1/2 cursor-pointer border p-1 shadow-2xl rounded-lg bg-white flex items-center justify-center" onClick={onClick}>
      <ArrowBackIosIcon />
    </div>
  );
};

export const NextBtn = ({ onClick }) => {
  return (
    <div className="cursor-pointer absolute right-1 top-1/2 transform -translate-y-1/2 hover:bg-white hover:border p-1 shadow-2xl rounded-lg" onClick={onClick}>
      <ArrowForwardIosIcon />
    </div>
  );
};

const Categories = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [visibleCount, setVisibleCount] = useState(4); // Default to mobile view

  const step = 2; // The number of items to shift by each click

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 500) {
        setVisibleCount(7); // Tablet view
      } else {
        setVisibleCount(3); // Mobile view
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial value

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetch(ADMIN_CATEGORY_API)
      .then((response) => response.json())
      .then((categoryData) => {
        const uniqueCategories = Array.from(
          new Map(categoryData.map((item) => [item.category_name, item])).values()
        );
        setCategoryData(uniqueCategories);
      })
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);

  const handlePrevClick = () => {
    setStartIndex((prevStartIndex) => Math.max(prevStartIndex - step, 0));
  };

  const handleNextClick = () => {
    setStartIndex((prevStartIndex) =>
      Math.min(prevStartIndex + step, categoryData.length - visibleCount)
    );
  };

  const touchStartXRef = useRef(0);
  const touchEndXRef = useRef(0);

  const handleTouchStart = (e) => {
    touchStartXRef.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndXRef.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const touchStartX = touchStartXRef.current;
    const touchEndX = touchEndXRef.current;
    const touchDiff = touchStartX - touchEndX;

    if (touchDiff > 50) {
      handleNextClick();
    } else if (touchDiff < -50) {
      handlePrevClick();
    }
  };

  const visibleCategories = categoryData.slice(startIndex, startIndex + visibleCount);

  return (
    <>
      <style>
        {`
          @media (max-width: 800px) {
            .desktop-view {
              display: none;
            }
          }

          @media (min-width: 801px) {
            .mobile-view {
              display: none;
            }
          }
        `}
      </style>
      <section className="relative mt-[15rem] sm:mt-[15rem] mb-[4rem] bg-white lg:mt-[6rem] lg:mb-4 w-96 md:w-full w-[50rem] lg:px-6 px-1 py-1 shadow overflow-hidden">
        {/* Hoverable container for desktop */}
        <div className="desktop-view relative flex items-center m-2">
          {/* Previous Button */}
          {startIndex > 0 && <PreviousBtn onClick={handlePrevClick} />}

          {/* Categories */}
          <div className="flex flex-wrap gap-3">
            {visibleCategories.map((item, i) => (
              <Link
                to={`/products?category_id=${item.id}`}
                className="flex items-center text-center border shadow-xl rounded-lg bg-gray-100 hover:bg-gray-300"
                style={{ width: "calc(100% / 7 - 16px)", height: "75px" }}
                key={i}
              >
                <div className="h-full w-16 flex-shrink-0">
                  <img
                    draggable="false"
                    className="h-full w-full rounded-l-lg object-cover"
                    src={item.category_img}
                    alt={item.category_name}
                  />
                </div>
                <div className="p-4 text-left items-center justify center lg:truncate p-0">
                  <span className="lg:text-[16px] lg:text-clip lg:overflow-hidden md:text-base font-medium">
                    {item.category_name}
                  </span>
                </div>
              </Link>
            ))}
          </div>

          {/* Next Button */}
          {startIndex + visibleCount < categoryData.length && <NextBtn onClick={handleNextClick} />}
        </div>

        {/* Mobile View */}
        <div
          className="mobile-view flex items-center mt-2 mb-2 gap-2 md:gap-4 md:pl-4 lg:hidden"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {visibleCategories.map((item, i) => (
            <Link
              to={`/products?category_id=${item.id}`}
              className="flex flex-row items-center group text-center border rounded-lg bg-gray-100 hover:bg-gray-300"
              style={{ width: "fit-content", height: "50px" }}
              key={i}
            >
              <div className="h-full w-10 flex-shrink-0">
                <img
                  draggable="false"
                  className="h-full w-full rounded-lg object-cover"
                  src={item.category_img}
                  alt={item.category_name}
                />
              </div>
              <span
                className="text-sm text-gray-800 font-medium m-2 flex-grow-0 truncate"
                style={{ maxWidth: "90px" }}
              >
                {item.category_name}
              </span>
            </Link>
          ))}
        </div>
      </section>
    </>
  );
};

export default Categories;
