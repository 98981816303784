import React, { useState, useEffect } from "react";
import dummy from "../../assets/images/dummy.png";
import { ADMIN_CATEGORY_API } from "../../utils/constants";
import CsvUploadPopup from "./CsvUploadPopup";
import SearchIcon from "@mui/icons-material/Search";

const Category = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [isCSVUploadOpen, setIsCSVUploadOpen] = useState(false);
  const [filteredCategoryData, setFilteredCategoryData] = useState([]);
  const [searchCategory, setSearchCategory] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [endPoint, setEndPoint] = useState("");

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = () => {
    fetch(ADMIN_CATEGORY_API)
      .then((response) => response.json())
      .then((data) => {
        setCategoryData(data);
        setFilteredCategoryData(data);
      })
      .catch((error) => console.error("Error fetching categories:", error));
  };

  const generateCSVData = () => {
    const headers = ["Categories_Id", "Categories_Name", "Categories_Img"];
    const rows = categoryData.map((category) => [
      category.category_id,
      category.category_img,
      category.category_name,
    ]);
    let csvContent = headers.join(",") + "\n";
    csvContent += rows.map((row) => row.join(",")).join("\n");

    return csvContent;
  };

  const downloadCSV = () => {
    const csvData = generateCSVData();
    const blob = new Blob(["\ufeff", csvData], {
      type: "application/vnd.ms-excel",
    });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "Categories.xlsx";

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const SearchCategory = () => {
    const filteredData = categoryData.filter((category) =>
      category.category_name
        .toLowerCase()
        .includes(searchCategory.toLowerCase())
    );
    setFilteredCategoryData(filteredData);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      SearchCategory();
    }
  };

  const handleUploadSuccess = (updatedData) => {
    setCategoryData(updatedData);
    setFilteredCategoryData(updatedData);
  };

  const handleCSVUpload = () => {
    setIsCSVUploadOpen(true);
    setEndPoint("category");
  };

  return (
    <div className="flex flex-wrap gap-8">
      <div className="shadow-xl sm:w-72 shadow-gray-250 border border-gray-300 p-2 rounded-md bg-white h-[30rem] w-[23rem] flex-none">
        <div className="border-b border-gray-300 p-2 font-sans">
          <h1>Copyright Information</h1>
        </div>
        <div className="ml-32 sm:ml-20">
          <img src={dummy} alt="oops!" className="mt-8 h-28 mb-4" />
          <label
            htmlFor="file-upload"
            className="font-sans bg-gray-300 px-14 py-1 relative right-8"
          >
            Add Cover
            <input
              id="file-upload"
              name="file-upload"
              type="file"
              className="sr-only"
            />
          </label>
        </div>
        <div className="p-4 font-sans">
          <h1>Category Name</h1>
          <input
            placeholder="Category Name"
            className="block w-full px-3 py-2 text-base font-normal leading-normal text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out"
            type="text"
          />
          <h1>Category Status</h1>
          <input
            placeholder="Category Status"
            className="block w-full px-3 py-2 text-base font-normal leading-normal text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out"
            type="text"
          />
        </div>
        <div className="p-4">
          <button
            className="flex justify-center my-2 mr-4
       items-center py-2 px-4 h-9 bg-primary-blue text-base font-normal rounded-md text-white"
          >
            Save
          </button>
        </div>
      </div>
      <div className="flex-1 min-w-0 border border-gray-300 shadow-xl rounded-md shadow-gray-250 h-[30rem] bg-white overflow-y-auto">
        <div className="border-b border-gray-300 p-2 font-sans">
          <h1>All Categories</h1>
          <div className="flex justify-end gap-2 pr-2">
            <button
              className="flex justify-center
       items-center py-2 px-4 h-9 bg-primary-blue text-xs text-white"
              onClick={downloadCSV}
            >
              Export as CSV
            </button>
            <button
              className="flex justify-center
       items-center py-2 px-4 h-9 bg-primary-green text-xs text-white"
              onClick={() => handleCSVUpload()}
            >
              Import CSV
            </button>
          </div>
        </div>
        <div className="p-4">
          <div className="flex items-center border border-gray-300 rounded">
            <input
              placeholder="Search Category"
              className="block w-full px-3 py-2 text-base font-normal leading-normal text-gray-700 bg-white border-none rounded-l transition duration-150 ease-in-out"
              value={searchCategory}
              type="text"
              onChange={(e) => setSearchCategory(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button
              type="submit"
              className="text-primary-blue px-4 py-2"
              onClick={SearchCategory}
            >
              <SearchIcon />
            </button>
          </div>
          <div className="w-full pt-8">
            <table className="w-full border-collapse font-sans">
              <thead>
                <tr className="border-b">
                  <th className="px-2 py-2 w-1/12 text-gray-600 text-left">
                    Id
                  </th>
                  <th className="px-4 py-2 text-gray-600 text-left">Cover</th>
                  <th className="px-4 py-2 text-gray-600 text-left">
                    Category Name
                  </th>
                  <th className="px-8 py-2 text-gray-600 text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredCategoryData.map((category) => (
                  <tr
                    key={category.category_id}
                    className="hover:bg-gray-100 border-b"
                  >
                    <td className="px-2 py-2">{category.category_id}</td>
                    <td className="px-4 py-2">
                      <img className="w-20" src={category.category_img} />
                    </td>
                    <td className="px-4 py-2 w-40">{category.category_name}</td>
                    <td className="py-2">
                      <button
                        className="px-1 py-1 bg-green-500 text-white rounded"
                        onClick={(e) => setIsActive(true)}
                      >
                        Active
                      </button>
                      <button className="px-1 py-1 bg-blue-500 text-white rounded ml-2">
                        Edit
                      </button>
                      <button className="px-1 py-1 bg-red-500 text-white rounded ml-2">
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isCSVUploadOpen && (
        <CsvUploadPopup
          isClose={setIsCSVUploadOpen}
          onUploadSuccess={handleUploadSuccess}
          endPoint={endPoint}
        />
      )}
    </div>
  );
};

export default Category;
