import MetaData from "../MetaData";
import React, { useState } from 'react';

const GoogleForm = () => {
  const [formData, setFormData] = useState({
    help: '',
    email: '',
    phone: '',
    details: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData);
  };

  const backgroundImageStyle = {
    backgroundImage: 'url(https://images.unsplash.com/photo-1518780664697-55e3ad937233)  ',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const formStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    maxWidth: '500px',
    width: '100%',
  };

  return (
    <div style={backgroundImageStyle}>
      <form onSubmit={handleSubmit} style={formStyle}>
        <h2>Contact Us</h2>

        <label>
          How can we help you?
          <input
            type="text"
            name="help"
            value={formData.help}
            onChange={handleChange}
            style={{ width: '100%', padding: '8px', margin: '10px 0', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </label>

        <label>
          Email:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            style={{ width: '100%', padding: '8px', margin: '10px 0', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </label>

        <label>
          Phone Number:
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            style={{ width: '100%', padding: '8px', margin: '10px 0', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </label>

        <label>
          Enter the details:
          <textarea
            name="details"
            value={formData.details}
            onChange={handleChange}
            rows="4"
            style={{ width: '100%', padding: '8px', margin: '10px 0', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </label>

        <button type="submit" style={{ padding: '10px 20px', backgroundColor: '#4285F4', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
          Submit
        </button>
      </form>
    </div>
  );
};

export default GoogleForm;
