import { SEARCH_PRODUCTS_REQUEST, SEARCH_PRODUCTS_SUCCESS, SEARCH_PRODUCTS_FAIL, FETCH_SUGGESTIONS_SUCCESS, FETCH_SUGGESTIONS_FAIL } from "../constants/searchConstants";

const initialState = {
    loading: false,
    products: [],
    suggestions: [],  // Add suggestions to the state
    error: null,
};

const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_PRODUCTS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case SEARCH_PRODUCTS_SUCCESS:
            const products = Array.isArray(action.payload) ? action.payload : [action.payload];
            return {
                ...state,
                loading: false,
                products,
            };
        case SEARCH_PRODUCTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                products: [],
            };
        case FETCH_SUGGESTIONS_SUCCESS:
            return {
                ...state,
                suggestions: action.payload,
            };
        case FETCH_SUGGESTIONS_FAIL:
            return {
                ...state,
                suggestions: [],
                error: action.payload,
            };
        default:
            return state;
    }
};

export default searchReducer;
