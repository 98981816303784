import { useEffect } from "react";
import Sidebar from "./Sidebar/Sidebar";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../Layouts/Loader";
import MinCategory from "../Layouts/MinCategory";
import MetaData from "../Layouts/MetaData";
import { auth } from "../../firebase";

const Profile = () => {
  const navigate = useNavigate();
  const { user, loading, isAuthenticated, storeData } = useSelector((state) => state.user);

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  const getLastName = () => {
    const nameArray = user.name.split(" ");
    return nameArray[nameArray.length - 1];
  };

  return (
    <>
      <MetaData title="My Profile" />

      {loading ? (
        <Loader />
      ) : (
        <>
          
          <main className="w-full mt-12 sm:mt-0">
            <div className="flex gap-3.5 sm:w-11/12 sm:mt-4 m-auto mb-7">
              

              <div className="flex-1 overflow-hidden shadow bg-white">
                <div className="flex flex-col gap-12 m-4 sm:mx-8 sm:my-6">
                  <div className="flex flex-col gap-5 items-start">
                    <span className="font-medium text-lg">
                      Personal Information{" "}
                      
                    </span>

                    <div
                      className="flex flex-col sm:flex-row items-center gap-3"
                      id="personalInputs"
                    >
                      <div className="flex flex-col gap-0.5 w-64 px-3 py-1.5 rounded-sm border inputs cursor-not-allowed bg-gray-100 focus-within:border-primary-blue">
                        <label className="text-xs text-gray-500">
                          Name
                        </label>
                        <input
                          type="text"
                          value={user.name}
                          className="text-sm outline-none border-none cursor-not-allowed text-gray-500"
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  

                  <div className="flex flex-col gap-5 items-start">
                      <span className="font-medium text-lg">Store Details</span>
                      <div className="flex flex-col gap-2">
                        <div className="flex gap-3">
                          <div className="flex flex-col gap-0.5 w-64 px-3 py-1.5 rounded-sm border bg-gray-100">
                            <label className="text-xs text-gray-500">Store ID</label>
                            <input
                              type="text"
                              value={user.id}
                              className="text-sm outline-none border-none text-gray-500"
                              disabled
                            />
                          </div>
                          <div className="flex flex-col gap-0.5 w-64 px-3 py-1.5 rounded-sm border bg-gray-100">
                            <label className="text-xs text-gray-500">Owner Name</label>
                            <input
                              type="text"
                              value={user.owner_name}
                              className="text-sm outline-none border-none text-gray-500"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="flex gap-3">
                          <div className="flex flex-col gap-0.5 w-64 px-3 py-1.5 rounded-sm border bg-gray-100">
                            <label className="text-xs text-gray-500">State</label>
                            <input
                              type="text"
                              value={user.state}
                              className="text-sm outline-none border-none text-gray-500"
                              disabled
                            />
                          </div>
                          <div className="flex flex-col gap-0.5 w-64 px-3 py-1.5 rounded-sm border bg-gray-100">
                            <label className="text-xs text-gray-500">City</label>
                            <input
                              type="text"
                              value={user.city_name}
                              className="text-sm outline-none border-none text-gray-500"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="flex gap-3">
                          <div className="flex flex-col gap-0.5 w-64 px-3 py-1.5 rounded-sm border bg-gray-100">
                            <label className="text-xs text-gray-500">Primary Location</label>
                            <input
                              type="text"
                              value={user.primary_location}
                              className="text-sm outline-none border-none text-gray-500"
                              disabled
                            />
                          </div>
                          <div className="flex flex-col gap-0.5 w-64 px-3 py-1.5 rounded-sm border bg-gray-100">
                            <label className="text-xs text-gray-500">Package Fee</label>
                            <input
                              type="text"
                              value={user.package_fee}
                              className="text-sm outline-none border-none text-gray-500"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                  
                </div>

                <img
                  draggable="false"
                  className="w-full object-contain"
                  src="https://static-assets-web.flixcart.com/www/linchpin/fk-cp-zion/img/myProfileFooter_4e9fe2.png"
                  alt="footer"
                />
              </div>
            </div>
          </main>
        </>
      )}
    </>
  );
};

export default Profile;
