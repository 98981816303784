import {
  GET_CATEGORY_NAME_FAILURE,
  GET_CATEGORY_NAME_REQUEST,
  GET_CATEGORY_NAME_SUCCESS,
  GET_SUB_CATEGORY_FAILURE,
  GET_SUB_CATEGORY_REQUEST,
  GET_SUB_CATEGORY_SUCCESS,
  GET_SUB_SUB_CATEGORY_FAILURE,
  GET_SUB_SUB_CATEGORY_REQUEST,
  GET_SUB_SUB_CATEGORY_SUCCESS,
} from "../constants/categoryConstants";
import axiosInstance from "../utils/api";

export const getSubCategories = (categoryId) => async (dispatch) => {
  try {
    dispatch({ type: GET_SUB_CATEGORY_REQUEST });
    const { data } = await axiosInstance.get(`/sub-category/category/${categoryId}`);
    console.log("Fetched sub-categories data:", data); // Add this log
    dispatch({ type: GET_SUB_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_SUB_CATEGORY_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getSubSubCategories = (subCategoryId) => async (dispatch) => {
  try {
    dispatch({ type: GET_SUB_SUB_CATEGORY_REQUEST });
    const { data } = await axiosInstance.get(`/sub-sub-categories/subCategory/${subCategoryId}`);
    console.log("Fetched sub-subcategories data:", data); // Add this log
    dispatch({ type: GET_SUB_SUB_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_SUB_SUB_CATEGORY_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getCategoryName = (categoryId) => async (dispatch) => {
  try {
    dispatch({ type: GET_CATEGORY_NAME_REQUEST });
    const { data } = await axiosInstance.get(`/categories/name/${categoryId}`);
    console.log("Fetched category name data:", data); // Add this log
    dispatch({ type: GET_CATEGORY_NAME_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_CATEGORY_NAME_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
